<template>
    <div></div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        dialogFormVisible: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>
</style>