<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>

        <div class="evaluateBox">
            <div
                class="evaluateList"
                v-for="(item, index) in dataList"
                :key="index"
            >
                <div class="evaluatePerson">
                    <div class="evaluatePersonImg">
                        <img :src="item.userUrl" alt="" />
                    </div>
                    <div class="evaluatePersonName">{{ item.userName }}</div>
                </div>
                <div class="evaluateGg">规格：{{ item.goodsSpecName }}</div>
                <div class="evaluateComent">{{ item.comment }}</div>
                <div class="evaluateImgList">
                    <div
                        class="evaluateImgListLi"
                        v-for="(obj, objIndex) in item.img"
                        :key="objIndex"
                    >
                        <img :src="obj" alt="" />
                    </div>

                    <div
                        class="evaluateImgListLi"
                        v-for="(obj, objIndex) in item.videos"
                        :key="objIndex"
                    >
                        <video :src="obj" controls></video>
                    </div>
                </div>
                <div class="evaluateTime">
                    {{ item.createDate }}
                </div>
            </div>
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 0.3rem;
                "
            >
                <el-pagination
                    :current-page="listQuery.page"
                    :page-sizes="[10]"
                    :page-size="listQuery.limit"
                    :total="listQuery.total"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="pageSizeChangeHandle"
                    @current-change="pageCurrentChangeHandle"
                >
                </el-pagination>
                <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
            </div>
        </div>
    </div>
</template>

<script>
import { getGoodsComment } from '@/api/user/index.js'

export default {
    data() {
        return {
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',

            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            dataList: []
        }
    },
    created() {
        console.log(1234232)
        this.init(this.$route.query.id)
    },
    methods: {
        pageSizeChangeHandle(val) {
            this.listQuery.page = 1
            this.listQuery.limit = Number(val)
            this.init()
        },
        pageCurrentChangeHandle(val) {
            this.listQuery.page = Number(val)
            this.init()
        },
        init(id) {
            getGoodsComment({
                id: id,
                current: this.listQuery.page,
                size: this.listQuery.limit
            }).then((res) => {
                if (res.code == 0) {
                    this.listQuery.total = Number(res.data.total)
                    for (var a = 0; a < res.data.records.length; a++) {
                        if (res.data.records[a].img) {
                            res.data.records[a].img =
                                res.data.records[a].img.split(',')
                        }
                        if (res.data.records[a].videos) {
                            res.data.records[a].videos =
                                res.data.records[a].videos.split(',')
                        }
                    }
                    this.dataList = res.data.records
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding: 0.5rem 0;
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}
.evaluateBox {
    width: 16.27rem;
    border-radius: 0.31rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    margin: 0.4rem auto 0;
    padding: 0.62rem;
    box-sizing: border-box;
}
.evaluateList {
    border-bottom: 1px solid #dedede;
    padding: 0.25rem 0;
    .evaluatePerson {
        display: flex;
        align-items: center;
        .evaluatePersonImg {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: block;
            }
        }

        .evaluatePersonName {
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-left: 0.15rem;
        }
    }

    .evaluateGg {
        color: #6b6b6b;
        font-size: 0.22rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        margin-top: 0.2rem;
    }

    .evaluateComent {
        font-size: 0.26rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        margin-top: 0.2rem;
        line-height: 0.4rem;
    }
    .evaluateImgList {
        display: flex;
        align-items: center;
        .evaluateImgListLi {
            width: 2rem;
            height: 2rem;
            margin-top: 0.3rem;
            margin-right: 0.2rem;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.1rem;
            }
            video {
                width: 100%;
                height: 100%;
                border-radius: 0.1rem;
            }
        }
    }
    .evaluateTime {
        font-size: 0.26rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        margin-top: 0.2rem;
    }
}
</style>