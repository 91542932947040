<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <el-carousel
            :interval="5000"
            arrow="always"
            height="9.17rem"
            indicator-position="none"
            style="position: relative"
        >
            <el-carousel-item v-for="(item, index) in lunboList" :key="index">
                <img
                    :src="item.pic"
                    alt=""
                    style="width: 100%; height: 100%; object-fit: cover"
                    @click="swiperIndex(index)"
                />
            </el-carousel-item>
        </el-carousel>
        <div
            class="marketBox"
            style="background: #fafafa"
            v-if="saleList.length > 0"
        >
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0.2rem;
                    box-sizing: border-box;
                "
            >
                <div style="width: 1rem"></div>

                <div>
                    <div class="marketTit">今日特卖</div>
                </div>
                <div
                    style="
                        width: 1rem;
                        font-size: 0.2rem;
                        color: rgba(17, 17, 17, 0.5019607843);
                    "
                    @click="toUrl('/temaiList', 4)"
                >
                    更多产品
                </div>
            </div>
            <div class="jrtmBox">
                <div
                    class="jrtmLi"
                    v-for="(item, index) in saleList"
                    :key="index"
                    @click="toUrl('/shopXq', item.id)"
                >
                    <img :src="item.pic[0]" alt="" />
                    <div class="jrtmName">{{ item.title }}</div>
                </div>
            </div>
        </div>
        <div class="contBox">
            <div
                class="contLi"
                @click="toUrl('/seedList', item.id, item.title)"
                v-for="(item, index) in list"
                :key="index"
            >
                <img :src="item.pic" alt="aspectFill" />
                <div class="contName">
                    <div class="contNameText">
                        {{ item.title }}
                    </div>
                </div>
            </div>

            <!-- <div class="contLi">
                <img src="https://lzdbuck.oss-cn-beijing.aliyuncs.com/20240822/3870ce7d71b7415f8bf0e322efd53267.jpg"
                    alt="">

                <div class="contName">
                    <div class="contNameText">
                        农药
                    </div>
                </div>
            </div>
            <div class="contLi">
                <img src="https://lzdbuck.oss-cn-beijing.aliyuncs.com/20240822/3870ce7d71b7415f8bf0e322efd53267.jpg"
                    alt="">

                <div class="contName">
                    <div class="contNameText">
                        化肥
                    </div>
                </div>
            </div>
            <div class="contLi">
                <img src="https://lzdbuck.oss-cn-beijing.aliyuncs.com/20240822/3870ce7d71b7415f8bf0e322efd53267.jpg"
                    alt="">

                <div class="contName">
                    <div class="contNameText">
                        农机
                    </div>
                </div>
            </div>
            <div class="contLi">
                <img src="https://lzdbuck.oss-cn-beijing.aliyuncs.com/20240822/3870ce7d71b7415f8bf0e322efd53267.jpg"
                    alt="">

                <div class="contName">
                    <div class="contNameText">
                        租地
                    </div>
                </div>
            </div>
            <div class="contLi">
                <img src="https://lzdbuck.oss-cn-beijing.aliyuncs.com/20240822/3870ce7d71b7415f8bf0e322efd53267.jpg"
                    alt="">

                <div class="contName">
                    <div class="contNameText">
                        收购
                    </div>
                </div>
            </div> -->
        </div>
        <!-- <FooterBottom></FooterBottom> -->
    </div>
</template>

<script>
import { categoryList, index } from '@/api/user/index.js'

export default {
    data() {
        return {
            dataNum: 4,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            list: [],
            lunboList: [],
            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            saleList: []
        }
    },
    created() {
        this.getInit()
        this.getInita()
    },
    methods: {
        swiperIndex(index) {
            console.log(index)
            // 1产品详情2公司详情0富文本
            if (this.lunboList[index].type == 1) {
                this.$router.push({
                    path: '/shopXq',
                    query: { id: this.lunboList[index].url }
                })
            } else if (this.lunboList[index].type == 2) {
                this.$router.push({
                    path: '/companyXq',
                    query: { id: this.lunboList[index].url }
                })
            } else if (this.lunboList[index].type == 0) {
                this.$router.push({
                    path: '/lbXq',
                    query: { id: this.lunboList[index].id }
                })
            }
        },
        getInita() {
            index({
                type: 4,
                page: this.listQuery.page,
                limit: this.listQuery.limit,
                keyword: ''
            }).then((res) => {
                if (res.code == 0) {
                    if (res.data.saleList.records.length > 0) {
                        for (
                            var c = 0;
                            c < res.data.saleList.records.length;
                            c++
                        ) {
                            res.data.saleList.records[c].pic =
                                res.data.saleList.records[c].pic.split(',')
                        }
                    }
                    this.saleList = res.data.saleList.records
                    this.lunboList = res.data.carouselList.records
                }
            })
        },
        toUrl(urlSrc, id, name) {
            if (urlSrc == '/temaiList') {
                if (localStorage.getItem('token')) {
                    this.$router.push({ path: urlSrc, query: { type: id } })
                } else {
                    this.$message({
                        message: '请登录后操作',
                        type: 'warning'
                    })
                }
                return
            }
            this.$router.push({ path: urlSrc, query: { id: id, name: name } })
        },
        getInit() {
            categoryList().then((res) => {
                if (res.code == 0) {
                    this.list = res.data
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.marketBox {
    padding: 0.54rem 0 0 0;

    .marketTit {
        color: #000000;
        font-size: 0.45rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .marketFu {
        color: #666666;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .jrtmBox {
        width: 15.5531rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0.29rem auto 0;
        .jrtmLi {
            width: 3.67rem;
            margin-bottom: 0.54rem;
            img {
                width: 3.67rem;
                height: 3.3rem;
                border-radius: 0.2921rem 0.2921rem 0 0;
                display: block;
                object-fit: cover;
            }
            .jrtmName {
                color: #121212;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                padding: 0.32rem;
                box-sizing: border-box;
                background: #ffffff;
            }
        }
        .jrtmLi:last-child:nth-child(4n-1) {
            // margin-right: calc(3.67rem+0.02rem/3);
            margin-right: calc(24% + 4% / 3);
        }
        .jrtmLi:last-child:nth-child(4n-2) {
            // margin-right: calc(7.34rem+0.04rem/3);
            margin-right: calc(48% + 8% / 3);
        }
    }
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.contBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 16.27rem;
    border-radius: 0.31rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    padding: 0.63rem 0.49rem 0;
    box-sizing: border-box;
    margin: 1.2rem auto 0.5rem;

    .contLi {
        width: 7.33rem;
        // height: 8.49rem;
        height: 3.68rem;
        border-radius: 0.28rem;
        opacity: 1;
        position: relative;
        margin-bottom: 1.28rem;

        img {
            width: 7.33rem;
            // height: 8.49rem;
            height: 3.68rem;

            border-radius: 0.28rem;
            object-fit: cover;
            //若图片不能全部显示,加上下面这条调节两个值可以设置要显示图片的哪个部分
            // object-position: 5px 10%;
        }

        .contName {
            width: 100%;
            height: 100%;
            border-radius: 0.28rem;

            background: linear-gradient(180deg, #0d253c00 52%, #0d253c 100%);
            position: absolute;
            top: 0;
            left: 0;

            .contNameText {
                color: #ffffff;
                font-size: 0.6rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                position: absolute;
                bottom: 0.71rem;
                left: 0.54rem;
            }
        }
    }
}
</style>