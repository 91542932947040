<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="chooseBig">
            <div class="chooseTab">
                <div
                    class="chooseTabLi"
                    :class="tabNum == 99 ? 'chooseTabLiA' : ''"
                    @click="shoose(99)"
                >
                    全部
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 1 ? 'chooseTabLiA' : ''"
                    @click="shoose(1)"
                >
                    已上架
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 0 ? 'chooseTabLiA' : ''"
                    @click="shoose(0)"
                >
                    已下架
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 2 ? 'chooseTabLiA' : ''"
                    @click="shoose(2)"
                >
                    审核中
                </div>
            </div>
            <div class="addBtn" @click="toUrl('/addShopInpt')">
                <img src="../assets/upShop.png" alt="" />
                上传商品
            </div>
        </div>
        <div class="inpt">
            <el-input
                placeholder="请输入关键字"
                prefix-icon="el-icon-search"
                v-model="keyword"
                @change="searchInpt"
            >
            </el-input>
            <!-- <i class="el-icon-search"></i>
            <el-input v-model="input" placeholder="请输入关键字"></el-input> -->
        </div>

        <div class="shopList" v-for="(item, index) in dataList" :key="index">
            <div class="shopLi">
                <div class="shopImage">
                    <img :src="item.pic[0]" alt="" />
                    <div class="shopLiTopStatus">
                        {{
                            (item.checkStatus == 0 && '审核中') ||
                            (item.checkStatus == 1 && '审核通过') ||
                            (item.checkStatus == 2 && '驳回')
                        }}
                    </div>
                </div>
                <div class="shopCont">
                    <div class="shopTop">
                        <div class="shopName">{{ item.title }}</div>
                        <div class="shopName">￥{{ item.price }}</div>
                    </div>
                    <div class="tip">类型：{{ item.categoryName || '' }}</div>
                    <div class="tip">产地：{{ item.source || '' }}</div>

                    <div class="tip">库存：{{ item.stock || '' }}</div>
                </div>
            </div>
            <div class="shopBtn" v-if="item.checkStatus == 1">
                <div
                    class="shopBtnLi"
                    v-if="item.status == 0"
                    @click="toUrl('/addShopInpt', item.id)"
                >
                    编辑
                </div>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon="el-icon-info"
                    icon-color="red"
                    title="当前商品确定删除吗？"
                    @confirm="deleteLi(item.id)"
                >
                    <!-- <el-button slot="reference">删除</el-button> -->
                    <div class="shopBtnLi" slot="reference">删除</div>
                </el-popconfirm>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon="el-icon-info"
                    icon-color="red"
                    title="当前商品确定下架吗？"
                    @confirm="grounding(item.id)"
                >
                    <div
                        class="shopBtnLi shopBtnLiA"
                        v-if="item.status == 1"
                        slot="reference"
                    >
                        下架
                    </div>
                </el-popconfirm>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon="el-icon-info"
                    icon-color="red"
                    title="当前商品确定上架吗？"
                    @confirm="grounding(item.id)"
                >
                    <div
                        class="shopBtnLi shopBtnLiA"
                        v-if="item.status == 0"
                        slot="reference"
                    >
                        上架
                    </div>
                </el-popconfirm>
            </div>

            <div class="shopBtn" v-if="item.checkStatus == 2">
                <div class="shopBtnLi" @click="toUrl('/addShopInpt', item.id)">
                    编辑
                </div>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon="el-icon-info"
                    icon-color="red"
                    title="当前商品确定删除吗？"
                    @confirm="deleteLi(item.id)"
                >
                    <!-- <el-button slot="reference">删除</el-button> -->
                    <div class="shopBtnLi" slot="reference">删除</div>
                </el-popconfirm>
            </div>
        </div>

        <div
            style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 14.29rem;
                margin: 0.5rem auto;
            "
        >
            <el-pagination
                :current-page="listQuery.page"
                :page-sizes="[10]"
                :page-size="listQuery.limit"
                :total="listQuery.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { goodsPage, updateStatus, delGoods } from '@/api/user/index.js'
export default {
    data() {
        return {
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            tabNum: 99,
            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            dataList: [],
            keyword: '',
            btnFlag: false
        }
    },
    created() {
        this.listQuery.page = 1
        this.dataList = []
        this.getInit()
    },
    methods: {
        toUrl(urlSrc, id) {
            if (id) {
                this.$router.push({ path: urlSrc, query: { id: id } })
            } else {
                this.$router.push(urlSrc)
            }
        },
        shoose(num) {
            this.tabNum = num
            this.listQuery.page = 1
            this.dataList = []
            this.getInit()
        },
        deleteLi(id) {
            delGoods({
                id: id
            }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '删除成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.listQuery.page = 1
                    this.dataList = []
                    this.getInit()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        grounding(id) {
            updateStatus({
                id: id
            }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.listQuery.page = 1
                    this.dataList = []
                    this.getInit()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        // 搜索
        searchInpt() {
            this.listQuery.page = 1
            this.dataList = []
            this.getInit()
        },
        getInit() {
            goodsPage({
                status: this.tabNum == 99 ? '' : this.tabNum,
                current: this.listQuery.page,
                size: this.listQuery.limit,
                title: this.keyword
            }).then((res) => {
                this.btnFlag = false
                if (res.code == 0) {
                    this.listQuery.total = Number(res.data.total)
                    for (var a = 0; a < res.data.records.length; a++) {
                        res.data.records[a].pic =
                            res.data.records[a].pic.split(',')
                    }
                    this.dataList = res.data.records
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding: 0.5rem 0;
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('@/assets/fwgtb.png') no-repeat center;
    background-size: 100% 100%;
    z-index: -1;
}
.chooseBig {
    // width: 100%;
    width: 14.29rem;
    margin: 0 auto 0;

    padding: 0.2rem;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background: #fafafa;
    // position: fixed;
    // top:0.8rem;
    // left: 0;
}
.addBtn {
    font-size: 0.3rem;
    display: flex;
    align-items: center;
    img {
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 0.1rem;
    }
}
.chooseTab {
    // width: 14.29rem;

    height: 1.55rem;
    border-radius: 0.26rem;
    opacity: 1;
    // border: 0.01rem solid #eaeaea;
    // background: #ffffff;
    display: flex;
    align-items: center;
    // justify-content: space-around;
    // margin: 0 auto 0;
    // padding: 0 2rem;
    box-sizing: border-box;

    .chooseTabLi {
        color: #3c3c3c;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        margin-right: 1.6rem;
    }

    .chooseTabLiA {
        color: #1b1b1b;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        position: relative;
    }

    .chooseTabLiA::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.2rem;
        // right: 10%;
        background: #239f59;
        width: 100%;
        height: 0.09rem;
        border-radius: 0.08rem;
    }
}

.inpt {
    width: 14.29rem;
    display: flex;
    align-items: center;
    margin: 0 auto;
    background: #ffffff;
    padding: 0.2rem;
    box-sizing: border-box;
    border-radius: 0.2rem;

    .el-icon-search {
        font-size: 0.3rem;
    }
}
.shopList {
    width: 14.29rem;
    background: #ffffff;
    margin: 0.2rem auto 0;
    border-radius: 0.26rem;
    padding: 0.5rem;
    box-sizing: border-box;
    .shopLi {
        display: flex;
        .shopImage {
            width: 1.8rem;
            height: 1.8rem;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0.26rem;
            }
            .shopLiTopStatus {
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.5);
                font-size: 0.24rem;
                padding: 0.1rem;
                box-sizing: border-box;
                width: 100%;

                text-align: center;
                color: #ffffff;
                border-radius: 0.26rem 0.26rem 0 0;
            }
        }

        .shopCont {
            margin-left: 0.26rem;
            flex: 1;
            .shopTop {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0.1rem;
                .shopName {
                    color: #1b1b1b;
                    font-size: 0.26rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
            }

            .tip {
                color: #6b6b6bcc;
                font-size: 0.22rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                margin-top: 0.05rem;
            }
        }
    }
    .shopBtn {
        display: flex;
        align-items: center;

        justify-content: flex-end;
        .shopBtnLi {
            width: 1.3rem;
            height: 0.51rem;
            border-radius: 0.5738rem;
            opacity: 1;
            border: 0.02rem solid #383838;
            color: #555555;
            font-size: 0.28rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.3rem;
        }

        .shopBtnLiA {
            color: #12924a;
            border: 0.02rem solid #12924a;
        }
    }
}
</style>