<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="addressBox">
            <div
                class="addressLi"
                v-for="(item, index) in dataList"
                :key="index"
            >
                <div class="addressTop">
                    {{ item.name }}
                </div>
                <div class="address">配送区域：{{ item.destinationNames }}</div>
                <div class="addressBottom">
                    <div
                        @click.stop="toUrl('/freightTemplateInpt', 2, item.id)"
                    >
                        <i class="el-icon-edit"></i>
                        编辑
                    </div>
                </div>
            </div>
            <div class="addShdz" @click="toUrl('/freightTemplateInpt', 1)">
                新增运费模版
            </div>
        </div>
    </div>
</template>

<script>
import { getConfig } from '@/api/user/index.js'

export default {
    data() {
        return {
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            dataList: []
        }
    },
    created() {
        this.init()
    },
    methods: {
        toUrl(urlSrc, num, id) {
            if (id) {
                this.$router.push({
                    path: urlSrc,
                    query: { type: num, id: id }
                })
            } else {
                this.$router.push({ path: urlSrc, query: { type: num } })
            }
        },

        init() {
            getConfig().then((res) => {
                console.log(res)

                this.dataList = res.data
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding: 0.5rem 0;
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('@/assets/fwgtb.png') no-repeat center;
    background-size: 100% 100%;
    z-index: -1;
}
.addressBox {
    .addressLi {
        width: 15.07rem;
        border-radius: 0.32rem;
        border: 0.02rem solid #00913e;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem #2fa5631a;
        margin: 0.32rem auto 0;
        padding: 0.5rem;
        box-sizing: border-box;
        .addressTop {
            color: #1b1b1b;
            font-size: 0.28rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            span {
                margin-left: 0.26rem;
            }
        }
        .address {
            color: #302e2f;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-top: 0.17rem;
        }
        .addressBottom {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #302e2f;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            .addressBottomLeft {
                display: flex;
                align-items: center;
                color: #302e2f;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                .shopCheck {
                    width: 0.37rem;
                    height: 0.37rem;
                    margin-right: 0.08rem;
                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
            }
            .addressBtn {
                display: flex;
                align-items: center;
                .addressBtnLi {
                    width: 1.1202rem;
                    height: 0.54rem;
                    border-radius: 0.6075rem;
                    opacity: 1;
                    border: 0.02rem solid #38383880;
                    color: #555555;
                    font-size: 0.28rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 0.11rem;
                }
            }
        }
    }
    .addShdz {
        width: 6.94rem;
        height: 0.82rem;
        border-radius: 0.4rem;
        background: linear-gradient(90deg, #0e8f46 0%, #12924a 100%);
        color: #ffffff;
        font-size: 0.28rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.76rem auto;
    }
}
</style>