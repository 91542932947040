<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="box">
            <!-- <div class="boxTit">基本信息</div> -->
            <div class="boxList">
                <div class="boxName"><span>*</span>模版名称</div>
                <el-input
                    v-model="from.name"
                    placeholder="请输入模版名称"
                ></el-input>
            </div>
            <div class="boxList">
                <div class="boxName"><span>*</span>计费方式</div>
                <el-select
                    v-model="unitName"
                    placeholder="请选择"
                    @change="jfFs"
                >
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>

        <div class="box">
            <div class="boxTit">配送区域及运费</div>
            <div class="boxList">
                <div class="boxName"><span>*</span>可配送区域</div>
                <!-- <el-input
                    v-model="input"
                    placeholder="请输入模版名称"
                ></el-input> -->
                <el-select
                    v-model="from.destinationIds"
                    multiple
                    placeholder="请选择"
                    @change="changeCity"
                >
                    <el-option
                        v-for="item in cityOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        :disabled="item.disabled"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="boxList">
                <div class="boxName"><span>*</span>首件重量(kg)</div>
                <el-input
                    v-model="from.initialNum"
                    placeholder="请输入首件重量(kg)"
                ></el-input>
            </div>

            <div class="boxList">
                <div class="boxName"><span>*</span>运费</div>
                <el-input
                    v-model="from.initialPrice"
                    placeholder="请输入运费(元)"
                ></el-input>
            </div>
            <div class="boxList">
                <div class="boxName"><span>*</span>续件重量(kg)</div>
                <el-input
                    v-model="from.increasingNum"
                    placeholder="请输入续件重量(kg)"
                ></el-input>
            </div>

            <div class="boxList">
                <div class="boxName"><span>*</span>续费</div>
                <el-input
                    v-model="from.increasingPrice"
                    placeholder="请输入续费(元)"
                ></el-input>
            </div>
        </div>
        <div class="addShdz" @click="addSure">确定</div>
    </div>
</template>
  
  <script>
import {
    getRegionList,
    addConfig,
    updateConfig,
    getConfigDetail
} from '@/api/user/index.js'

export default {
    data() {
        return {
            options: [
                {
                    value: '1',
                    label: '数量'
                },
                {
                    value: '2',
                    label: '重量'
                }
            ],
            unitName: '',
            cityOptions: [],

            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',

            from: {
                name: '', //模版名称
                destinationIds: '', //终点城市的id集合
                destinationNames: '', //终点城市的名称集合
                unit: '', //"计费方式：1表示数量几件货，2表示重量千克kg")
                initialNum: '', //首重/首件
                initialPrice: '', //首重价格
                increasingNum: '', //续重/续件
                increasingPrice: '' //续重价格
            },

            type: 1,
            btnFlag: false,
            id: ''
        }
    },

    created() {
        this.init()
        this.type = this.$route.query.type
        if (this.type == 2) {
            this.title = '修改'
            this.id = this.$route.query.id
            this.detailGet(this.id)
        }
    },
    methods: {
        jfFs(e) {
            console.log(e)
            this.from.unit = e
        },
        detailGet(id) {
            console.log(id, '123123123')
            getConfigDetail({ id: id }).then((res) => {
                console.log(res)
                if (res.code == 0) {
                    this.from.name = res.data.name
                    this.from.destinationIds =
                        res.data.destinationIds.split(',')
                    this.from.destinationNames =
                        res.data.destinationNames.split(',')
                    this.from.unit = res.data.unit
                    this.unitName = res.data.unit == 1 ? '数量' : '重量'
                    this.from.initialNum = res.data.initialNum
                    this.from.initialPrice = res.data.initialPrice
                    this.from.increasingNum = res.data.increasingNum
                    this.from.increasingPrice = res.data.increasingPrice

                    // console.log(this.area,99999999)
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        // 提交
        addSure() {
            if (!this.from.name) {
                this.$message({
                    message: '请输入模版名称',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (!this.from.unit) {
                this.$message({
                    message: '请选择计费方式',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (this.from.destinationIds.length <= 0) {
                this.$message({
                    message: '请选择配送区域',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (!this.from.initialNum) {
                this.$message({
                    message: '请输入首件重量(kg)',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (!this.from.initialPrice) {
                this.$message({
                    message: '请输入运费(元)',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (!this.from.increasingNum) {
                this.$message({
                    message: '请输入续件重量(kg)',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }

            if (!this.from.increasingPrice) {
                this.$message({
                    message: '请输入续费(元)',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (this.btnFlag) {
                return
            }
            // this.btnFlag = true
            let array = []
            this.cityOptions.forEach((obj) => {
                this.from.destinationIds.forEach((item) => {
                    if (obj.value == item) {
                        array.push(obj.label)
                    }
                })
            })

            console.log(array)
            this.from.destinationNames = array.join(',')
            this.from.destinationIds = this.from.destinationIds.join(',')
            if (this.type == 1) {
                addConfig(this.from).then((res) => {
                    this.btnFlag = false
                    if (res.code == 0) {
                        this.$message({
                            message: '提交成功',
                            type: 'success',
                            customClass: 'messageBoxTip'
                        })
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 1500)
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'warning',
                            customClass: 'messageBoxTip'
                        })
                    }
                })
            } else {
                this.from.id = this.id
                updateConfig(this.from).then((res) => {
                    this.btnFlag = false
                    if (res.code == 0) {
                        this.$message({
                            message: '提交成功',
                            type: 'success',
                            customClass: 'messageBoxTip'
                        })
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 1500)
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'warning',
                            customClass: 'messageBoxTip'
                        })
                    }
                })
            }
        },
        changeCity(e) {
            console.log(e)
            console.log(e.includes('-1'))
            if (e.includes('-1')) {
                this.cityOptions.forEach((item) => {
                    item.disabled = true
                })
                this.from.destinationIds = ['-1']
            } else {
                this.cityOptions.forEach((item) => {
                    item.disabled = false
                })
            }
            console.log(this.cityOptions)
        },

        init() {
            getRegionList().then((res) => {
                let array = []
                let map = {}
                res.data.forEach((item) => {
                    map = {
                        label: item.name,
                        value: item.id,
                        disabled: false
                    }

                    array.push(map)
                })
                array.unshift({
                    label: '全国',
                    value: '-1',
                    disabled: false
                })
                this.cityOptions = array
                console.log(this.cityOptions, 99999)
            })
        }
    }
}
</script>
  
  <style lang="scss" scoped>
.el-select {
    width: 100%;
}
.content {
    padding: 0.5rem 0;
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('@/assets/fwgtb.png') no-repeat center;
    background-size: 100% 100%;
    z-index: -1;
}
.box {
    width: 15.14rem;
    border-radius: 0.34rem;
    border: 0.04rem solid #ffffff;
    background: #ffffff;
    box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;

    margin: 0 auto 0.3rem;
    .boxTit {
        background: linear-gradient(90deg, #00903d 0%, #00903d00 100%);
        color: #ffffff;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';

        border-radius: 0.34rem 0 0 0;
        padding: 0.2rem 0.3rem;
        box-sizing: border-box;
    }
    .boxList {
        display: flex;
        align-items: center;
        padding: 0.3rem;

        box-sizing: border-box;
        .boxName {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            width: 2.5rem;
            span {
                color: #f92800;
            }
        }
    }
}
.addShdz {
    width: 6.94rem;
    height: 0.82rem;
    border-radius: 0.4rem;
    background: linear-gradient(90deg, #0e8f46 0%, #12924a 100%);
    color: #ffffff;
    font-size: 0.28rem;
    font-weight: 400;
    font-family: 'SourceHanSansCN';
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.76rem auto;
}
</style>