<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="shopBig">
            <!-- <div class="shopTit">
                <div class="shopDiv"></div>
                <div class="shopName">产品列表</div>
            </div> -->
            <div class="shopList">
                <div
                    class="shopLi"
                    v-for="(item, index) in dataMsg"
                    :key="index"
                    @click="toUrl('/shopXq', item.id)"
                >
                    <div class="shopTag" v-if="item.isHot == 1">推荐</div>
                    <div class="shopImg">
                        <img :src="item.pic[0]" alt="" />
                    </div>
                    <div class="shopTitName">
                        {{ item.title }}
                    </div>
                    <div class="shopNum">库存：{{ item.stock }}</div>
                    <div class="shopPrice">
                        ¥<span>{{ item.price }}</span
                        >/元
                    </div>
                    <div style="display: flex">
                        <div class="shopAdd">{{ item.source }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <FooterBottom></FooterBottom> -->
        <div
            style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
            "
        >
            <el-pagination
                :current-page="listQuery.page"
                :page-sizes="[10]"
                :page-size="listQuery.limit"
                :total="listQuery.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
            <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
        </div>
    </div>
</template>

<script>
import { moreProduct } from '@/api/user/index.js'

export default {
    data() {
        return {
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            type: '',
            dataMsg: [],
            keyword: ''
        }
    },
    created() {
        this.type = this.$route.query.type
        this.listQuery.page = 1
        this.dataMsg = []
        this.getInit()
    },
    methods: {
        pageSizeChangeHandle(val) {
            this.listQuery.page = 1
            this.listQuery.limit = Number(val)
            this.getInit()
        },
        pageCurrentChangeHandle(val) {
            this.listQuery.page = Number(val)
            this.getInit()
        },
        getInit() {
            let obj = {}
            if (this.type != 3) {
                obj.type = this.type
                obj.keyword = this.keyword
                obj.page = this.listQuery.page
            } else {
                obj.isHot = 1
                obj.keyword = this.keyword
                obj.page = this.listQuery.page
            }
            moreProduct(obj).then((res) => {
                if (res.code == 0) {
                    this.listQuery.total = Number(res.data.total)
                    for (var a = 0; a < res.data.records.length; a++) {
                        res.data.records[a].pic =
                            res.data.records[a].pic.split(',')
                    }
                    this.dataMsg = res.data.records
                }
            })
        },
        toUrl(urlSrc, id) {
            this.$router.push({ path: urlSrc, query: { id: id } })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.5rem;
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}
.shopBig {
    width: 16.27rem;
    margin: 0.4rem auto 0;
}

.shopTit {
    display: flex;
    align-items: center;
    margin-bottom: 0.42rem;
    .shopDiv {
        width: 0.08rem;
        height: 0.35rem;
        border-radius: 0 1rem 1rem 0;
        opacity: 1;
        border: 0.01rem solid #979797;
        background: #00903d;
    }

    .shopName {
        color: #00903d;
        font-size: 0.24rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        margin-left: 0.16rem;
    }
}
.shopList {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    margin-right: -0.3rem;

    .shopLi {
        margin-bottom: 0.69rem;
        width: 3.84rem;
        // height: 5.28rem;
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff80;
        background: #ffffff;
        box-shadow: 0 0 0.3rem 0 #0000001a;
        padding: 0.3rem 0.2rem;
        box-sizing: border-box;
        margin-right: 0.3rem;
        position: relative;
        .shopTag {
            width: 0.7rem;
            height: 0.28rem;
            border-radius: 0.32rem 0 0.2rem 0;
            opacity: 1;
            background: linear-gradient(90deg, #ff0000 0%, #f8632b 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 0.18rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            position: absolute;
            top: 0;
            left: 0;
        }
        .shopImg {
            width: 3.21rem;
            height: 2.15rem;
            border-radius: 0.16rem;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.16rem;
                object-fit: cover;
            }
        }
        .shopTitName {
            color: #333333;
            font-size: 0.28rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-top: 0.19rem;
        }
        .shopNum {
            color: #7f7f7f;
            font-size: 0.18rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-top: 0.17rem;
        }
        .shopPrice {
            color: #ff0000;
            font-weight: 500;
            font-size: 0.24rem;
            font-family: 'SourceHanSansCN';
            margin-top: 0.16rem;
            span {
                font-weight: 700;
                font-size: 0.36rem;
            }
        }
        .shopAdd {
            min-width: 0.96rem;
            height: 0.28rem;
            border-radius: 0.08rem;
            opacity: 1;
            background: #339cff33;
            margin-top: 0.1rem;
            color: #339cff;
            font-size: 0.18rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            text-align: center;
        }
    }
}
</style>