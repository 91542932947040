<template>
    <div class="popupBigC">
        <div class="popupBox">
            <div class="popupTop">
                <div></div>
                <div class="popupTit">取消订单</div>
                <div class="el-icon-close" @click="close"></div>
            </div>
            <!-- <div class="popupTip">
                <div class="popupTipTlt">温馨提示：</div>
                <div class="popupTipList">
                    <div class="popupTipLi">
                        1.消费者因个人原因申请退货，包邮商品需承担返回运费，非包邮商品需承担送返两程运费
                    </div>
                    <div class="popupTipLi">
                        2.限时特价、预约资格等购买优惠可能一并取消
                    </div>
                    <div class="popupTipLi">
                        3.如遇订单拆分，京券将换成同价值京豆返还
                    </div>
                    <div class="popupTipLi">
                        4.本单支付券退还以实际使用规则为准
                    </div>
                    <div class="popupTipLi">5.订单一旦取消，无法恢复</div>
                    <div class="popupTipLi">
                        6.微信搜一搜订单返现金额会从退款中扣除
                    </div>
                </div>
            </div> -->
            <div class="tit">请选择取消订单的原因（必选）</div>
            <div class="popupFlag" v-for="(item, index) in list" :key="index">
                <div
                    @click="chooseFlag(index)"
                    style="display: flex; align-items: center"
                >
                    <img
                        src="../assets/noactive.png"
                        alt=""
                        v-if="listIndex != index"
                    />

                    <img
                        src="../assets/activce.png"
                        alt=""
                        v-if="listIndex == index"
                    />
                    {{ item.name }}
                </div>
            </div>

            <div class="popupOff">
                <div class="popupOffName">提交后，将本单商品放回购物车中</div>
                <div class="popupOffCheck">
                    <el-switch v-model="value" active-color="#12924A">
                    </el-switch>
                </div>
            </div>
            <div class="popupBtn" @click="submit">提交</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            listIndex: 0,
            value: false,
            radio: '1',
            list: [
                {
                    name: '不想要了',
                    flag: false
                },
                {
                    name: '商品错选/多选',
                    flag: false
                },
                {
                    name: '商品无货',
                    flag: false
                },
                {
                    name: '地址信息填写错误',
                    flag: false
                },
                {
                    name: '供应商选多了',
                    flag: false
                }
            ]
        }
    },
    methods: {
        chooseFlag(index) {
            this.listIndex = index
        },

        submit() {
            let num = Number(this.listIndex) + 1
            this.$emit('suBmit', num, this.value)
        },

        close() {
            this.$emit('close', false)
        }
    }
}
</script>

<style lang="scss" scoped>
.popupBigC {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 999999;

    .popupBox {
        width: 11.08rem;
        height: 10rem;
        border-radius: 0.32rem;
        background: #ffffff;
        margin: 1.11rem auto 0;
        overflow-y: scroll;
        padding: 0.3rem;

        box-sizing: border-box;
        .popupTop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .popupTit {
                color: #000000;
                font-size: 0.36rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
            }
            .el-icon-close {
                font-size: 0.3rem;

                color: #a6a6a6;
            }
        }
        .popupTip {
            width: 100%;
            border-radius: 0.16rem;
            background: #f6f6f6;
            padding: 0.3rem;
            box-sizing: border-box;
            margin-top: 0.72rem;
            .popupTipTlt {
                color: #302e2f;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
            }
            .popupTipList {
                margin-top: 0.4rem;
                .popupTipLi {
                    color: #302e2f;
                    font-size: 0.26rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                }
            }
        }
        .tit {
            color: #000000;
            font-size: 0.28rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            margin-top: 0.48rem;
        }

        .popupFlag {
            color: #302e2f;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            display: flex;
            align-items: center;
            margin-top: 0.45rem;
            img {
                width: 0.37rem;
                height: 0.37rem;
                margin-right: 0.25rem;
            }
        }
        .popupOff {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.3rem 0;
            border-top: 1px solid #f3f3f3;
            margin-top: 0.4rem;
            .popupOffName {
                color: #1b1b1b;
                font-size: 0.32rem;
                font-weight: 500;
                font-family: 'SourceHanSansCN';
            }
        }
        .popupBtn {
            width: 100%;
            height: 0.82rem;
            border-radius: 0.4rem;
            background: linear-gradient(90deg, #0e8f46 0%, #12924a 100%);
            color: #ffffff;
            font-size: 0.28rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>