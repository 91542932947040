<template>
    <div class="content">
        <HeaderTop :parentData="dataNum"></HeaderTop>
        <el-carousel
            :interval="5000"
            arrow="always"
            height="9.17rem"
            indicator-position="none"
            style="position: relative"
        >
            <el-carousel-item v-for="(item, index) in lunboList" :key="index">
                <img
                    :src="item.pic"
                    alt=""
                    style="width: 100%; height: 100%; object-fit: cover"
                    @click="swiperIndex(index)"
                />
            </el-carousel-item>
        </el-carousel>
        <!-- <div class="header" :style="{background:'url('+banner+')',backgroundSize:cover}">
        <HeaderTop :parentData="dataNum"></HeaderTop>
        <div class="headerContText">
        <div class="headerContTextTit">
          粮坊
        </div>
        <div class="headerContTextTip">
          与未来同行，让我们的生活更美好
        </div>
      </div>
    </div> -->
        <div
            class="marketBox"
            style="background: #fafafa"
            v-if="saleList.length > 0"
        >
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0.2rem;
                    box-sizing: border-box;
                "
            >
                <div style="width: 1rem"></div>

                <div>
                    <div class="marketTit">今日特卖</div>
                </div>
                <div
                    style="
                        width: 1rem;
                        font-size: 0.2rem;
                        color: rgba(17, 17, 17, 0.5019607843);
                    "
                    @click="toUrl('/temaiList', 2)"
                >
                    更多产品
                </div>
            </div>
            <div class="jrtmBox">
                <div
                    class="jrtmLi"
                    v-for="(item, index) in saleList"
                    :key="index"
                    @click="toUrl('/shopXq', item.id)"
                >
                    <img :src="item.pic[0]" alt="" />
                    <div class="jrtmName">{{ item.title }}</div>
                </div>
            </div>
        </div>
        <div class="listBox">
            <div
                class="listLi"
                v-for="(item, index) in companyOneList"
                :key="index"
                @click="toUrl('/companyXq', item.id)"
            >
                <div class="listImg">
                    <img :src="item.factoryImg" alt="" />
                </div>
                <div class="listCont">
                    <div class="listName">
                        {{ item.title }}
                    </div>
                    <!-- <div class="listtip">
                        <div class="listtipName">
                            {{item.paramOne}}
                        </div>
                    </div> -->
                    <div class="listtip">
                        <div class="listtipName">
                            主营：{{ item.majorBusiness }}
                        </div>
                    </div>
                    <div class="listtipBtn">
                        了解更多信息<span class="el-icon-arrow-right"></span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="companyBox">
        <div class="companyLi" v-for="(item,index) in companyOneList" :key="index" @click="toUrl('/companyXq',item.id)">
            <div class="companyTop">
                <div class="companyTag">
                    {{item.briefName}}
                </div>
                <div class="companyName">{{item.title}}</div>
            </div>
            <div class="companyMsg">
              <div class="companyMsgLi" style="margin-right: 0.74rem;">联系人：{{item.legalName}}</div>
              <div class="companyMsgLi">联系电话：{{item.mobile}}</div>

            </div>
            <div class="companyCont">
              {{item.aboutUs}}
            </div>
            <div class="companyZy">主营：{{item.majorBusiness}}</div>
            <div class="companyBtn">
              了解更多信息<span class="el-icon-arrow-right"></span>
            </div>
        </div>
    </div> -->
        <div
            style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0 1.81rem;
                box-sizing: border-box;
            "
        >
            <el-pagination
                :current-page="listQuery.page"
                :page-sizes="[10]"
                :page-size="listQuery.limit"
                :total="listQuery.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
            <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
        </div>
        <!-- <FooterBottom></FooterBottom> -->
    </div>
</template>

<script>
// import pageIndex from '@/components/pageIndex' // Secondary package based on el-pagination

import { index } from '@/api/user/index.js'

export default {
    // components:{
    //   pageIndex
    // },
    data() {
        return {
            dataNum: 2,
            banner: JSON.parse(localStorage.getItem('webMsg')).picture.lf,
            companyOneList: [],

            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            lunboList: [],
            saleList: []

            // lunboList:JSON.parse(localStorage.getItem('lunboList'))
        }
    },
    created() {
        this.listQuery.page = 1
        this.companyOneList = []
        this.getInit()
    },
    methods: {
        swiperIndex(index) {
            console.log(index)

            // 1产品详情2公司详情0富文本
            if (this.lunboList[index].type == 1) {
                this.$router.push({
                    path: '/shopXq',
                    query: { id: this.lunboList[index].url }
                })
            } else if (this.lunboList[index].type == 2) {
                this.$router.push({
                    path: '/companyXq',
                    query: { id: this.lunboList[index].url }
                })
            } else if (this.lunboList[index].type == 0) {
                this.$router.push({
                    path: '/lbXq',
                    query: { id: this.lunboList[index].id }
                })
            }
        },
        pageSizeChangeHandle(val) {
            this.listQuery.page = 1
            this.listQuery.limit = Number(val)
            this.getInit()
        },
        pageCurrentChangeHandle(val) {
            this.listQuery.page = Number(val)
            this.getInit()
        },
        toUrl(name, id) {
            if (name == '/temaiList') {
                this.$router.push({ path: name, query: { type: id } })

                // if (localStorage.getItem('token')) {
                //     this.$router.push({ path: name, query: { type: id } })
                // } else {
                //     this.$message({
                //         message: '请登录后操作',
                //         type: 'warning'
                //     })
                // }
                return
            }

            this.$router.push({ path: name, query: { id: id } })

            // if (localStorage.getItem('token')) {
            //     this.$router.push({ path: name, query: { id: id } })
            // } else {
            //     this.$message({
            //         message: '请登录后操作',
            //         type: 'warning'
            //     })
            // }
        },
        getInit() {
            index({
                type: 2,
                page: this.listQuery.page,
                limit: this.listQuery.limit,
                keyword: ''
            }).then((res) => {
                if (res.code == 0) {
                    this.listQuery.total = Number(res.data.companyOneList.total)
                    this.companyOneList = res.data.companyOneList.records

                    if (res.data.saleList.records.length > 0) {
                        for (
                            var c = 0;
                            c < res.data.saleList.records.length;
                            c++
                        ) {
                            res.data.saleList.records[c].pic =
                                res.data.saleList.records[c].pic.split(',')
                        }
                    }
                    this.saleList = res.data.saleList.records
                    this.lunboList = res.data.carouselList.records
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.marketBox {
    padding: 0.54rem 0 0 0;

    .marketTit {
        color: #000000;
        font-size: 0.45rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .marketFu {
        color: #666666;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .jrtmBox {
        width: 15.5531rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0.29rem auto 0;
        .jrtmLi {
            width: 3.67rem;
            margin-bottom: 0.54rem;
            img {
                width: 3.67rem;
                height: 3.3rem;
                border-radius: 0.2921rem 0.2921rem 0 0;
                display: block;

                object-fit: cover;
            }
            .jrtmName {
                color: #121212;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                padding: 0.32rem;
                box-sizing: border-box;
                background: #ffffff;
            }
        }
        .jrtmLi:last-child:nth-child(4n-1) {
            // margin-right: calc(3.67rem+0.02rem/3);
            margin-right: calc(24% + 4% / 3);
        }
        .jrtmLi:last-child:nth-child(4n-2) {
            // margin-right: calc(7.34rem+0.04rem/3);
            margin-right: calc(48% + 8% / 3);
        }
    }
}
.listBox {
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1.32rem 1.81rem 0.01rem;
    box-sizing: border-box;
    .listLi {
        width: 7.48rem;
        // height: 2.68rem;
        height: 3.68rem;

        border-radius: 0.29rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
        padding: 0.4rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        .listImg {
            width: 3rem;
            // height: 1.86rem;
            height: 2.86rem;

            border-radius: 0.16rem;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.16rem;
                object-fit: cover;
            }
        }
        .listCont {
            margin-left: 0.37rem;
            flex: 1;
            .listName {
                color: #000000;
                font-size: 0.36rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
                margin-bottom: 0.36rem;
                display: -webkit-box; /* 显示多行文本容器 */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /*显示行数*/
                overflow: hidden; /*隐藏多出部分文字*/
                text-overflow: ellipsis; /*用省略号代替多出部分文字*/
                height: 1rem;
            }
            .listtip {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .listtipName {
                    color: #54545480;
                    font-size: 0.18rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
            }
            .listtipBtn {
                width: 1.5rem;
                height: 0.5rem;
                border-radius: 1rem;
                opacity: 1;
                background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
                box-shadow: 0 0 0.16rem 0 #00b34a66;
                color: #ffffff;
                font-size: 0.13rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                line-height: 0.5rem;
                margin-top: 0.5rem;
            }
        }
    }
}
.header {
    width: 100%;
    height: 8.02rem;
    background: url('@/assets/lslflc.png') no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    padding-left: 3.72rem;
    box-sizing: border-box;
    .headerContText {
        .headerContTextTit {
            color: #ffffff;
            font-size: 0.72rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
        }
        .headerContTextTip {
            color: #ffffff;
            font-size: 0.2rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
    }
}
.companyBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1.32rem 1.81rem 0.01rem;
    box-sizing: border-box;
    .companyLi {
        width: 7.4rem;
        height: 4.92rem;
        border-radius: 0.31rem;
        opacity: 1;
        border: 0.01rem solid #eaeaea;
        background: #ffffff;
        //   padding: 0.31rem;
        //   box-sizing: border-box;
        margin-bottom: 1.02rem;
        .companyTop {
            display: flex;
            align-items: center;
            background: linear-gradient(180deg, #fffad9 0%, #ffbe0000 100%);
            border-radius: 0.31rem 0.31rem 0 0;

            padding: 0.27rem 0.31rem;
            box-sizing: border-box;
            .companyTag {
                width: 0.39rem;
                height: 0.41rem;
                border-radius: 0.1rem;
                opacity: 1;
                //   border: 0.01rem solid #ffbe00;
                background: #ffbe00;
                color: #ffffff;
                font-size: 0.14rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: left;
                line-height: 0.16rem;
                text-align: center;
                display: flex;
                align-items: center;
            }
            .companyName {
                color: #333333;
                font-size: 0.3rem;
                font-weight: 500;
                font-family: 'SourceHanSansCN';
                margin-left: 0.14rem;
            }
        }
        .companyMsg {
            display: flex;
            align-items: center;
            margin-top: 0.19rem;
            padding: 0 0.31rem;
            box-sizing: border-box;
            .companyMsgLi {
                color: #555555;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
            }
        }
        .companyCont {
            padding: 0 0.31rem;
            box-sizing: border-box;
            color: #33333380;
            font-size: 0.28rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            display: -webkit-box; /* 显示多行文本容器 */
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /*显示行数*/
            overflow: hidden; /*隐藏多出部分文字*/
            text-overflow: ellipsis; /*用省略号代替多出部分文字*/
            margin-top: 0.41rem;
        }
        .companyZy {
            color: #333333cc;
            font-size: 0.24rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-top: 0.24rem;
            padding: 0 0.31rem;
            box-sizing: border-box;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .companyBtn {
            margin-left: 0.31rem;
            box-sizing: border-box;
            width: 1.5rem;
            height: 0.5rem;
            border-radius: 1rem;
            opacity: 1;
            background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
            box-shadow: 0 0 0.16rem 0 #00b34a66;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 0.13rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            text-align: center;
            margin-top: 0.79rem;
        }
    }
    .companyLi:nth-child(4n + 1) {
        .companyTop {
            background: linear-gradient(180deg, #fffad9 0%, #ffbe0000 100%);
            .companyTag {
                background: #ffbe00;
                color: #ffffff;
            }
        }
    }
    .companyLi:nth-child(4n + 2) {
        .companyTop {
            background: linear-gradient(180deg, #e2e8ff 0%, #ffbe0000 100%);
            .companyTag {
                background: #5e7efe;
            }
        }
    }
    .companyLi:nth-child(4n + 3) {
        .companyTop {
            background: linear-gradient(180deg, #ffe6d3 0%, #ffbe0000 100%);
            .companyTag {
                background: #ff8025;
            }
        }
    }
    .companyLi:nth-child(4n + 4) {
        .companyTop {
            background: linear-gradient(180deg, #ddfbfb 0%, #ffbe0000 100%);
            .companyTag {
                background: #5bebe9;
            }
        }
    }
}
</style>