<template>
    <div class="hello">
        <HeaderTop :parentData="dataNum"></HeaderTop>
        <el-carousel
            :interval="5000"
            arrow="always"
            height="9.17rem"
            indicator-position="none"
            style="position: relative"
        >
            <el-carousel-item
                v-for="(item, index) in initData.carouselList.records"
                :key="index"
            >
                <img
                    :src="item.pic"
                    alt=""
                    style="width: 100%; height: 100%; object-fit: cover"
                    @click="swiperIndex(index)"
                />
            </el-carousel-item>
            <div class="headerBottom">
                <div class="headerBottomLi">
                    <img :src="msgData.navbar.icon1" alt="" />
                    <div class="headerBottomCont">
                        <div class="headerBottomName">
                            {{ msgData.navbar.title1 }}
                        </div>
                        <div class="headerBottomTip">
                            {{ msgData.navbar.des1 }}
                        </div>
                    </div>
                </div>
                <div class="headerBottomLine"></div>
                <div class="headerBottomLi">
                    <img :src="msgData.navbar.icon2" alt="" />
                    <div class="headerBottomCont">
                        <div class="headerBottomName">
                            {{ msgData.navbar.title2 }}
                        </div>
                        <div class="headerBottomTip">
                            {{ msgData.navbar.des2 }}
                        </div>
                    </div>
                </div>
                <div class="headerBottomLine"></div>
                <div class="headerBottomLi">
                    <img :src="msgData.navbar.icon3" alt="" />
                    <div class="headerBottomCont">
                        <div class="headerBottomName">
                            {{ msgData.navbar.title3 }}
                        </div>
                        <div class="headerBottomTip">
                            {{ msgData.navbar.des3 }}
                        </div>
                    </div>
                </div>
                <div class="headerBottomLine"></div>
                <div class="headerBottomLi">
                    <img :src="msgData.navbar.icon4" alt="" />
                    <div class="headerBottomCont">
                        <div class="headerBottomName">
                            {{ msgData.navbar.title4 }}
                        </div>
                        <div class="headerBottomTip">
                            {{ msgData.navbar.des4 }}
                        </div>
                    </div>
                </div>
            </div>
        </el-carousel>
        <!-- <div class="header" :style="{ background: 'url(' + msgData.picture.ln + ')',backgroundSize:cover }">
      <div class="headerContText">
      </div>
      <div class="headerBottom">
        <div class="headerBottomLi">
          <img :src="msgData.navbar.icon1" alt="">
          <div class="headerBottomCont">
            <div class="headerBottomName">{{ msgData.navbar.title1 }}</div>
            <div class="headerBottomTip">{{ msgData.navbar.des1 }}</div>
          </div>
        </div>
        <div class="headerBottomLine"></div>
        <div class="headerBottomLi">
          <img :src="msgData.navbar.icon2" alt="">
          <div class="headerBottomCont">
            <div class="headerBottomName">{{ msgData.navbar.title2 }}</div>
            <div class="headerBottomTip">{{ msgData.navbar.des2 }}</div>
          </div>
        </div>
        <div class="headerBottomLine"></div>
        <div class="headerBottomLi">
          <img :src="msgData.navbar.icon3" alt="">
          <div class="headerBottomCont">
            <div class="headerBottomName">{{ msgData.navbar.title3 }}</div>
            <div class="headerBottomTip">{{ msgData.navbar.des3 }}</div>
          </div>
        </div>
        <div class="headerBottomLine"></div>
        <div class="headerBottomLi">
          <img :src="msgData.navbar.icon4" alt="">
          <div class="headerBottomCont">
            <div class="headerBottomName">{{ msgData.navbar.title4 }}</div>
            <div class="headerBottomTip">{{ msgData.navbar.des4 }}</div>
          </div>
        </div>
      </div>
    </div> -->
        <!-- <div class="contBox">
      <div class="contMsg">
        <div class="contImg">
          <img :src="msgData.company.pic" alt="">
        </div>
        <div class="contText">
          <div class="contTit">{{ msgData.company.title }}</div>
          <div class="contfu">
            {{ msgData.company.introduce }}
          </div>
          <div class="contBtn" @click="toUrl('/aboutUs')">集团详情<img src="../assets/righticon.png" alt=""></div>
        </div>
      </div>
      <div class="contDisplay">
        <div class="contDisplayLi">
          <div class="contDisplayNum">{{ msgData.navbar.num1 }}<span>{{ msgData.navbar.dw1 }}</span></div>
          <div class="contDisplayName">{{ msgData.navbar.text1 }}</div>
        </div>
        <div class="contDisplayLine"></div>
        <div class="contDisplayLi">
          <div class="contDisplayNum">{{ msgData.navbar.num2 }}<span>{{ msgData.navbar.dw2 }}</span></div>
          <div class="contDisplayName">{{ msgData.navbar.text2 }}</div>
        </div>
        <div class="contDisplayLine"></div>

        <div class="contDisplayLi">
          <div class="contDisplayNum">{{ msgData.navbar.num3 }}<span>{{ msgData.navbar.dw3 }}</span></div>
          <div class="contDisplayName">{{ msgData.navbar.text3 }}</div>
        </div>
        <div class="contDisplayLine"></div>

        <div class="contDisplayLi">
          <div class="contDisplayNum">{{ msgData.navbar.num4 }}<span>{{ msgData.navbar.dw4 }}</span></div>
          <div class="contDisplayName">{{ msgData.navbar.text4 }}</div>
        </div>
      </div>
    </div>
    <div class="chooseBox">
      <div class="chooseTit">
        为什么选择我们？
      </div>
      <div class="chooseFu">
        独特地利用最佳对齐实现直观的带宽。快速协调电子商务<br />
        通过革命性的催化剂应用。

      </div>
      <div class="chooseCont">
        <div class="chooseContLi">
          <img :src="msgData.choose.pic1" alt="">
          <div class="chooseContName">
            {{ msgData.choose.title1 }}
          </div>
          <div class="chooseContTip">
            {{ msgData.choose.describe1 }}
          </div>
          <div class="chooseContBtn" @click="wenben(msgData.choose, 1)">
            了解更多信息<span class="el-icon-arrow-right"></span>
          </div>
        </div>
        <div class="chooseContLi">
          <img :src="msgData.choose.pic2" alt="">
          <div class="chooseContName">
            {{ msgData.choose.title2 }}
          </div>
          <div class="chooseContTip">
            {{ msgData.choose.describe2 }}
          </div>
          <div class="chooseContBtn" @click="wenben(msgData.choose, 2)">
            了解更多信息<span class="el-icon-arrow-right"></span>
          </div>
        </div>
        <div class="chooseContLi">
          <img :src="msgData.choose.pic3" alt="">
          <div class="chooseContName">
            {{ msgData.choose.title3 }}
          </div>
          <div class="chooseContTip">
            {{ msgData.choose.describe3 }}
          </div>
          <div class="chooseContBtn" @click="wenben(msgData.choose, 3)">
            了解更多信息<span class="el-icon-arrow-right"></span>
          </div>
        </div>
      </div>
    </div> -->
        <div class="noticeTitBox">
            <div class="noticeTit">公告</div>
            <div class="list">
                <div
                    class="child"
                    v-for="(item, index) in initData.noticeList.records"
                    :key="item.id"
                    :class="!index && play ? 'toUp' : ''"
                >
                    {{ item.title }}
                </div>
            </div>
        </div>

        <div class="marketBox" style="background: #fafafa">
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0.2rem;
                    box-sizing: border-box;
                "
            >
                <div style="width: 1rem"></div>

                <div>
                    <div class="marketTit">今日特卖</div>
                </div>
                <div
                    style="
                        width: 1rem;
                        font-size: 0.2rem;
                        color: rgba(17, 17, 17, 0.5019607843);
                    "
                    @click="toUrl('/temaiList', 0)"
                >
                    更多产品
                </div>
            </div>
            <div class="jrtmBox">
                <div
                    class="jrtmLi"
                    v-for="(item, index) in initData.saleList.records"
                    :key="index"
                    @click="toUrl('/shopXq', item.id)"
                >
                    <img :src="item.pic[0]" alt="" />
                    <div class="jrtmName">{{ item.title }}</div>
                </div>
            </div>
        </div>
        <!-- <div class="marketBox">
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0.2rem;
                    box-sizing: border-box;
                "
            >
                <div style="width: 1rem"></div>

                <div>
                    <div class="marketTit">我们的粮市</div>
                    <div class="marketFu">为您选择合适的SEO</div>
                </div>
                <div
                    style="
                        width: 1rem;
                        font-size: 0.2rem;
                        color: rgba(17, 17, 17, 0.5019607843);
                    "
                    @click="toUrl('/shopList', 1)"
                >
                    更多产品
                </div>
            </div>

            <div class="marketShop">
                <div
                    class="marketShopLi"
                    v-for="(item, index) in initData.productZeroList.records"
                    :key="index"
                    @click="toUrl('/shopXq', item.id)"
                >
                    <img :src="item.pic[0]" alt="" v-if="index < 3" />
                    <div class="marketShopAdd" v-if="index < 3">
                        {{ item.source }}
                    </div>
                    <div class="marketShopBottom" v-if="index < 3">
                        <div class="marketShopName">{{ item.title }}</div>
                        <div class="marketShopPrice">¥{{ item.price }}</div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="marketBox">
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0.2rem;
                    box-sizing: border-box;
                "
            >
                <div style="width: 1rem"></div>

                <div>
                    <div class="marketTit">我们的粮坊</div>
                    <div class="marketFu">为您选择合适的SEO</div>
                </div>
                <div
                    style="
                        width: 1rem;
                        font-size: 0.2rem;
                        color: rgba(17, 17, 17, 0.5019607843);
                    "
                    @click="toUrl('/shopList', 2)"
                >
                    更多产品
                </div>
            </div>
            <div class="marketShop">
                <div
                    class="marketShopLi"
                    v-for="(item, index) in initData.productOneList.records"
                    :key="index"
                    @click="toUrl('/shopXq', item.id)"
                >
                    <img :src="item.pic" alt="" v-if="index < 3" />
                    <div class="marketShopAdd" v-if="index < 3">
                        {{ item.source }}
                    </div>
                    <div class="marketShopBottom" v-if="index < 3">
                        <div class="marketShopName">{{ item.name }}</div>
                        <div class="marketShopPrice">¥{{ item.price }}</div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="granaryBox">
            <div class="granaryTit">我们的粮仓</div>
            <div class="granaryTip">为您选择合适的SEO</div>
            <div class="granaryCont">
                <div class="granaryContMar">
                    <div
                        v-for="(item, index) in initData.companyTwoList.records"
                        :key="index"
                    >
                        <div
                            class="granaryContLi"
                            v-if="index <= 0"
                            @click="toUrl('/companyXq', item.id)"
                        >
                            <div class="granaryContLeft">
                                <div
                                    class="granaryContTop"
                                    style="margin-top: 1rem"
                                >
                                    <div class="granaryContLiJc">
                                        {{ item.briefName }}
                                    </div>
                                    <div class="granaryContLiName">
                                        {{ item.title }}
                                    </div>
                                </div>
                                <!-- <div class="granaryContTop">
                  <div class="granaryContTopLi">
                    联系人：{{ item.legalName }}
                  </div>
                  <div class="granaryContTopLi">
                    联系电话：{{ item.telephone }}
                  </div>
                </div> -->
                                <div
                                    class="granaryContText"
                                    style="padding-right: 0.01rem"
                                >
                                    {{ item.aboutUs }}
                                </div>
                            </div>
                            <div class="granaryImg">
                                <img :src="item.factoryImg" alt="" />
                            </div>
                        </div>
                    </div>

                    <div
                        v-for="(item, index) in initData.companyTwoList.records"
                        :key="index"
                    >
                        <div
                            class="granaryContLi"
                            v-if="index > 0 && index <= 1"
                            @click="toUrl('/companyXq', item.id)"
                        >
                            <div class="granaryImg">
                                <img
                                    :src="item.factoryImg"
                                    alt=""
                                    style="width: 4.4rem; height: 2.23rem"
                                />
                            </div>
                            <div
                                class="granaryContLeft"
                                style="margin-left: 0.35rem"
                            >
                                <div
                                    class="granaryContTop"
                                    style="margin-top: 0"
                                >
                                    <div class="granaryContLiJc">
                                        {{ item.briefName }}
                                    </div>
                                    <div class="granaryContLiName">
                                        {{ item.title }}
                                    </div>
                                </div>
                                <!-- <div class="granaryContTop">
                  <div class="granaryContTopLi">
                    联系人：{{ item.legalName }}
                  </div>
                  <div class="granaryContTopLi">
                    联系电话：{{ item.telephone }}
                  </div>
                </div> -->
                                <div class="granaryContText">
                                    {{ item.aboutUs }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="farmersBox">
            <!-- <div class="farmersTit">我们的粮农</div> -->
            <div class="farmersTit">我们的粮讯</div>

            <div class="farmersFu">
                第一个号角在他面前像一只大鹰，第二个号角在他的面前像一个大号角。

                <br />照顾好船前面的船，它会照顾好雪的。
            </div>
            <el-carousel
                :interval="5000"
                arrow="always"
                height="6rem"
                indicator-position="outside"
            >
                <el-carousel-item
                    v-for="(item, index) in initData.twoList.records"
                    :key="index"
                >
                    <div class="farmersCont">
                        <div
                            class="farmersLi"
                            v-for="(obj, objIndex) in item"
                            :key="objIndex"
                            @click="toUrl('/newXq', obj.id)"
                        >
                            <div class="farmersText" v-if="objIndex <= 1">
                                <div class="farmersLiNum">
                                    {{ index }}-{{ objIndex + 1 }}
                                </div>
                                <div class="farmersLiTit">
                                    {{ obj.title }}
                                </div>
                                <div class="farmersLiTip">
                                    {{ obj.pubDate }}
                                </div>
                            </div>
                            <div class="farmersUrl" v-if="objIndex <= 1">
                                <img :src="obj.pic" alt="" />
                            </div>
                        </div>
                        <div
                            class="farmersLi"
                            v-for="(obj, objIndex) in item"
                            :key="objIndex"
                            @click="toUrl('/newXq', obj.id)"
                        >
                            <div class="farmersUrl" v-if="objIndex > 1">
                                <img :src="obj.pic" alt="" />
                            </div>
                            <div class="farmersText" v-if="objIndex > 1">
                                <div class="farmersLiNum">
                                    {{ index }}-{{ objIndex + 1 }}
                                </div>
                                <div class="farmersLiTit">
                                    {{ obj.title }}
                                </div>
                                <div class="farmersLiTip">
                                    {{ obj.pubDate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
                <template #indicator="{ index }">
                    <!-- 自定义指示器内容 -->
                    <span>{{ index + 1 }} / 4</span>
                </template>
            </el-carousel>
        </div>
        <!-- <div class="joinBox">
      <div class="joinTit">我要加入粮之道</div>
      <div class="joinTip">加盟店遍布全国，央视上榜品牌 中国地板十大品牌 全国市场放心品牌</div>
      <div class="joinFrom">
        <div class="joinInpt">
          <div class="el-icon-s-custom icon"></div>
          <input type="text" placeholder="请输入您的姓名">
        </div>
        <div style="width: 0.07rem;"></div>
        <div class="joinInpt">
          <div class="el-icon-phone icon"></div>
          <input type="text" placeholder="请输入您的联系方式">
        </div>
        <div style="width: 0.07rem;"></div>

        <div class="joinInpt">
          <div class="el-icon-location icon"></div>
          <input type="text" placeholder="请输入您的地址">
        </div>
        <div style="width: 0.07rem;"></div>

        <button>我要加入</button>
      </div>
    </div> -->
        <!-- <FooterBottom></FooterBottom> -->
        <div class="xfBox">
            <div class="xfLi">
                <el-image
                    style="width: 0.5rem; height: 0.5rem; display: block"
                    :src="msgData.picture.appUrl"
                    :preview-src-list="srcList"
                >
                </el-image>
                <div class="xfLiName">android下载</div>
                <!-- <img src="https://lzdbuck.oss-cn-beijing.aliyuncs.com/20240827/f0c33fe6586946cca5b12c1ba046b38c.png" alt=""> -->
            </div>
            <div class="xfLi">
                <el-image
                    style="width: 0.5rem; height: 0.5rem; display: block"
                    :src="msgData.picture.iosUrl"
                    :preview-src-list="srcList"
                >
                </el-image>
                <div class="xfLiName">ios下载</div>
            </div>

            <div class="kefu" @click="kefuGo">
                <img src="@/assets/kf.png" alt="" />
            </div>
        </div>

        <el-dialog :visible.sync="dialogVisible" width="70%">
            <div style="width: 100%; height: 8rem">
                <iframe
                    :src="urlSrcKf"
                    frameborder="0"
                    width="100%"
                    height="100%"
                ></iframe>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import FooterBottom from './FooterBottom.vue'
import { getWeb, index, userInfo } from '@/api/user/index.js'
export default {
    // components: { FooterBottom },
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data() {
        return {
            urlSrcKf: '',
            dialogVisible: false,

            srcList: [],
            dataNum: 0,
            msgData: {
                picture: {},
                choose: {},
                company: {},
                navbar: {}
            },
            initData: {
                carouselList: {},
                companyOneList: {},
                companyTwoList: {},
                companyZeroList: {},
                noticeList: {},
                productOneList: {},
                productZeroList: {},
                twoList: {},
                saleList: {}
            },
            // ulList: [
            //     { msg: '这是第一条信息' },
            //     { msg: '这是第二条信息' },
            //     { msg: '这是第三条信息' },
            //     { msg: '这是第四条信息' },
            //     { msg: '这是第五条信息' },
            //     { msg: '这是第六条信息' },
            //     { msg: '这是第七条信息' },
            //     { msg: '这是第八条信息' },
            //     { msg: '这是第九条信息' },
            //     { msg: '这是第十条信息' }
            // ],
            play: true
        }
    },
    created() {
        this.wznr()
        this.getInit()
        if (localStorage.getItem('token')) {
            this.getUser()
        }
    },
    mounted() {
        setInterval(this.startPlay, 2000)
    },
    methods: {
        startPlay() {
            let that = this
            that.play = true //开始播放
            setTimeout(() => {
                that.initData.noticeList.records.push(
                    that.initData.noticeList.records[0]
                ) //将第一条数据塞到最后一个
                that.initData.noticeList.records.shift() //删除第一条数据
                that.play = false //暂停播放,此处修改，保证每一次都会有动画显示。 一定要修改属性，只有修改属性这样才能触发控件刷新冲毁!!!!
            }, 1000)
        },

        //只要对第一行进行滚动，下面的自动会跟着往上移动。
        isScroll(index) {
            if (index == 0) {
                return true
            } else {
                return false
            }
        },

        swiperIndex(index) {
            console.log(index)
            // uni.navigateTo({
            // 	url:this.listBannerList[index].url
            // })
            // 1产品详情2公司详情0富文本
            if (this.initData.carouselList.records[index].type == 1) {
                this.$router.push({
                    path: '/shopXq',
                    query: { id: this.initData.carouselList.records[index].url }
                })
            } else if (this.initData.carouselList.records[index].type == 2) {
                this.$router.push({
                    path: '/companyXq',
                    query: { id: this.initData.carouselList.records[index].url }
                })
            } else if (this.initData.carouselList.records[index].type == 0) {
                this.$router.push({
                    path: '/lbXq',
                    query: { id: this.initData.carouselList.records[index].id }
                })
            }
            // if(this.initData.carouselList.records[index].url){
            // 	// uni.navigateTo({
            // 	// 	url:this.listBannerList[index].url
            // 	// })
            // }else{
            //   this.$router.push({path:'/lbXq',query:{id:this.initData.carouselList.records[index].id}})
            // 	// uni.navigateTo({
            // 	// 	url:"/pagesC/pages/lcxq?id=" + this.listBannerList[index].id
            // 	// })
            // }
        },
        kefuGo() {
            if (localStorage.getItem('token')) {
                userInfo().then((res) => {
                    console.log(res)
                    if (res.code == 0) {
                        let url =
                            'https://kf.liangzhidao.com/index/index/home?theme=7571f9' +
                            '&visiter_id=' +
                            res.data.id +
                            '&visiter_name=' +
                            res.data.username +
                            '&avatar=' +
                            res.data.headUrl +
                            '&business_id=1&groupid=0'
                        // window.open(url, '_blank')
                        this.urlSrcKf = url
                        localStorage.setItem(
                            'userInfo',
                            JSON.stringify(res.data)
                        )
                    }
                })
            } else {
                let url =
                    'https://kf.liangzhidao.com/index/index/home?theme=7571f9' +
                    '&visiter_id=' +
                    '' +
                    '&visiter_name=' +
                    '' +
                    '&avatar=' +
                    '' +
                    '&business_id=1&groupid=0'
                // window.open(url, '_blank')
                this.urlSrcKf = url
            }
            this.dialogVisible = true
        },
        wenben(data, index) {
            localStorage.setItem('textCont', JSON.stringify(data))
            this.$router.push({ path: '/adoutXq', query: { type: index } })
        },
        wznr() {
            let array = []
            getWeb().then((res) => {
                array.push(res.data.picture.appUrl)
                array.push(res.data.picture.iosUrl)
                this.srcList = array
                this.msgData = res.data
                localStorage.setItem('webMsg', JSON.stringify(res.data))
            })
        },
        chunkArray(arr, chunkSize) {
            let chunks = []
            for (let i = 0; i < arr.length; i += chunkSize) {
                arr.index = i
                chunks.push(arr.slice(i, i + chunkSize))
            }
            return chunks
        },
        getInit() {
            index({ type: 0, page: 1, keyword: '' }).then((res) => {
                res.data.twoList.records = this.chunkArray(
                    res.data.twoList.records,
                    4
                )

                // if (res.data.productZeroList.records.length > 0) {
                //     for (
                //         var a = 0;
                //         a < res.data.productZeroList.records.length;
                //         a++
                //     ) {
                //         res.data.productZeroList.records[a].pic =
                //             res.data.productZeroList.records[a].pic.split(',')
                //     }
                // }

                // if (res.data.productOneList.records.length > 0) {
                //     for (
                //         var b = 0;
                //         b < res.data.productOneList.records.length;
                //         b++
                //     ) {
                //         res.data.productOneList.records[b].pic =
                //             res.data.productOneList.records[b].pic.split(',')
                //     }
                // }
                if (res.data.saleList.records.length > 0) {
                    for (var c = 0; c < res.data.saleList.records.length; c++) {
                        res.data.saleList.records[c].pic =
                            res.data.saleList.records[c].pic.split(',')
                    }
                }

                this.initData = res.data
                console.log(this.initData)
            })
        },

        toUrl(urlSrc, id) {
            if (urlSrc == '/shopXq' || urlSrc == '/companyXq') {
                this.$router.push({ path: urlSrc, query: { id: id } })
                // if (localStorage.getItem('token')) {
                //     this.$router.push({ path: urlSrc, query: { id: id } })
                // } else {
                //     this.$message({
                //         message: '请登录后操作',
                //         type: 'warning'
                //     })
                // }
                return
            }
            if (urlSrc == '/newXq') {
                this.$router.push({ path: urlSrc, query: { id: id } })
                return
            }
            if (urlSrc == '/shopList' || urlSrc == '/temaiList') {
                this.$router.push({ path: urlSrc, query: { type: id } })
                // if (localStorage.getItem('token')) {
                //     this.$router.push({ path: urlSrc, query: { type: id } })
                // } else {
                //     this.$message({
                //         message: '请登录后操作',
                //         type: 'warning'
                //     })
                // }
                return
            }
            this.$router.push(urlSrc)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.headerBottom {
    width: 100%;
    height: 1.32rem;
    opacity: 1;
    background: #00000080;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;

    .headerBottomLine {
        width: 1px;
        height: 1rem;
        opacity: 1;
        background: #ffffff26;
    }

    .headerBottomLi {
        display: flex;
        align-items: center;
        // width: 3.1rem;
        justify-content: center;
        padding: 0 0.63rem;
        box-sizing: border-box;

        img {
            width: 0.49rem;
            height: 0.49rem;
            object-fit: cover;
        }

        .headerBottomCont {
            margin-left: 0.18rem;

            .headerBottomName {
                color: #ffffff;
                font-size: 0.2rem;
                font-weight: 500;
                font-family: 'SourceHanSansCN';
            }

            .headerBottomTip {
                color: #ffffff;
                font-size: 0.12rem;
                font-weight: 500;
                font-family: 'SourceHanSansCN';
            }
        }
    }
}
.header {
    width: 100%;
    height: 9.17rem;
    background: url('@/assets/homeBg.png') no-repeat center;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;

    .headerContText {
        padding-left: 3.72rem;

        .headerContTextTit {
            color: #ffffff;
            font-size: 0.72rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
        }

        .headerContTextTip {
            color: #ffffff;
            font-size: 0.2rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
    }

    .headerBottom {
        width: 100%;
        height: 1.32rem;
        opacity: 1;
        background: #00000080;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;

        .headerBottomLine {
            width: 1px;
            height: 1rem;
            opacity: 1;
            background: #ffffff26;
        }

        .headerBottomLi {
            display: flex;
            align-items: center;
            // width: 3.1rem;
            justify-content: center;
            padding: 0 0.63rem;
            box-sizing: border-box;

            img {
                width: 0.49rem;
                height: 0.49rem;
                object-fit: cover;
            }

            .headerBottomCont {
                margin-left: 0.18rem;

                .headerBottomName {
                    color: #ffffff;
                    font-size: 0.2rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }

                .headerBottomTip {
                    color: #ffffff;
                    font-size: 0.12rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
            }
        }
    }
}

.contBox {
    width: 100%;
    height: 8.97rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url('@/assets/jsBg.png') no-repeat center;
    background-size: 100% 100%;

    .contMsg {
        display: flex;
        align-items: center;

        .contImg {
            img {
                width: 5.63rem;
                height: 3.91rem;
                border-radius: 0 0.4rem 0 0.4rem;
                object-fit: cover;
            }
        }

        .contText {
            width: 5.55rem;
            margin-left: 0.56rem;

            .contTit {
                color: #000000;
                font-size: 0.45rem;
                font-weight: 500;
                font-family: 'SourceHanSansCN';
            }

            .contfu {
                color: #666666;
                font-size: 0.16rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                margin-top: 0.16rem;
            }

            .contBtn {
                width: 1.8rem;
                height: 0.5799rem;
                border-radius: 1.0356rem;
                opacity: 1;
                background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
                box-shadow: 0 0 0.16rem 0 #00b34a66;
                color: #ffffff;
                font-size: 0.16rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.5rem;

                img {
                    width: 0.2568rem;
                    height: 0.0603rem;
                    margin-left: 0.08rem;
                    object-fit: cover;
                }
            }
        }
    }

    .contDisplay {
        width: 12.09rem;
        height: 1.48rem;
        background: #ffffff;
        margin-top: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .contDisplayLi {
            padding: 0 0.35rem;
            box-sizing: border-box;

            .contDisplayNum {
                color: #00943f;
                font-size: 0.3rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';

                span {
                    font-size: 0.16rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    margin-left: 0.04rem;
                }
            }

            .contDisplayName {
                color: #282828;
                font-size: 0.16rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
            }
        }

        .contDisplayLine {
            width: 0.01rem;
            height: 0.39rem;
            background: #f6f7f7;
        }
    }
}

.chooseBox {
    padding: 1.29rem 0 1rem;
    background: #fafafa;

    .chooseTit {
        color: #000000;
        font-size: 0.45rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .chooseFu {
        color: #666666;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        text-align: center;
        margin-top: 0.12rem;
    }

    .chooseCont {
        display: flex;
        align-items: center;
        width: 11.7rem;
        justify-content: space-between;
        margin: 0.9rem auto 0;

        .chooseContLi {
            width: 3.7rem;
            height: 6.16rem;
            opacity: 1;
            background: #ffffff;

            img {
                width: 3.7rem;
                height: 3.3rem;
                object-fit: cover;
            }

            .chooseContName {
                color: #000000;
                font-size: 0.24rem;
                font-weight: 500;
                font-family: 'SourceHanSansCN';
                text-align: center;
                margin-top: 0.36rem;
            }

            .chooseContTip {
                color: #aaaaaa;
                font-size: 0.15rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                width: 2.24rem;
                margin: 0.18rem auto 0;
                display: -webkit-box;
                /* 显示多行文本容器 */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                /*显示行数*/
                overflow: hidden;
                /*隐藏多出部分文字*/
                text-overflow: ellipsis;
                /*用省略号代替多出部分文字*/
            }

            .chooseContBtn {
                width: 1.5rem;
                height: 0.5rem;
                border-radius: 1rem;
                opacity: 1;
                background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
                box-shadow: 0 0 0.16rem 0 #00b34a66;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ffffff;
                font-size: 0.13rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                margin: 0.57rem auto 0;
            }
        }

        .chooseContLi:hover {
            box-shadow: 0.15rem 0.25rem 0.6rem -0.05rem #0000001a;
        }
    }
}
.noticeTitBox {
    width: 15.5531rem;
    display: flex;
    align-items: center;
    margin: 0 auto;
}
.noticeTit {
    color: #222222;
    font-size: 0.48rem;
    text-align: center;
    font-family: 'mianfeiziti';
    margin-right: 0.18rem;
}
.list {
    flex: 1;
    list-style: none;
    text-align: center;
    overflow: hidden;
    color: #ffff00;
    height: 1.11rem;
}
.child {
    text-align: left; /**覆盖外层设置的水平居中效果**/
    height: 1.11rem;
    line-height: 1.11rem; /**span 垂直居中需要这一句**/
    color: #222222;
    font-size: 0.28rem;
    font-weight: 400;
    font-family: 'SourceHanSansCN';
}

.toUp {
    margin-top: -1.11rem; //向上移
    transition: all 0.5s;
}

.marketBox {
    padding: 0.54rem 0 0 0;

    .marketTit {
        color: #000000;
        font-size: 0.45rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .marketFu {
        color: #666666;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .jrtmBox {
        width: 15.5531rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0.29rem auto 0;
        .jrtmLi {
            width: 3.67rem;
            margin-bottom: 0.54rem;
            img {
                width: 3.67rem;
                height: 3.3rem;
                border-radius: 0.2921rem 0.2921rem 0 0;
                display: block;
                object-fit: cover;
            }
            .jrtmName {
                color: #121212;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                padding: 0.32rem;
                box-sizing: border-box;
                background: #ffffff;
            }
        }
        .jrtmLi:last-child:nth-child(4n-1) {
            // margin-right: calc(3.67rem+0.02rem/3);
            margin-right: calc(24% + 4% / 3);
        }
        .jrtmLi:last-child:nth-child(4n-2) {
            // margin-right: calc(7.34rem+0.04rem/3);
            margin-right: calc(48% + 8% / 3);
        }
    }

    // .jrtmBox::after{
    //   content:'';
    //   width: 3.67rem;
    // }

    .marketShop {
        width: 15.5531rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.29rem auto 0;

        .marketShopLi {
            img {
                width: 4.9531rem;
                height: 3.7055rem;
                border-radius: 0.16rem;
                object-fit: cover;
            }

            .marketShopAdd {
                color: #11111180;
                font-size: 0.16rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: left;
                margin-top: 0.28rem;
            }

            .marketShopBottom {
                margin-top: 0.12rem;
                padding-bottom: 0.28rem;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .marketShopName {
                    color: #333333;
                    font-size: 0.2rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }

                .marketShopPrice {
                    color: #ea5d3f;
                    font-size: 0.24rem;
                    font-weight: 600;
                    font-family: 'SourceHanSansCN';
                }
            }
        }
    }
    .marketShop::after {
        content: '';
        width: 4.9531rem;
    }
}

.granaryBox {
    width: 100%;
    height: 13.53rem;
    background: url('@/assets/ourlcBg.png') no-repeat center;
    background-size: 100% 100%;
    padding-top: 1.48rem;
    margin-top: 0.5rem;

    .granaryTit {
        color: #ffffff;
        font-size: 0.45rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .granaryTip {
        color: #ffffff;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .granaryCont {
        width: 11.73rem;
        height: 9.55rem;
        opacity: 1;
        background: #ffffff;
        box-shadow: 0.15rem 0.25rem 0.6rem -0.05rem #0000001a;
        margin: 0.6rem auto 0;
        padding-left: 0.46rem;
        position: relative;
        border-radius: 0.31rem;
        .granaryContMar {
            position: absolute;
            top: -0.54rem;
            width: 11.73rem;
        }

        .granaryContLi {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            margin-bottom: 0.8rem;

            .granaryContLeft {
                width: 6.52rem;

                .granaryContTop {
                    display: flex;
                    align-items: center;
                    margin-top: 0.29rem;

                    .granaryContLiJc {
                        color: #ffffff;
                        font-size: 0.14rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                        width: 0.39rem;
                        height: 0.41rem;
                        text-align: center;
                        border-radius: 0.1rem;
                        opacity: 1;
                        border: 0.01rem solid #ffbe00;
                        background: #ffbe00;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        line-height: 0.16rem;
                    }

                    .granaryContLiName {
                        color: #333333;
                        font-size: 0.3rem;
                        font-weight: 500;
                        font-family: 'SourceHanSansCN';
                        margin-left: 0.1rem;
                    }

                    .granaryContTopLi {
                        color: #555555;
                        font-size: 0.26rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                    }

                    .granaryContTopLi:first-child {
                        margin-right: 0.26rem;
                    }
                }

                .granaryContText {
                    color: #333333;
                    font-size: 0.28rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    margin-top: 0.3rem;
                    display: -webkit-box;
                    /* 显示多行文本容器 */
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    /*显示行数*/
                    overflow: hidden;
                    /*隐藏多出部分文字*/
                    text-overflow: ellipsis;
                    /*用省略号代替多出部分文字*/
                    padding-right: 0.46rem;
                }
            }

            .granaryImg {
                img {
                    width: 4.71rem;
                    height: 3.27rem;
                    display: block;
                    object-fit: cover;
                    border-radius: 0.31rem;
                }
            }
        }
    }
}

.farmersBox {
    padding-top: 1rem;

    .farmersTit {
        color: #000000;
        font-size: 0.45rem;
        font-weight: 700;
        font-family: 'AlimamaShuHeiTi';
        text-align: center;
    }

    .farmersFu {
        color: #666666;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: 'AlibabaPuHuiTi';
        text-align: center;
        line-height: 0.38rem;
        margin-top: 0.1rem;
        margin-bottom: 0.8rem;
    }

    .farmersCont {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .farmersLi {
            display: flex;
            align-items: center;

            .farmersText {
                width: 4.43rem;
                padding: 0.34rem;
                box-sizing: border-box;

                .farmersLiNum {
                    color: #9d9d9d;
                    font-size: 0.24rem;
                    font-weight: M;
                    font-family: 'AlibabaPuHuiTi';
                }

                .farmersLiTit {
                    color: #545454;
                    font-size: 0.18rem;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                    margin-top: 0.17rem;
                }

                .farmersLiTip {
                    color: #545454;
                    font-size: 0.14rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                    margin-top: 0.12rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    /* 定义文本的行数 */
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .farmersUrl {
                width: 4.43rem;
                height: 2.95rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .farmersCont::after {
        content: '';
        width: 8.86rem;
    }
}

.joinBox {
    width: 100%;
    height: 3.93rem;
    background: url('@/assets/joinBg.png') no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .joinTit {
        color: #ffffff;
        font-size: 0.3rem;
        font-weight: 500;
        font-family: 'SourceHanSansCN';
    }

    .joinTip {
        color: #8c8c8c;
        font-size: 0.14rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        margin-top: 0.2rem;
    }

    .joinFrom {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;

        .joinInpt {
            width: 2.75rem;
            height: 0.5rem;
            border-radius: 0.08rem;
            background: rgba($color: #ffffff, $alpha: 0.5);
            display: flex;
            align-items: center;
            padding: 0 0.22rem;
            box-sizing: border-box;

            .icon {
                color: #ffffff;
                font-size: 0.3rem;
            }

            input {
                color: #ffffff;
                font-size: 0.14rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                flex: 1;
                margin-left: 0.1rem;
            }

            input::placeholder {
                color: #ffffff;
                /* 将颜色代码修改为需要的颜色 */
            }
        }

        button {
            width: 1.46rem;
            height: 0.5rem;
            border-radius: 0.08rem;
            opacity: 1;
            background: #00b34a;
            color: #ffffff;
            font-size: 0.18rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
        }
    }
}
.xfBox {
    position: fixed;
    right: 0.1rem;
    bottom: 2.5rem;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .kefu {
        width: 0.5rem;
        height: 0.5rem;
        background: #126324;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 0.3rem;
            height: 0.3rem;
        }
    }
    .xfLi {
        margin-bottom: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
            width: 0.5rem;
            height: 0.5rem;
            display: block;
        }

        .xfLiName {
            color: #000000;
            font-size: 0.13rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            margin-top: 0.1rem;
        }
    }
    // .xfLi:hover{
    //   img{
    //     width: 1.5rem;
    //     height: 1.5rem;
    //   }
    // }
}
</style>
