<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="listBox">
            <div
                class="listLi"
                v-for="(item, index) in dataMsg"
                :key="index"
                @click="toUrl('/shopXq', item.id, name)"
            >
                <div class="listImg">
                    <img :src="item.pic[0]" alt="" />
                </div>
                <div class="listCont">
                    <div class="listName">
                        {{ item.title }}
                    </div>
                    <div class="listtip">
                        <div class="listtipPrice">
                            ￥<span>{{ item.price }}</span>
                        </div>
                    </div>
                    <!-- <div class="listtip">
                        <div class="listtipName">
                            {{ item.paramTwo }}
                        </div>
                    </div> -->
                    <div class="listtipBtn">
                        了解更多信息<span class="el-icon-arrow-right"></span>
                    </div>
                </div>
            </div>
        </div>
        <div
            style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
            "
        >
            <el-pagination
                :current-page="listQuery.page"
                :page-sizes="[10]"
                :page-size="listQuery.limit"
                :total="listQuery.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
            <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
        </div>
    </div>
</template>

<script>
import { goodsList } from '@/api/user/index.js'

export default {
    data() {
        return {
            dataNum: 4,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            id: '',
            name: '',
            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            dataMsg: []
        }
    },
    created() {
        this.listQuery.page = 1
        this.dataMsg = []
        this.id = this.$route.query.id
        this.name = this.$route.query.name
        this.init()
    },
    methods: {
        pageSizeChangeHandle(val) {
            this.listQuery.page = 1
            this.listQuery.limit = Number(val)
            this.init()
        },
        pageCurrentChangeHandle(val) {
            this.listQuery.page = Number(val)
            this.init()
        },
        toUrl(urlSrc, id, name) {
            // this.$router.push(urlSrc)

            this.$router.push({
                path: urlSrc,
                query: { id: id, name: name }
            })
            // if (localStorage.getItem('token')) {
            //     this.$router.push({
            //         path: urlSrc,
            //         query: { id: id, name: name }
            //     })
            // } else {
            //     this.$message({
            //         message: '请登录后操作',
            //         type: 'warning'
            //     })
            // }
        },
        init() {
            goodsList({
                categoryId: this.id,
                page: this.listQuery.page,
                limit: this.listQuery.limit,
                keyword: ''
            }).then((res) => {
                if (res.code == 0) {
                    this.listQuery.total = Number(res.data.total)

                    for (var a = 0; a < res.data.records.length; a++) {
                        res.data.records[a].pic =
                            res.data.records[a].pic.split(',')
                    }
                    this.dataMsg = res.data.records
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}
.content {
    padding: 0.5rem 1.84rem;
    box-sizing: border-box;
}
.listBox {
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .listLi {
        width: 7.48rem;
        // height: 2.68rem;
        height: 3.68rem;

        border-radius: 0.29rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
        padding: 0.4rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        .listImg {
            width: 3rem;
            // height: 1.86rem;
            height: 2.86rem;

            border-radius: 0.16rem;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.16rem;
                object-fit: cover;
            }
        }
        .listCont {
            margin-left: 0.37rem;
            flex: 1;
            .listName {
                color: #000000;
                font-size: 0.36rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
                margin-bottom: 0.36rem;
                display: -webkit-box; /* 显示多行文本容器 */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /*显示行数*/
                overflow: hidden; /*隐藏多出部分文字*/
                text-overflow: ellipsis; /*用省略号代替多出部分文字*/
                height: 1rem;
            }
            .listtip {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .listtipName {
                    color: #54545480;
                    font-size: 0.18rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
                .listtipPrice {
                    color: #0e8f46;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                    font-size: 0.28rem;
                    span {
                        color: #0e8f46;
                        font-weight: 700;
                        font-family: 'SourceHanSansCN';
                        font-size: 0.36rem;
                    }
                }
            }
            .listtipBtn {
                width: 1.5rem;
                height: 0.5rem;
                border-radius: 1rem;
                opacity: 1;
                background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
                box-shadow: 0 0 0.16rem 0 #00b34a66;
                color: #ffffff;
                font-size: 0.13rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                line-height: 0.5rem;
                margin-top: 0.5rem;
            }
        }
    }
}
</style>