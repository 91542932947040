<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
            :position="position"></HeaderTop>
        <div class="box">
            <div class="boxTit">
                订单详情
            </div>
            <div class="boxMin">
                <div class="boxTop">
                    基本信息
                </div>
                <div class="boxCont">
                    <div class="boxLi">
                        <div class="boxName">
                            订单编号
                        </div>
                        <div class="boxNum">
                            {{ driverMsg.orderCode || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            状态
                        </div>
                        <div class="boxNum">
                            {{ (driverMsg.status == 1 && '待运输') || (driverMsg.status == 2 && '已装车') || (driverMsg.status == 3 && '已过磅') || (driverMsg.status == 4 && '已发货') || (driverMsg.status == 5 && '已验收') || (driverMsg.status == 6 && '已卸车过磅') || (driverMsg.status == 7 && '待结算') || (driverMsg.status == 8 && '已完成') || (driverMsg.status == 10 && '售后') }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            交易品种
                        </div>
                        <div class="boxNum">
                            {{ driverMsg.orderResult.productName || '' }}
                        </div>
                    </div>
                    <div class="boxLi" v-if="driverMsg.priceType==0">
                        <div class="boxName">
                            价格
                        </div>
                        <div class="boxNum">
                            {{ driverMsg.price || '' }}
                        </div>
                    </div>
                    <div class="boxLi" v-if="driverMsg.priceType==1">
                        <div class="boxName">
                            价格
                        </div>
                        <div class="boxNum">
                            以实时价格为准
                        </div>
                    </div>

                    <div class="boxLi">
                        <div class="boxName">
                            粮运(司机)
                        </div>
                        <div class="boxNum">
                            {{ driverMsg.driverName || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            联系电话
                        </div>
                        <div class="boxNum">
                            {{ driverMsg.driverMobile || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            车牌号
                        </div>
                        <div class="boxNum">
                            {{ driverMsg.carNumber || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            车挂号
                        </div>
                        <div class="boxNum">
                            {{ driverMsg.trailerNumber || '无' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            备注
                        </div>
                        <div class="boxNum">
                            {{ driverMsg.remark || '' }}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            驾驶证
                        </div>
                        <div class="boxImageBox" v-if="driverMsg.drivingLicense">
                            <img :src="driverMsg.drivingLicense" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="boxMin">
                <div class="boxTop">
                    自检信息
                </div>
                <div class="boxCont">
                    <div class="boxLi">
                        <div class="boxName">
                            水分
                        </div>
                        <div class="boxNum">
                            {{driverMsg.waterRequire||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            杂质
                        </div>
                        <div class="boxNum">
                            {{driverMsg.impurityRequire||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            生霉粒
                        </div>
                        <div class="boxNum">
                            {{driverMsg.moldyRequire||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            不完善率
                        </div>
                        <div class="boxNum">
                            {{driverMsg.unsoundKernel||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            蛋白质
                        </div>
                        <div class="boxNum">
                            {{driverMsg.proteinRequire||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            赤毒病率
                        </div>
                        <div class="boxNum">
                            {{driverMsg.wornRequire||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            容重
                        </div>
                        <div class="boxNum">
                            {{driverMsg.weightRequire||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            霉菌要求
                        </div>
                        <div class="boxNum">
                            {{driverMsg.mildewRequire||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            呕吐毒素
                        </div>
                        <div class="boxNum">
                            {{driverMsg.vomitoxinRequire||''}}
                        </div>
                    </div>
                    <!-- <div class="boxLi" style="width: 100%;">
                        <div class="boxName">
                            不完善粒
                        </div>
                        <div class="boxNum">
                            {{driverMsg.unsoundKernel||''}}
                        </div>
                    </div> -->
                    <div class="boxLi" style="width: 100%;">
                        <div class="boxName">
                            装车照片、视频
                        </div>
                        <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;">
                            <div class="boxImageBox" v-for="(item,index) in driverMsg.loadImgCheck" :key="index">
                                <img :src="item" alt="">
                            </div>
                            <div class="boxImageBox" v-for="(item,index) in driverMsg.loadVideoCheck" :key="index">
                                <video :src="item"></video>
                            </div>
                        </div>
                       
                    </div>
                    <div class="boxLi" style="width: 100%;">
                        <div class="boxName">
                            抽检照片、视频
                        </div>
                        <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;">
                            <div class="boxImageBox" v-for="(item,index) in driverMsg.samplingImgCheck" :key="index">
                                <img :src="item" alt="">
                            </div>
                            <div class="boxImageBox" v-for="(item,index) in driverMsg.samplingCheck" :key="index">
                                <video :src="item"></video>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div class="boxMin">
                <div class="boxTop">
                    验收信息
                </div>
                <div class="boxCont">
                    <div class="boxLi">
                        <div class="boxName">
                            验收地点
                        </div>
                        <div class="boxNum">
                            {{driverMsg.reachPlace||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            验收人员
                        </div>
                        <div class="boxNum">
                            {{driverMsg.unloadWorker||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            是否符合要求
                        </div>
                        <div class="boxNum">
                            {{(driverMsg.checkStatus==1&&'符合')||(driverMsg.checkStatus==2&&'不符合')||(driverMsg.checkStatus==0&&'待验收')}}
                        </div>
                    </div>
                    <div class="boxLi" v-if="driverMsg.checkStatus!=1">
                        <div class="boxName">
                            不符合原因
                        </div>
                        <div class="boxNum">
                            {{driverMsg.checkResults||''}}
                        </div>
                    </div>
                    <div class="boxLi">
                        <div class="boxName">
                            抵扣标准
                        </div>
                        <div class="boxNum">
                            {{driverMsg.deductions||''}}
                        </div>
                    </div>
                    <div class="boxLi" v-if="driverMsg.checkStatus!=1">
                        <div class="boxName">
                            扣款
                        </div>
                        <div class="boxNum" v-if="driverMsg.deduct">
                            {{driverMsg.deduct||'无'}}
                        </div>
                    </div>
                    <div class="boxLi" style="width: 100%;">
                        <div class="boxName">
                            验收单据
                        </div>
                        <div class="boxImageBox" v-if="driverMsg.unloadDoc">
                            <img :src="driverMsg.unloadDoc" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { orderDetails } from '@/api/user/index.js'

export default {
    data() {
        return {
            id: "",
            driverMsg: {},
            dataNum: 0,
            background: "#ffffff",
            color: "#000000",
            activeColor: "#00943F",
            position: "fixed",

        }
    },
    created() {
        this.id = this.$route.query.id
        this.init()
    },
    methods: {
        // 详情数据
        init() {
            orderDetails({ orderId: this.id }).then(res => {
                if (res.code == 0) {
                    if (res.data.loadVideo) {
                        res.data.loadVideo = res.data.loadVideo.split(',')
                    }
                    if (res.data.loadImg) {
                        res.data.loadImg = res.data.loadImg.split(',')
                    }
                    if (res.data.sampling) {
                        res.data.sampling = res.data.sampling.split(',')
                    }
                    if (res.data.samplingImg) {
                        res.data.samplingImg = res.data.samplingImg.split(',')
                    }
                    if (res.data.loadVideoCheck) {
                        res.data.loadVideoCheck = res.data.loadVideoCheck.split(',')
                    }
                    if (res.data.loadImgCheck) {
                        res.data.loadImgCheck = res.data.loadImgCheck.split(',')
                    }
                    if (res.data.samplingCheck) {
                        res.data.samplingCheck = res.data.samplingCheck.split(',')
                    }
                    if (res.data.samplingImgCheck) {
                        res.data.samplingImgCheck = res.data.samplingImgCheck.split(',')
                    }
                    this.driverMsg = res.data

                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })


        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.5rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.box {
    width: 15.68rem;
    border-radius: 0.32rem;
    opacity: 1;
    background: #ffffff;
    margin: 0 auto;
    padding: 0.43rem 0.59rem;
    box-sizing: border-box;

    .boxTit {
        color: #000000;
        font-size: 0.5rem;
        font-weight: 500;
        font-family: "SourceHanSansCN";
    }

    .boxMin {
        width: 14.48rem;
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
        margin-top: 0.4rem;

        .boxTop {
            width: 10.6rem;
            height: 0.7rem;
            border-radius: 0.3rem 0 0 0;
            opacity: 1;
            background: linear-gradient(90deg, rgba(47, 165, 99, 0.5) 0%, #2fa56300 100%);
            color: #2fa563;
            font-size: 0.32rem;
            font-weight: 500;
            font-family: "SourceHanSansCN";
            display: flex;
            align-items: center;
            padding-left: 0.65rem;
        }

        .boxCont {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 0.5rem 0.65rem;
            box-sizing: border-box;

            .boxLi {
                width: 50%;
                margin-bottom: 0.5rem;

                .boxName {
                    color: #34323166;
                    font-size: 0.26rem;
                    font-weight: 400;
                    font-family: "SourceHanSansCN";
                }

                .boxNum {
                    color: #343231;
                    font-size: 0.28rem;
                    font-weight: 500;
                    font-family: "SourceHanSansCN";
                }

                .boxImageBox {
                    width: 2.89rem;
                    height: 2.16rem;
                    border-radius: 0.2rem;
                    margin-top: 0.1rem;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 0.2rem;
                        object-fit: cover;
                    }
                    video{
                        width: 100%;
                        height: 100%;
                        border-radius: 0.2rem;
                    }
                }
            }
        }
    }
}
</style>