<template>
  <div class="content">
      <div class="bgColor"></div>
      <HeaderTop :parentData="dataNum" :background="background" :color="color" :activeColor="activeColor"
          :position="position"></HeaderTop>
      <el-form @submit.native.prevent :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <div class="box">
              <div class="boxTit">
                  粮仓(供应商)入驻申请
              </div>
              <el-form-item prop="title">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>企业名称</div>
                      <!-- <div class="inpt">
            <input v-model="ruleForm.price" type="number" placeholder="请输入价格" style="width: 7.63rem;"/>

            <div class="el-icon-arrow-right"></div>
          </div> -->
                      <el-input class="input" v-model="ruleForm.title" size="medium" placeholder="请输入企业名称"
                          suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>

                  </div>
              </el-form-item>
              <!-- <el-form-item prop="briefName">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>企业简称(四个字)</div>
                      
                      <el-input class="input" v-model="ruleForm.briefName" size="medium" placeholder="请输入企业简称"
                          suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item> -->
              <el-form-item prop="creditCode">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>统一社会信用代码</div>
                      <!-- <div class="inpt">
            <input v-model="ruleForm.productName" placeholder="请输入交易品种" style="width: 7.63rem;"/>
            <div class="el-icon-arrow-right"></div>
          </div> -->
                      <el-input v-model="ruleForm.creditCode" placeholder="请输入统一社会信用代码"
                          suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>
              <el-form-item prop="legalName">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>法人代表姓名</div>
                      <!-- <div class="inpt">
            <input v-model="ruleForm.dealType" placeholder="请输入交易类型" style="width: 7.63rem;"/>
            <div class="el-icon-arrow-right"></div>
          </div> -->
                      <el-input v-model="ruleForm.legalName" placeholder="请输入法人代表姓名" suffix-icon="el-icon-arrow-right"
                          style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>
              <el-form-item prop="legalId">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>法人身份证号</div>
                      <!-- <div class="inpt">
            <input v-model="ruleForm.producer" placeholder="请输入产地" style="width: 7.63rem;"/>
            <div class="el-icon-arrow-right"></div>
          </div> -->
                      <el-input v-model="ruleForm.legalId" placeholder="请输入法人身份证号" suffix-icon="el-icon-arrow-right"
                          style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>
              <el-form-item prop="mobile">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>法人/负责人电话(登录账号)</div>
                      <!-- <div class="inpt">
            <input v-model="ruleForm.level" placeholder="请输入国标等级" style="width: 7.63rem;"/>
            <div class="el-icon-arrow-right"></div>
          </div> -->
                      <el-input v-model="ruleForm.mobile" placeholder="请输入代理人电话" suffix-icon="el-icon-arrow-right"
                          style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>

              <el-form-item prop="majorBusiness">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>公司主营业务</div>
                      <el-input v-model="ruleForm.majorBusiness" placeholder="请输入公司主营业务"
                          suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>

              <el-form-item prop="businessAddress">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>公司地址</div>
                      <el-input v-model="ruleForm.businessAddress" placeholder="请输入公司地址"
                          suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>
              <el-form-item prop="telephone">
                  <div class="boxLi">
                      <div class="boxName">固定电话</div>
                      <el-input v-model="ruleForm.telephone" placeholder="请输入固定电话" suffix-icon="el-icon-arrow-right"
                          style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>
              <el-form-item prop="fax">
                  <div class="boxLi">
                      <div class="boxName">传真</div>
                      <el-input v-model="ruleForm.fax" placeholder="请输入传真" suffix-icon="el-icon-arrow-right"
                          style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>
              <div class="boxLi">
                  <div class="boxName"><span>*</span>营业有效期是否长期有效</div>
                  <el-switch @change="changeCheck" v-model="checked" active-color="#00903d" inactive-color="#999999">
                  </el-switch>
              </div>
              <el-form-item prop="returnTime" v-if="!checked">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>营业有效期</div>
                      <el-date-picker v-model="ruleForm.businessEx" type="date" placeholder="请选择营业有效期"
                          style="width: 7.63rem;">
                      </el-date-picker>
                  </div>
              </el-form-item>
              <el-form-item prop="accountInfo">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>开户名称</div>
                      <el-input v-model="ruleForm.accountInfo" placeholder="请输入开户名称" suffix-icon="el-icon-arrow-right"
                          style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>
              <!-- <el-form-item prop="laterAuth">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>后期企业认证信息</div>
                      <el-input v-model="ruleForm.laterAuth" placeholder="请输入后期企业认证信息"
                          suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item> -->
              <el-form-item prop="factoryImg">
                    <div class="boxImage">
                        <div class="boxName"><span>*</span>厂区图片</div>
                        <div class="imagePer" @click="triggerFileInput(3)">
                            <input type="file" @change="handleFileChange" ref="fileInputssss" name="avatar" id="avatar"
                                accept="image/*" style="display: none;">
                            <img :src="ruleForm.factoryImg" alt="" v-if="ruleForm.factoryImg">
                            <div class="el-icon-plus" v-if="!ruleForm.factoryImg"></div>
                        </div>
                        <!-- <image-upload  
                            :upload-url="action"  
                            :file-list="ruleForm.businessLicense"  
                            @businessLicenseGet="businessLicenseGet"
                            limit="1"
                            />   -->
                    </div>
                </el-form-item>
              <el-form-item prop="businessLicense">
                  <div class="boxImage">
                      <div class="boxName"><span>*</span>营业执照</div>
                      <div class="imagePer" @click="triggerFileInput(0)">
                          <input type="file" @change="handleFileChange" ref="fileInput" name="avatar" id="avatar"
                              accept="image/*" style="display: none;">
                          <img :src="ruleForm.businessLicense" alt="" v-if="ruleForm.businessLicense">
                          <div class="el-icon-plus" v-if="!ruleForm.businessLicense"></div>
                      </div>
                      <!-- <image-upload  
                          :upload-url="action"  
                          :file-list="ruleForm.businessLicense"  
                          @businessLicenseGet="businessLicenseGet"
                          limit="1"
                          />   -->
                  </div>
              </el-form-item>
              <el-form-item prop="idCardFront">
                  <div class="boxImage">
                      <div class="boxName"><span>*</span>身份证正面</div>
                      <div class="imagePer" @click="triggerFileInput(1)">
                          <input type="file" @change="handleFileChange" ref="fileInputs" accept="image/*"
                              style="display: none;">
                          <img :src="ruleForm.idCardFront" alt="" v-if="ruleForm.idCardFront">
                          <div class="el-icon-plus" v-if="!ruleForm.idCardFront"></div>
                      </div>
                      <!-- <image-upload  
                          :upload-url="action"  
                          :file-list="ruleForm.idCardFront"  
                          @update:file-list="handleFileListUpdate"
                          limit="1"
                          />   -->
                  </div>
              </el-form-item>
              <el-form-item prop="idCardBack">
                  <div class="boxImage">
                      <div class="boxName"><span>*</span>身份证反面</div>
                      <div class="imagePer" @click="triggerFileInput(2)">
                          <input type="file" @change="handleFileChange" ref="fileInputss" accept="image/*"
                              style="display: none;">
                          <img :src="ruleForm.idCardBack" alt="" v-if="ruleForm.idCardBack">
                          <div class="el-icon-plus" v-if="!ruleForm.idCardBack"></div>
                      </div>
                      <!-- <image-upload :upload-url="action" :file-list="ruleForm.idCardBack"
                          @update:file-list="handleFileListUpdate" limit="1" /> -->
                  </div>
              </el-form-item>
              <el-form-item prop="scale">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>烘干塔和仓储规模</div>
                      <el-input v-model="ruleForm.scale" placeholder="请输入烘干塔和仓储规模" suffix-icon="el-icon-arrow-right"
                          style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>
              <el-form-item prop="supply">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>每年收储和供应量(吨)</div>
                      <el-input v-model="ruleForm.supply" placeholder="请输入每年收储和供应量" suffix-icon="el-icon-arrow-right"
                          style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>
              <el-form-item prop="workshop">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>厂房性质</div>
                      <el-input v-model="ruleForm.workshop" placeholder="请输入厂房性质" suffix-icon="el-icon-arrow-right"
                          style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>
              <el-form-item prop="invoicing">
                  <div class="boxLi">
                      <div class="boxName"><span>*</span>可开票金额</div>
                      <!-- <el-input v-model="ruleForm.scale" placeholder="请选择签订战略合同协议" suffix-icon="el-icon-arrow-right"
                          style="width: 7.63rem;"></el-input> -->
                          <el-date-picker v-model="ruleForm.invoicing" type="date" placeholder="请输入可开票金额"
                          style="width: 7.63rem;">
                      </el-date-picker>
                  </div>
              </el-form-item>
              <el-form-item prop="letter">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>承诺函文件(签字盖章)</div>
                        <input type="file" @change="handleFileChange" ref="fileInputsss"
                                style="display: none;">
                                <div v-if="ruleForm.letter" style="margin-right: 0.3rem;">
                                    {{ruleForm. letter}}
                                </div>
                                <button class="shangchuan" @click="triggerFileInput(4)">点击上传</button>
                        <button class="shangchuan" style="margin-left: 0.2rem;" @click="xz">点击下载承诺函模版</button>
                        <!-- <div @click="triggerFileInput(4)">
                            点击上传
                        </div>
                     
                        <div style="margin-left: 0.2rem;" @click="xz">点击下载承诺函模版</div> -->
                    </div>
                </el-form-item>
              <el-form-item prop="aboutUs">
                  <div class="boxLi" style="align-items: start;">
                      <div class="boxName"><span>*</span>公司介绍</div>
                      <el-input type="textarea" autosize v-model="ruleForm.aboutUs" placeholder="请输入公司介绍"
                          style="width: 7.63rem;"></el-input>
                  </div>
              </el-form-item>


          </div>
          <div class="agreeBox" @click="toUrl('/xieyiCont','粮仓（供应商）入驻承诺函')">
              <div class="agreeQuan" @click.stop="agreeFlag=!agreeFlag" :class="agreeFlag?'agreeQuanA':''">
                  <div class="el-icon-check" v-if="agreeFlag"></div>
              </div>
              我已阅读并同意《粮仓（供应商）入驻承诺函》
          </div>
          <el-form-item>
              <div class="btnBoxBig">
                  <button class="btnBox" @click="submitForm('ruleForm')">确定提交</button>

              </div>
              <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
          </el-form-item>

      </el-form>
  </div>
</template>

<script>
import { upload, joinIn,getValue } from '@/api/user/index.js';

// import ImageUpload from '../../components/upload/singleImage.vue'
export default {
  // components: {
  //     ImageUpload
  // },
  data() {
      return {
        urlSrcFile:"",
        selectedFile: null,
          agreeFlag:false,
          chooseNum: 0,
          imageList: [], // 初始图片列表
          // headers:{
          //     token:localStorage.getItem('token')
          // },
          action: "/api/upload",
          dialogImageUrl: '',
          dialogVisible: false,
          disabled: false,

          checked: true,
          dataNum: 6,
          background: "#ffffff",
          color: "#000000",
          activeColor: "#00943F",
          position: "fixed",
          ruleForm: {
            letter:"",
            title: "", //企业名称
					// briefName:"",//企业简称
					creditCode: "", //统一社会信用代码
					legalName: "", //法人代表姓名
					legalId: "", //法人代表身份证号
					mobile: "", //法人/负责人电话(登录账号)
					majorBusiness: "", //公司主营业务
					businessAddress: "", //公司地址
					telephone: "", //固定电话
					fax: "", //传真
					businessEx: "长期", //营业有效期
					accountInfo: "", //开户名称
					// laterAuth: "", //后期企业认证信息
                    factoryImg:"",
					businessLicense: "", //营业执照
					idCardFront: "", //身份证正面
					idCardBack: "", //身份证反面
					// halfFinishProduct: "", //企业需求半年成品情况说明
					// finishProduct: "", //请输入企业产品成品情况说明
					// dailyRequir: "", //产品每天粮食需求量
					// yield: "", //产品产量
					// scale: "", //厂子规模
					// signContract: "", //签订战略合同协议(一年制)
					scale:"",//烘干塔和仓储规模
					supply:"",//每年收储和供应量
					workshop:"",//厂房性质
					invoicing:"",//可开票金额
					aboutUs:"",//公司介绍
					userType: 3//粮仓(供应商)
          },
          rules: {
              title: [{
          required: true,
          message: '请输入企业名称',
          // 可以单个或者同时写两个触发验证方式 
          trigger: ['change', 'blur'],
        }],
        // briefName: [{
        //   required: true,
        //   message: '请输入企业简称(四个字)',
        //   // 可以单个或者同时写两个触发验证方式 
        //   trigger: ['change', 'blur'],
        // },
        // {
        //   min: 4,
        //   max: 4,
        //   message: '简称四个字',
        //   // 可以单个或者同时写两个触发验证方式 
        //   trigger: ['change', 'blur'],
        // }],
        creditCode: [{
          required: true,
          message: '请输入统一社会信用代码',
          trigger: ['change', 'blur']
        }],
        legalName: [{
          required: true,
          message: '请输入法人代表姓名',
          trigger: ['change', 'blur']
        }],
        legalId: [{
          required: true,
          message: '请输入法人代表身份证号',
          trigger: ['change', 'blur']
        },
        {
          pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
          message: '请输入正确的身份证号',
          trigger: ['change', 'blur']
        }],
        mobile: [{
            required: true,
            message: '请输入法人/负责人电话(登录账号)',
            trigger: ['change', 'blur']
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入正确的手机号',
            trigger: ['change', 'blur']
          }
        ],
        majorBusiness: [{
          required: true,
          message: '请输入公司主营业务',
          trigger: ['change', 'blur']
        }],
        factoryImg: [{
                    required: true,
                    message: '请上传厂区图片',
                    trigger: ['change', 'blur']
                }],
        businessAddress: [{
          required: true,
          message: '请输入公司地址',
          trigger: ['change', 'blur']
        }],
        businessEx: [{
          required: true,
          message: '请选择营业有效期',
          trigger: ['change', 'blur']
        }],
        accountInfo: [{
          required: true,
          message: '请输入开户名称',
          trigger: ['change', 'blur']
        }],
        
        businessLicense: [{
          required: true,
          message: '请上传营业执照',
          trigger: ['change', 'blur']
        }],
        idCardFront: [{
          required: true,
          message: '请上传身份证正面',
          trigger: ['change', 'blur']
        }],
                  idCardBack: [{
          required: true,
          message: '请上传身份证反面',
          trigger: ['change', 'blur']
        }],
        scale: [{
						required: true,
						message: '请输入烘干塔和仓储规模',
						trigger: ['change', 'blur']
					}],
					supply: [{
						required: true,
						message: '请输入每年收储和供应量',
						trigger: ['change', 'blur']
					}],
					workshop: [{
						required: true,
						message: '请输入厂房性质',
						trigger: ['change', 'blur']
					}],
					invoicing: [{
						required: true,
						message: '请输入可开票金额',
						trigger: ['change', 'blur']
					}],
                    letter:[{
						required: true,
						message: '请上传签字盖章的承诺函',
						trigger: ['change', 'blur']
					}],
        aboutUs:[{
          required: true,
          message: '请输入公司介绍',
          trigger: ['change', 'blur']
        }]
          }
      }
  },
  created(){
this.cnh()
    },
    methods: {
        xz(){
            window.location.href = this.urlSrcFile;
        },
        cnh(){
            getValue({key:'粮仓（供应商）入驻承诺函模版'}).then(res=>{
            if (res.code == 0) {
						this.urlSrcFile = res.data
					} else {
                        this.$message({
                        message: res.msg,
                        type: 'warning'
                        });
						
					}
        })
        },
    toUrl(urlSrc,name){
            this.$router.push({path:urlSrc,query:{name:name}})
        },
      changeCheck(status){
      console.log(status);
      if(!status){
        this.from.businessEx = ""
      }else{
        this.from.businessEx = "长期"
      }
    },
      triggerFileInput(num) {
          console.log(num)
          this.chooseNum = num
          if (num == 0) {
              this.$refs.fileInput.click();

          } else if (num == 1) {
              this.$refs.fileInputs.click();

          }else if (num == 2) {
              this.$refs.fileInputss.click();

          }else if (num == 3) {
                this.$refs.fileInputssss.click();

            }else if (num == 4) {
                this.$refs.fileInputsss.click();

            }
          // 触发隐藏 input 的点击事件  

      },
      handleFileChange(e) {
        this.selectedFile = e.target.files[0];
            if (!this.selectedFile) {
                return;
            }
            const formData = new FormData();
            console.log(formData, 'formData')
            console.log(this.selectedFile, 'this.selectedFile')
            formData.append('file', this.selectedFile);
            console.log(formData.length, 'formData')
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            upload(formData).then(res => {
                loading.close();
                if (this.chooseNum == 0) {
                    this.ruleForm.businessLicense = res.data.src
                } else if (this.chooseNum == 1) {
                    this.ruleForm.idCardFront = res.data.src

                } else if (this.chooseNum == 2) {
                    this.ruleForm.idCardBack = res.data.src

                }else if (this.chooseNum == 3) {
                    this.ruleForm.factoryImg = res.data.src

                } else if (this.chooseNum == 4) {
                    this.ruleForm.letter = res.data.src
                }
            }).catch(error => {
                console.error('Error uploading image:', error);
                // 处理上传失败后的逻辑  
            });
      },
     
      submitForm(formName) {
      if(!this.agreeFlag){
                  this.$message({
        message: '请阅读并同意协议',
        type: 'warning'
      });
        return
      }
          this.$refs[formName].validate((valid) => {
              if (valid) {
                joinIn(this.ruleForm).then(res => {
                        console.log(res)
                        if (res.code == 0) {
                            this.$message({
                                message: '提交成功，等待审核!',
                                type: 'success'
                            })
                            this.$router.replace('/joinOus')
                        }

                    })
                  // alert('submit!');
                  // console.log(this.ruleForm, '数据');
              } else {
                  console.log('error submit!!');
                  // return false;
              }
          });
      },
  }
}
</script>

<style lang="scss" scoped>
.el-form-item__error{
  margin-left: 4rem!important;
}
.content {
  padding-top: 0.5rem;
}

.bgColor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fafafa;
  z-index: -1;
}

.box {
  width: 16.27rem;
  border-radius: 0.43rem;
  opacity: 1;
  border: 0.04rem solid #ffffff;
  background: #ffffff;
  box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
  margin: 0.52rem auto 0;

  .boxTit {
      width: 16.12rem;
      height: 0.7rem;
      border-radius: 0.32rem 0.32rem 0 0;
      opacity: 1;
      background: linear-gradient(90deg, #00903d 0%, #00903d00 100%);
      color: #ffffff;
      font-size: 0.32rem;
      font-weight: 700;
      font-family: "SourceHanSansCN";
      display: flex;
      align-items: center;
      padding-left: 0.26rem;
      margin-bottom: 0.2rem;
  }

  .boxLi {
      display: flex;
      align-items: center;
      margin-top: 0.3rem;
      padding-left: 0.35rem;

      .boxName {
          color: #253a70;
          font-size: 0.3rem;
          font-weight: 400;
          font-family: "SourceHanSansCN";
          width: 4rem;
          text-align: right;
          margin-right: 0.3rem;

          span {
              color: #f92800;
          }
      }
      .shangchuan{
            padding: 0.1rem 0.3rem;
            box-sizing: border-box;
            background: #00903d;
            color: #ffffff;
            border-radius: 0.5rem;
        }
      .inpt {
          width: 7.63rem;
          height: 0.64rem;
          border-radius: 0.16rem;
          opacity: 1;
          border: 0.01rem solid #ced9f2;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.22rem;
          box-sizing: border-box;
      }

      input {
          color: #253a70;
          font-size: 0.3rem;
          font-weight: 400;
          font-family: "SourceHanSansCN";
      }

      input::placeholder {
          color: #B8C2D9;
      }

      .el-icon-arrow-right {
          font-size: 0.3rem;
      }


  }
}

.btnBoxBig {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.88rem;
}

.btnBox {
  width: 6.62rem;
  height: 0.98rem;
  border-radius: 1rem;
  background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
  color: #ffffff;
  font-size: 0.32rem;
  font-weight: 500;
  font-family: "SourceHanSansCN";
  text-align: center;
}

.boxImage {
  display: flex;
  margin-top: 0.3rem;
  padding-left: 0.35rem;

  .imagePer {
      width: 3rem;
      height: 3rem;
      border-radius: 0.1rem;
      border: 0.01rem solid #dedede;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-icon-plus {
          font-size: 1rem;
          color: #dedede;
      }

      img {
          width: 100%;
          height: 100%;
          border-radius: 0.1rem;

      }
  }
 

  .boxName {
      color: #253a70;
      font-size: 0.3rem;
      font-weight: 400;
      font-family: "SourceHanSansCN";
      width: 4rem;
      text-align: right;
      margin-right: 0.3rem;

      span {
          color: #f92800;

      }
  }
}
.agreeBox{
      font-size: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.5rem;
      .agreeQuan{
          width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      border: 0.02rem solid #253a70;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.1rem;
      .el-icon-check{
          font-size: 0.3rem;
          font-weight: 400;
          font-family: "SourceHanSansCN";
          color: #ffffff;
      }
      }
      .agreeQuanA{
          background: #009A41;
      }
  }
</style>