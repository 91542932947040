<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="shopBox">
            <div class="shopTop" @click="qyGo(goodMsg.companyId)">
                <img src="../../assets/shopIcon.png" alt="" />
                <div class="shopName">
                    {{ goodMsg.company }}
                    <div class="el-icon-arrow-right"></div>
                </div>
            </div>
            <div>
                <div class="shopList" @click="spGo(goodMsg.goodsId)">
                    <div class="shopImage">
                        <img :src="goodMsg.goodsImg" alt="" />
                    </div>
                    <div class="shopCont">
                        <div class="shopContTop">
                            <div class="shopContName">
                                {{ goodMsg.goodsName || '' }}
                            </div>
                            <div class="shopContPrice">
                                ￥{{ goodMsg.goodsPrice || '' }}
                            </div>
                        </div>
                        <div class="shopContTip">
                            规格：{{ goodMsg.specName || '' }}
                        </div>
                        <div class="shopContTip">
                            数量：{{ goodMsg.goodsNum || '' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shopBox" style="margin-top: 0.2rem">
            <div class="shopTop">
                <div class="shopName">评分</div>
            </div>
            <el-rate disabled v-model="goodMsg.comment.score"></el-rate>
        </div>
        <div class="shopBox" style="margin-top: 0.2rem">
            <div class="shopTop">
                <div class="shopName">晒图</div>
            </div>
            <div class="boxContBox">
                <div class="boxImage" style="flex-wrap: nowrap">
                    <div class="boxName">照片：</div>
                    <div class="imagePerBoox">
                        <div
                            class="imagePer"
                            v-for="(item, index) in goodMsg.comment.img"
                            :key="index"
                        >
                            <img :src="item" alt="" />
                        </div>
                    </div>
                </div>
                <div class="boxImage" style="flex-wrap: nowrap">
                    <div class="boxName">视频：</div>
                    <div class="imagePerBoox">
                        <div
                            class="imagePer"
                            v-for="(item, index) in goodMsg.comment.videos"
                            :key="index"
                        >
                            <video :src="item" controls></video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shopBox" style="margin-top: 0.2rem">
            <div class="shopTop">
                <div class="shopName">评价内容</div>
            </div>
            <el-input
                type="textarea"
                :rows="3"
                disabled
                v-model="goodMsg.comment.comment"
                placeholder="请输入评价内容"
            ></el-input>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            score: 0,
            chooseNum: '',
            img: [],
            videos: [],
            comment: '',
            goodMsg: {}
        }
    },
    created() {
        let dataMsg = JSON.parse(localStorage.getItem('pingjia'))
        if (dataMsg.comment.img) {
            dataMsg.comment.img = dataMsg.comment.img.split(',')
        }
        if (dataMsg.comment.videos) {
            dataMsg.comment.videos = dataMsg.comment.videos.split(',')
        }
        this.goodMsg = dataMsg
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.el-rate {
    line-height: 0.5;
    height: auto;
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}
.shopBox {
    width: 15.12rem;
    border-radius: 0.32rem;
    background: #ffffff;
    padding: 0.28rem;
    box-sizing: border-box;
    margin: 2rem auto 0;
    .shopTop {
        display: flex;
        align-items: center;

        img {
            width: 0.33rem;
            height: 0.35rem;
        }
        .shopName {
            color: #1b1b1b;
            font-size: 0.28rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-left: 0.11rem;
        }
    }
    .boxImage {
        display: flex;
        margin-top: 0.3rem;
        // padding-left: 0.35rem;
        flex-wrap: wrap;

        .imagePerBoox {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex: 1;
            justify-content: space-between;
        }

        .imagePerBoox::after {
            content: '';
            width: 3rem;
        }

        .imagePer {
            width: 3rem;
            height: 3rem;
            border-radius: 0.1rem;
            border: 0.01rem solid #dedede;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.2rem;
            position: relative !important;

            .boxClose {
                width: 0.5rem;
                height: 0.5rem;
                position: absolute;
                top: -0.1rem;
                right: -0.1rem;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }

            .el-icon-plus {
                font-size: 1rem;
                color: #dedede;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 0.1rem;
            }

            video {
                width: 100%;
                height: 100%;
                border-radius: 0.1rem;
            }
        }

        .boxName {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            // width: 4rem;
            text-align: right;
            margin-right: 0.3rem;

            span {
                color: #f92800;
            }
        }
    }
    .shopList {
        display: flex;
        // align-items: center;
        margin-top: 0.22rem;
        .shopImage {
            width: 1.8rem;
            height: 1.8rem;
            border-radius: 0.16rem;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.16rem;
            }
        }
        .shopCont {
            margin-left: 0.26rem;

            flex: 1;
            .shopContTop {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .shopContName {
                    color: #1b1b1b;
                    font-size: 0.24rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
                .shopContPrice {
                    color: #1b1b1b;
                    font-size: 0.3rem;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                }
            }
            .shopContTip {
                color: #6b6b6bcc;
                font-size: 0.22rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                margin-top: 0.16rem;
            }
            .footerBottomLiBox {
                display: flex;
                justify-content: flex-end;
            }
            .footerBottomLi {
                min-width: 1.5738rem;
                padding: 0 0.2rem;
                box-sizing: border-box;
                height: 0.64rem;
                border-radius: 0.72rem;
                border: 0.02rem solid #383838;
                color: #555555;
                font-size: 0.28rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0.16rem;
                //  margin-bottom: constant(safe-area-inset-bottom);
                //  margin-bottom: env(safe-area-inset-bottom);
            }
            .footerBottomLiA {
                border: 0.02rem solid #12924a;
                color: #12924a;
            }
        }
    }
}
.btnBoxBig {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.88rem 0;
}

.btnBox {
    width: 15.12rem;
    height: 0.98rem;
    border-radius: 1rem;
    background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
    color: #ffffff;
    font-size: 0.32rem;
    font-weight: 500;
    font-family: 'SourceHanSansCN';
    text-align: center;
}
</style>