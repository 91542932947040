<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="chooseBig">
            <div class="chooseTab">
                <div
                    class="chooseTabLi"
                    :class="tabNum == 6 ? 'chooseTabLiA' : ''"
                    @click="shoose(6)"
                >
                    全部
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 0 ? 'chooseTabLiA' : ''"
                    @click="shoose(0)"
                >
                    待付款
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 1 ? 'chooseTabLiA' : ''"
                    @click="shoose(1)"
                >
                    待发货
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 2 ? 'chooseTabLiA' : ''"
                    @click="shoose(2)"
                >
                    待收货
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 3 ? 'chooseTabLiA' : ''"
                    @click="shoose(3)"
                >
                    已收货
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 4 ? 'chooseTabLiA' : ''"
                    @click="shoose(4)"
                >
                    待开票
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 5 ? 'chooseTabLiA' : ''"
                    @click="shoose(5)"
                >
                    已完成
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == -1 ? 'chooseTabLiA' : ''"
                    @click="shoose(-1)"
                >
                    已取消
                </div>
            </div>
        </div>
        <div class="box">
            <div class="shopLi" v-for="(item, index) in dataList" :key="index">
                <div class="shopTop" @click="qyGo(item.companyId)">
                    <div class="shopTopLeft">
                        <img
                            src="../../assets/shopIcon.png"
                            alt=""
                            class="shopLeft"
                        />
                        <div class="shopShop">{{ item.company }}</div>
                        <img
                            src="../../assets/xzright.png"
                            alt=""
                            class="shopRight"
                        />
                    </div>

                    <div class="shopTopStatus">
                        {{ item.statusName }}
                    </div>
                </div>
                <div v-for="(obj, objIndex) in item.cartList" :key="objIndex">
                    <div class="spLi" @click="spGo(obj.goodsId)">
                        <img :src="obj.goodsImg" alt="" class="shopImg" />
                        <div class="shopCont">
                            <div class="shopContTop">
                                <div class="shopName">{{ obj.goodsName }}</div>
                                <div class="shopContPrice">
                                    ￥{{ obj.goodsPrice }}
                                </div>
                            </div>
                            <div class="shopTip">规格：{{ obj.specName }}</div>
                            <div class="shopTip">数量：{{ obj.goodsNum }}</div>
                        </div>
                    </div>
                </div>
                <div class="shopBottom">
                    实付款￥
                    <div class="shopPrice">{{ item.totalPrice }}</div>
                </div>
                <div class="shopBtn">
                    <!-- @click.stop="toUrl('/merchandiseOrderXq', item.id)" -->
                    <div class="shopBtnLi" @click.stop="btnOperation(item)">
                        订单详情
                    </div>
                    <div
                        class="shopBtnLi"
                        v-if="item.status == 0"
                        @click.stop="cancelOrder(item.id)"
                    >
                        取消订单
                    </div>
                    <div
                        class="shopBtnLi shopBtnLiA"
                        v-if="item.status == 0"
                        @click.stop="payMent(item.id)"
                    >
                        去支付
                    </div>
                    <el-popconfirm
                        confirm-button-text="确定"
                        cancel-button-text="取消"
                        icon="el-icon-info"
                        icon-color="red"
                        title="是否确定收到货？"
                        @confirm="receipt(item.id)"
                        v-if="item.status == 2"
                    >
                        <!-- <el-button slot="reference">删除</el-button> -->
                        <div class="shopBtnLi shopBtnLiA" slot="reference">
                            确认收货
                        </div>
                    </el-popconfirm>

                    <div
                        class="shopBtnLi shopBtnLiA"
                        v-if="item.status == 3"
                        @click.stop="billing(item.id)"
                    >
                        申请开票
                    </div>
                    <div
                        class="shopBtnLi shopBtnLiA"
                        v-if="item.status == 5 && item.invoiceUrl"
                        @click="checkInvoice(item.invoiceUrl)"
                    >
                        查看发票
                    </div>
                </div>
            </div>
        </div>

        <div
            style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 14.29rem;
                margin: 0.5rem auto;
            "
        >
            <el-pagination
                :current-page="listQuery.page"
                :page-sizes="[10]"
                :page-size="listQuery.limit"
                :total="listQuery.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
        </div>
        <CancelOrder v-if="show" @suBmit="suBmit" @close="close"></CancelOrder>
        <!-- <ApplyForBilling :dialogFormVisible="billingFlag"></ApplyForBilling> -->
        <el-dialog :visible.sync="dialogFormVisible">
            <div class="popupTit">选择发票信息</div>
            <div class="popupMsg" @click="goChooseInvoice">
                <div class="popupMsgLeft" v-if="invoiceId">
                    <div class="popupMsgLi">
                        税号：{{ invoiceMsg.taxNumber }}
                    </div>
                    <div class="popupMsgLi">
                        账户名称：{{ invoiceMsg.accountName }}
                    </div>
                    <div class="popupMsgLi">开户银行:{{ invoiceMsg.bank }}</div>
                </div>
                <div class="popupMsgLeft" v-if="!invoiceId">
                    <div class="popupMsgLi">点击选择发票信息</div>
                </div>
                <div class="el-icon-arrow-right"></div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureKp">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible" width="30%">
            <img width="100%" :src="imageSrc" alt="预览图片" />
        </el-dialog>
    </div>
</template>

<script>
import {
    getOrderPage,
    cancelOrder,
    orderContract,
    orderCancel,
    confirmReceipt,
    applyInvoice
} from '@/api/user/index.js'
export default {
    data() {
        return {
            dialogVisible: false,
            imageSrc: '',
            dialogFormVisible: false,
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            tabNum: 6,
            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            dataList: [],
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            tabIndex: 1,
            btnFlag: false,
            show: false,
            order_id: '',
            invoiceMsg: {},
            invoiceId: '',

            incoiceList: []
        }
    },
    created() {
        if (localStorage.getItem('invoiceChoose')) {
            this.invoiceMsg = JSON.parse(localStorage.getItem('invoiceChoose'))
            this.invoiceId = this.invoiceMsg.id
            this.order_id = this.invoiceMsg.orderId
            this.tabNum = this.invoiceMsg.tabNum
            localStorage.removeItem('invoiceChoose')
            this.dialogFormVisible = true
        }
        this.listQuery.page = 1
        this.dataList = []
        this.getInit()
    },
    methods: {
        // 跳转企业详情
        qyGo(id) {
            if (id) {
                this.$router.push({
                    path: '/companyXq',
                    query: { id: id }
                })
            }
        },
        // 跳转商品详情
        spGo(id) {
            this.$router.push({
                path: '/shopXq',
                query: { id: id }
            })
        },
        // 查看发票
        checkInvoice(url) {
            this.imageSrc = url
            this.dialogVisible = true
        },

        // 选择发票信息
        goChooseInvoice() {
            this.$router.push({
                path: '/invoiceAdmin',
                query: { type: 2, orderId: this.order_id, tabNum: this.tabNum }
            })
        },
        // 申请开票
        billing(id) {
            this.order_id = id
            this.dialogFormVisible = true
        },
        sureKp() {
            if (!this.invoiceId) {
                this.$message({
                    message: '请选择发票信息',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            applyInvoice({
                id: this.order_id,
                invoiceId: this.invoiceId
            }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '申请开票提交成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.dialogFormVisible = false

                    this.listQuery.page = 1
                    this.dataList = []
                    this.getInit()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
            // dialogFormVisible = false
        },
        // 确认收货
        receipt(id) {
            confirmReceipt({ id: id }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '确定收货成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.listQuery.page = 1
                    this.dataList = []
                    this.getInit()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        // 跳转订单详情
        btnOperation(item) {
            if (item.status == 0) {
                this.$router.push({
                    path: '/onePayOrder',
                    query: { orderId: item.id }
                })
            } else {
                this.$router.push({
                    path: '/merchandiseOrderXq',
                    query: { orderId: item.id }
                })
            }
        },
        // 去支付
        payMent(id) {
            this.$router.push({
                path: '/onePayOrder',
                query: { orderId: id }
            })
        },
        // 取消订单
        cancelOrder(id) {
            this.order_id = id
            this.show = true
        },
        // 取消订单
        suBmit(num, flag) {
            orderCancel({
                id: this.order_id,
                type: num,
                flag: flag
            }).then((res) => {
                this.show = false
                if (res.code == 0) {
                    this.$message({
                        message: '取消成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.listQuery.page = 1
                    this.dataList = []
                    this.getInit()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        close(e) {
            this.show = e
        },
        chooseTop(num) {
            if (this.btnFlag) {
                return
            }
            this.btnFlag = true
            this.tabIndex = num
            this.page = 1
            this.listQuery.page = 1
            this.dataList = []
            this.getInit()
        },
        htDown(id) {
            orderContract({ orderCode: id }).then((res) => {
                console.log(res)
                if (res.data.contract) {
                    window.location.href = res.data.contract
                } else {
                    this.$message({
                        message: '暂无合同',
                        type: 'warning'
                    })
                }
            })
        },
        toUrl(urlSrc) {
            this.$router.push({ path: urlSrc })
        },
        cancelOrderBtn(id) {
            cancelOrder({ orderId: id }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '取消订单成功！',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.listQuery.page = 1
                    this.dataList = []
                    this.getInit()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        pageSizeChangeHandle(val) {
            this.listQuery.page = 1
            this.listQuery.limit = Number(val)
            this.getInit()
        },
        pageCurrentChangeHandle(val) {
            this.listQuery.page = Number(val)
            this.getInit()
        },
        getInit() {
            getOrderPage({
                identity: 1,
                status: this.tabNum,
                current: this.listQuery.page,
                size: this.listQuery.limit
            }).then((res) => {
                this.btnFlag = false
                if (res.code == 0) {
                    this.listQuery.total = Number(res.data.total)
                    this.dataList = res.data.records
                }
            })
        },
        shoose(num) {
            this.tabNum = num
            this.listQuery.page = 1
            this.dataList = []
            this.getInit()
        }
    }
}
</script>

<style lang="scss" scoped>
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.header {
    width: 100%;
    height: 5rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.chooseBig {
    width: 100%;
    padding: 0.2rem;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    // position: fixed;
    // top:0.8rem;
    // left: 0;
    margin-top: 1rem;
}

.chooseTab {
    width: 14.29rem;

    height: 1.55rem;
    border-radius: 0.26rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto 0;
    box-sizing: border-box;

    .chooseTabLi {
        color: #3c3c3c;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
    }

    .chooseTabLiA {
        color: #1b1b1b;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        position: relative;
    }

    .chooseTabLiA::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.2rem;
        // right: 10%;
        background: #239f59;
        width: 100%;
        height: 0.09rem;
        border-radius: 0.08rem;
    }
}

.box {
    width: 14.29rem;
    margin: 0 auto;
    .shopLi {
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
        padding: 0.3rem 0.5rem;
        box-sizing: border-box;
        margin-top: 0.32rem;
        .shopTop {
            display: flex;
            align-items: center;

            justify-content: space-between;
            .shopTopLeft {
                display: flex;
                align-items: center;
            }
            .shopTopStatus {
                color: #1b1b1b80;
                font-size: 0.28rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
            }
            .shopCheck {
                width: 0.37rem;
                height: 0.37rem;
                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
            .shopShop {
                color: #343231;
                font-size: 0.32rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                margin-left: 0.16rem;
            }
            .shopLeft {
                width: 0.327rem;
                height: 0.327rem;
            }
            .shopRight {
                width: 0.22rem;
                height: 0.22rem;
            }
        }

        .shopBottom {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #000000;
            font-weight: 400;
            text-align: left;
            font-size: 0.24rem;
            font-family: 'SourceHanSansCN';
            margin-top: 0.2rem;
            .shopPrice {
                color: #000000;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
                text-align: left;
                font-size: 0.3rem;
            }
            .shopNum {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        .spLi {
            display: flex;
            // align-items: center;
            margin-top: 0.35rem;
            .shopCheck {
                width: 0.37rem;
                height: 0.37rem;
                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
            .shopImg {
                width: 1.8rem;
                height: 1.8rem;
                border-radius: 0.16rem;
                margin-left: 0.12rem;
            }
            .shopCont {
                margin-left: 0.26rem;
                flex: 1;
                .shopContTop {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .shopName {
                        color: #343231;
                        font-size: 0.3rem;
                        font-weight: 700;
                        font-family: 'SourceHanSansCN';
                        margin-bottom: 0.15rem;
                    }
                    .shopContPrice {
                        color: #000000;
                        font-weight: 700;
                        font-size: 0.3rem;
                        font-family: 'SourceHanSansCN';
                    }
                }

                .shopTip {
                    color: #6b6b6bcc;
                    font-size: 0.22rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    margin-bottom: 0.04rem;
                }
            }
        }
        .shopBtn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 0.3rem;
            .shopBtnLi {
                width: 1.559rem;
                height: 0.64rem;
                border-radius: 0.72rem;
                border: 0.02rem solid #383838;
                color: #555555;
                font-size: 0.28rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0.16rem;
            }
            .shopBtnLiA {
                color: #12924a;
                border: 0.02rem solid #12924a;
            }
        }
    }
}
.popupTit {
    font-size: 0.32rem;
    text-align: center;
    font-weight: 700;
    font-family: 'SourceHanSansCN';
    margin-bottom: 0.2rem;
}
.popupMsg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0;
    .popupMsgLeft {
        .popupMsgLi {
            color: #1b1b1b;
            font-size: 0.28rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            margin-top: 0.1rem;
        }
    }

    .el-icon-arrow-right {
        font-size: 0.28rem;
    }
}
</style>