<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="guanliBox">
            <div class="guanliBtn" @click="chooseBtn(1)" v-if="btnIndex == 0">
                管理
            </div>
            <div class="guanliBtn" @click="chooseBtn(0)" v-if="btnIndex == 1">
                退出管理
            </div>
            <el-empty
                description="购物车暂无商品"
                v-if="listData.length <= 0"
            ></el-empty>
            <div class="shopLi" v-for="(item, index) in listData" :key="index">
                <div class="shopTop">
                    <div class="shopCheck" @click.stop="checkBox(item)">
                        <img
                            src="../assets/noactive.png"
                            alt=""
                            v-if="!item.flag"
                        />
                        <img
                            src="../assets/activce.png"
                            alt=""
                            v-if="item.flag"
                        />
                    </div>
                    <div class="shopShop">{{ item.company }}</div>
                    <img src="../assets/xzright.png" alt="" class="shopRight" />
                </div>
                <div
                    class="spLi"
                    v-for="(obj, objIndex) in item.cartList"
                    :key="objIndex"
                >
                    <div class="shopCheck" @click="checkLi(obj, item)">
                        <img
                            src="../assets/noactive.png"
                            alt=""
                            v-if="!obj.flag"
                        />
                        <img
                            src="../assets/activce.png"
                            alt=""
                            v-if="obj.flag"
                        />
                    </div>
                    <div style="display: flex; flex: 1">
                        <img :src="obj.goodsImg" alt="" class="shopImg" />
                        <div class="shopCont">
                            <div class="shopName">{{ obj.goodsName }}</div>
                            <div class="shopTip">规格：{{ obj.specName }}</div>
                            <!-- <div class="shopTip">国际等级：三级</div> -->
                            <div class="shopBottom">
                                <div class="shopPrice">
                                    ￥{{ obj.goodsPrice }}
                                </div>
                                <div class="shopNum">
                                    <el-input-number
                                        v-model="obj.goodsNum"
                                        @change="handleChange(obj)"
                                        :min="1"
                                        label="描述文字"
                                    ></el-input-number>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div class="footerCont">
                    <div class="shopCheck" @click="checkAll">
                        <img
                            src="../assets/noactive.png"
                            alt=""
                            v-if="!allFlag"
                        />
                        <img
                            src="../assets/activce.png"
                            alt=""
                            v-if="allFlag"
                        />
                    </div>
                    <span
                        style="
                            font-size: 0.24rem;
                            font-weight: 400;
                            font-family: 'SourceHanSansCN';
                            margin: 0 0.1rem;
                        "
                    >
                        全选
                    </span>
                    <span v-if="btnIndex == 0">
                        合计
                        <span
                            style="
                                font-size: 0.24rem;
                                font-weight: 400;
                                font-family: 'SourceHanSansCN';
                                color: #0e8f46;
                            "
                            >￥</span
                        >
                        <span
                            style="
                                font-size: 0.28rem;
                                font-weight: 400;
                                font-family: 'SourceHanSansCN';
                                color: #0e8f46;
                                font-weight: 500;
                            "
                            >{{ totalPrice }}</span
                        >
                    </span>

                    <div
                        class="btnJs"
                        @click="closeAccount"
                        v-if="btnIndex == 0"
                    >
                        结算({{ numAll }})
                    </div>
                    <el-popconfirm
                        confirm-button-text="确定"
                        cancel-button-text="取消"
                        icon="el-icon-info"
                        icon-color="red"
                        title="当前商品确定删除吗？"
                        @confirm="deleteCart"
                        v-if="btnIndex == 1"
                    >
                        <!-- <el-button slot="reference">删除</el-button> -->
                        <div class="btnJs" slot="reference">删除</div>
                    </el-popconfirm>
                    <!-- <div class="btnJs" v-if="btnIndex == 1" @click="deleteCart">
                        删除
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getShoppingCartList,
    statisticalPrices,
    addOrDel,
    removeCartGoods
} from '@/api/user/index.js'
export default {
    data() {
        return {
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            cartNum: 0, //购物车的总数量
            listData: [], //购物车数据
            totalPrice: 0, //选中总价格
            numAll: 0, //选中的数量
            btnIndex: 0, //0是结算1是删除
            llFlag: false, //是否全选
            cartIds: ''
        }
    },
    created() {
        this.totalPrice = 0
        this.numAll = 0
        this.allFlag = false
        this.init()
    },

    methods: {
        deleteCart() {
            if (!this.cartIds) {
                this.$message({
                    message: '请选择需要删除的商品',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            removeCartGoods({ cartIds: this.cartIds }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '删除成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.totalPrice = 0
                    this.numAll = 0
                    this.allFlag = false
                    this.init()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        // 结算
        closeAccount() {
            if (!this.cartIds) {
                this.$message({
                    message: '请选择需要购买的商品',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            this.$router.push({
                path: '/sureOrder',
                query: { ids: this.cartIds, type: 1 }
            })
        },
        chooseBtn(num) {
            console.log(num)
            this.btnIndex = num
        },
        handleChange(e) {
            console.log(e)
            addOrDel({ num: e.goodsNum, specId: e.goodsSpecId }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '修改成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    for (var a = 0; a < this.listData.length; a++) {
                        for (
                            var b = 0;
                            b < this.listData[a].cartList.length;
                            b++
                        ) {
                            if (
                                this.listData[a].cartList[b].goodsSpecId ==
                                this.specId
                            ) {
                                this.listData[a].cartList[b].goodsNum =
                                    this.amendNum
                            }
                        }
                    }
                    this.idsSet()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        // 计算价格
        calculatePrice() {
            statisticalPrices({ cartIds: this.cartIds }).then((res) => {
                if (res.code == 0) {
                    this.totalPrice = res.data
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        // 公司选择
        checkBox(item) {
            item.flag = !item.flag
            if (item.flag) {
                for (var a = 0; a < item.cartList.length; a++) {
                    item.cartList[a].flag = true
                }
            } else {
                for (var b = 0; b < item.cartList.length; b++) {
                    item.cartList[b].flag = false
                }
            }
            this.isOrNoAll()
        },
        // 选择的时候把id存起来备用
        idsSet() {
            let cartIds = []
            for (var a = 0; a < this.listData.length; a++) {
                for (var b = 0; b < this.listData[a].cartList.length; b++) {
                    if (this.listData[a].cartList[b].flag) {
                        cartIds.push(this.listData[a].cartList[b].id)
                    }
                }
            }
            if (cartIds.length > 0) {
                this.numAll = cartIds.length
                this.cartIds = cartIds.join(',')
                this.calculatePrice()
            } else {
                this.totalPrice = 0
                this.numAll = 0
            }
        },
        // 单选
        checkLi(obj, item) {
            obj.flag = !obj.flag
            let array = []
            for (var a = 0; a < item.cartList.length; a++) {
                if (item.cartList[a].flag) {
                    array.push(item.cartList[a].id)
                }
            }
            if (item.cartList.length == array.length) {
                item.flag = true
            } else {
                item.flag = false
            }
            this.isOrNoAll()
        },
        // 全选
        checkAll() {
            this.allFlag = !this.allFlag
            if (this.allFlag) {
                for (var a = 0; a < this.listData.length; a++) {
                    this.listData[a].flag = true
                    for (var b = 0; b < this.listData[a].cartList.length; b++) {
                        this.listData[a].cartList[b].flag = true
                    }
                }
            } else {
                for (var c = 0; c < this.listData.length; c++) {
                    this.listData[c].flag = false
                    for (var d = 0; d < this.listData[c].cartList.length; d++) {
                        this.listData[c].cartList[d].flag = false
                    }
                }
            }
            this.idsSet()
        },
        // 是否全选了判断
        isOrNoAll() {
            let array = []
            for (var a = 0; a < this.listData.length; a++) {
                if (this.listData[a].flag) {
                    array.push(this.listData[a])
                }
            }
            if (array.length == this.listData.length) {
                this.allFlag = true
            } else {
                this.allFlag = false
            }
            this.idsSet()
        },
        init() {
            getShoppingCartList().then((res) => {
                let araay = []
                for (var a = 0; a < res.data.length; a++) {
                    res.data[a].flag = false
                    for (var b = 0; b < res.data[a].cartList.length; b++) {
                        res.data[a].cartList[b].flag = false
                        araay.push(res.data[a].cartList[b])
                    }
                }
                this.cartNum = araay.length
                this.listData = res.data
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding: 0.8rem 0;
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('@/assets/fwgtb.png') no-repeat center;
    background-size: 100% 100%;
    z-index: -1;
}
.guanliBox {
    width: 15.07rem;
    margin: 0 auto;
    .guanliBtn {
        text-align: right;
        color: #3c3c3c;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
    }
    .shopLi {
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
        padding: 0.3rem 0.5rem;
        box-sizing: border-box;
        margin-top: 0.32rem;
        .shopTop {
            display: flex;
            align-items: center;
            .shopCheck {
                width: 0.37rem;
                height: 0.37rem;
                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
            .shopShop {
                color: #343231;
                font-size: 0.32rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                margin-left: 0.16rem;
            }
            .shopRight {
                width: 0.22rem;
                height: 0.22rem;
            }
        }
        .spLi {
            display: flex;
            align-items: center;
            margin-top: 0.35rem;
            .shopCheck {
                width: 0.37rem;
                height: 0.37rem;
                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
            .shopImg {
                width: 1.8rem;
                height: 1.8rem;
                border-radius: 0.16rem;
                margin-left: 0.12rem;
            }
            .shopCont {
                margin-left: 0.26rem;
                flex: 1;
                .shopName {
                    color: #343231;
                    font-size: 0.3rem;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                    margin-bottom: 0.15rem;
                }
                .shopTip {
                    color: #6b6b6bcc;
                    font-size: 0.22rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    margin-bottom: 0.04rem;
                }
                .shopBottom {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .shopPrice {
                        color: #0e8f46;
                        font-weight: 700;
                        font-family: 'SourceHanSansCN';
                        text-align: left;
                        font-size: 0.3rem;
                    }
                    .shopNum {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}
.footer {
    margin-top: 1rem;

    .footerCont {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 0.24rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        .shopCheck {
            width: 0.37rem;
            height: 0.37rem;
            img {
                width: 100%;
                height: 100%;
                display: block;
            }
        }
        span {
        }
        .btnJs {
            width: 1.8rem;
            height: 0.75rem;
            border-radius: 0.72rem;
            opacity: 1;
            border: 0.02rem solid #0e8f46;
            background: #0e8f46;
            color: #ffffff;
            font-size: 0.32rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.54rem;
        }
    }
}
</style>