<template>
    <div class="content" ref="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="box">
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 0.3rem;
                "
            >
                <div class="boxTit">基础信息</div>
                <div class="boxStatus" v-if="shopId">
                    {{
                        (dataMsgStatus.checkStatus == 0 && '审核中') ||
                        (dataMsgStatus.checkStatus == 1 && '审核通过') ||
                        (dataMsgStatus.checkStatus == 2 && '驳回')
                    }}
                </div>
            </div>
            <div
                class="tip"
                v-if="
                    dataMsgStatus.checkStatus == 2 && dataMsgStatus.checkReason
                "
            >
                驳回原因：{{ dataMsgStatus.checkReason }}
            </div>
            <div class="boxList">
                <div class="boxName"><span>*</span>类型</div>
                <el-select
                    v-model="typeName"
                    placeholder="请选择类型"
                    @change="chooseType"
                >
                    <el-option
                        v-for="item in typeArray"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="boxList">
                <div class="boxName"><span>*</span>商品名称</div>
                <el-input
                    v-model="from.title"
                    placeholder="请输入商品名称"
                ></el-input>
            </div>

            <div class="boxList">
                <div class="boxName"><span>*</span>商品介绍</div>
                <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入商品介绍"
                    v-model="from.blurb"
                >
                </el-input>
            </div>

            <div class="boxImage" style="flex-wrap: nowrap">
                <div class="boxName"><span>*</span>轮播图</div>
                <div class="imagePerBoox">
                    <div
                        class="imagePer"
                        v-for="(item, index) in pic"
                        :key="index"
                    >
                        <img :src="item" alt="" />
                        <div class="boxClose" @click.stop="deleteImg(index, 1)">
                            <img src="@/assets/delete.png" alt="" />
                        </div>
                    </div>
                    <div
                        class="imagePer"
                        @click="triggerFileInput('fileInput1')"
                    >
                        <input
                            type="file"
                            @change="handleFileChange"
                            ref="fileInput1"
                            accept="image/*"
                            style="display: none"
                        />
                        <div class="el-icon-plus"></div>
                    </div>
                </div>
            </div>
            <div class="boxImage" style="flex-wrap: nowrap">
                <div class="boxName"><span>*</span>详情图</div>
                <div class="imagePerBoox">
                    <div
                        class="imagePer"
                        v-for="(item, index) in details"
                        :key="index"
                    >
                        <img :src="item" alt="" />
                        <div class="boxClose" @click.stop="deleteImg(index, 2)">
                            <img src="@/assets/delete.png" alt="" />
                        </div>
                    </div>
                    <div
                        class="imagePer"
                        @click="triggerFileInput('fileInput2')"
                    >
                        <input
                            type="file"
                            @change="handleFileChange"
                            ref="fileInput2"
                            accept="image/*"
                            style="display: none"
                        />
                        <div class="el-icon-plus"></div>
                    </div>
                </div>
            </div>
            <div class="boxList">
                <div class="boxName"><span>*</span>产地</div>
                <el-input
                    v-model="from.source"
                    placeholder="请输入产地"
                ></el-input>
            </div>
            <div class="boxList">
                <div class="boxName"><span>*</span>是否需要运费</div>
                <el-select
                    v-model="freightName"
                    placeholder="请选择是否需要运费"
                    @change="freightChoose"
                >
                    <el-option
                        v-for="item in freightList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="boxList" v-if="from.freightNum == 1">
                <div class="boxName"><span>*</span>运费模版</div>
                <el-select
                    v-model="freightOptionsName"
                    placeholder="请选择运费模版"
                    @change="yfmb"
                >
                    <el-option
                        v-for="item in freightOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="boxList">
                <div class="boxName"><span>*</span>状态</div>
                <el-select
                    v-model="statusName"
                    placeholder="请选择状态"
                    @change="statusClick"
                >
                    <el-option
                        v-for="item in statusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="box" v-for="(item, index) in from.specList" :key="index">
            <div class="boxTit">
                <div>规格{{ index + 1 }}</div>
                <div class="boxR" @click="specificationAdd" v-if="index == 0">
                    <img src="../assets/addGg.png" alt="" />
                    <div class="boxBtn">添加</div>
                </div>
                <div
                    class="boxR"
                    @click="specificationDel(index)"
                    v-if="index > 0"
                >
                    <img src="../assets/shanchu.png" alt="" />
                    <div class="boxBtn">删除</div>
                </div>
            </div>
            <div class="boxList">
                <div class="boxName"><span>*</span>商品规格</div>
                <el-input
                    v-model="item.name"
                    placeholder="请输入商品名称"
                ></el-input>
            </div>
            <div class="boxList">
                <div class="boxName"><span>*</span>价格</div>
                <el-input
                    v-model="item.price"
                    placeholder="请输入价格"
                ></el-input>
            </div>
            <div class="boxImage">
                <div class="boxName"><span>*</span>图片</div>
                <input
                    type="file"
                    @change="handleFileUpload(index, $event)"
                    accept="image/*"
                />
                <div class="imagePer" v-if="item.img">
                    <img :src="item.img" alt="" v-if="item.img" />
                    <div class="el-icon-plus" v-if="!item.img"></div>
                    <div
                        class="boxClose"
                        @click.stop="item.img = ''"
                        v-if="item.img"
                    >
                        <img src="@/assets/delete.png" alt="" />
                    </div>
                </div>
            </div>
            <div class="boxList">
                <div class="boxName"><span>*</span>库存</div>
                <el-input
                    v-model="item.stock"
                    placeholder="请输入库存"
                ></el-input>
            </div>
        </div>
        <div class="btnBoxBig">
            <button class="btnBox" @click="submitForm">确定提交</button>
        </div>
    </div>
</template>

<script>
import {
    categoryList,
    upload,
    getConfig,
    addGoods,
    getGoods,
    updateGoods
} from '@/api/user/index.js'
export default {
    data() {
        return {
            options: [],
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            pic: [],

            details: [],
            from: {
                categoryId: '', //类型id
                categoryName: '', //类型名称
                title: '', //商品名称
                blurb: '', //商品介绍
                pic: '', //轮播图
                details: '', //详情图
                source: '', //产地
                isInsurance: 0, //物损险1是0否
                freightNum: 1, //运费1是 0否 默认0
                postageId: '', //运费模版id
                status: 1, //状态 1上架 0下架 默认1
                specList: [
                    {
                        name: '',
                        price: '',
                        img: '',
                        stock: ''
                    }
                ] //规格
            },
            typeArray: [], //类型数据
            typeName: '', //类型名称
            selectedFile: null,
            chooseNum: '',
            freightList: [
                {
                    value: '1',
                    label: '是'
                },
                {
                    value: '0',
                    label: '否'
                }
            ],
            freightName: '是',
            freightOptions: [],
            freightOptionsName: '',
            statusOptions: [
                {
                    value: '1',
                    label: '上架'
                },
                {
                    value: '0',
                    label: '下架'
                }
            ],
            statusName: '上架',
            saveFlag: false,
            shopId: '',
            dataMsgStatus: {}
        }
    },
    created() {
        if (this.$route.query.id) {
            this.shopId = this.$route.query.id
            this.getData()
        }
        this.init()
        this.initYf()
    },
    methods: {
        getData() {
            getGoods({ id: this.shopId }).then((res) => {
                this.from.categoryId = res.data.categoryId
                this.from.categoryName = res.data.categoryName
                this.typeName = res.data.categoryName
                this.from.title = res.data.title
                this.from.blurb = res.data.blurb
                this.from.pic = res.data.pic
                this.pic = res.data.pic.split(',')
                this.from.details = res.data.details
                this.details = res.data.details.split(',')
                this.from.source = res.data.source
                this.from.isInsurance = res.data.isInsurance
                this.from.freightNum = res.data.freightNum
                this.freightNum = res.data.freightNum
                if (res.data.freightNum == 1) {
                    this.from.postageId = res.data.postageId
                    this.freightOptionsName = res.data.postageName
                }
                this.status = res.data.status
                this.from.specList = res.data.specList

                this.dataMsgStatus = res.data
            })
        },
        // 删除规格
        specificationDel(index) {
            this.from.specList.splice(index, 1)
        },
        // 添加规格
        specificationAdd() {
            let obj = {
                name: '',
                price: '',
                img: '',
                stock: ''
            }
            this.from.specList.push(obj)
            setTimeout(() => {
                const content = this.$refs.content
                this.$nextTick(() => {
                    content.scrollTop = content.scrollHeight
                })
            }, 300)
        },
        submitForm() {
            console.log(this.from)
            if (!this.from.categoryId) {
                this.$message({
                    message: '请选择类型',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (!this.from.title) {
                this.$message({
                    message: '请输入商品名称',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (!this.from.blurb) {
                this.$message({
                    message: '请输入商品介绍',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (!this.from.pic) {
                this.$message({
                    message: '请上传轮播图',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (!this.from.details) {
                this.$message({
                    message: '请上传详情图',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (!this.from.source) {
                this.$message({
                    message: '请输入产地',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (this.from.freightNum == 1) {
                if (!this.from.postageId) {
                    this.$message({
                        message: '请选择运费模版',
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                    return
                }
            }

            for (var a = 0; a < this.from.specList.length; a++) {
                if (!this.from.specList[a].name) {
                    this.$message({
                        message: '请输入规格' + a + '名称',
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                    return
                }
                if (!this.from.specList[a].price) {
                    this.$message({
                        message: '请输入规格' + a + '价格',
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                    return
                }
                if (!this.from.specList[a].img) {
                    this.$message({
                        message: '请上传规格' + a + '图片',
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                    return
                }
                if (!this.from.specList[a].stock) {
                    this.$message({
                        message: '请输入规格' + a + '库存',
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                    return
                }
            }

            if (this.saveFlag) {
                return
            }
            this.saveFlag = true
            if (this.shopId) {
                this.from.id = this.shopId
                updateGoods(this.from).then((res) => {
                    this.saveFlag = false
                    if (res.code == 0) {
                        this.$message({
                            message: '提交成功',
                            type: 'success',
                            customClass: 'messageBoxTip'
                        })

                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 1000)
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'warning',
                            customClass: 'messageBoxTip'
                        })
                    }
                })
            } else {
                addGoods(this.from).then((res) => {
                    this.saveFlag = false
                    if (res.code == 0) {
                        this.$message({
                            message: '提交成功',
                            type: 'success',
                            customClass: 'messageBoxTip'
                        })

                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 1000)
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'warning',
                            customClass: 'messageBoxTip'
                        })
                    }
                })
            }
        },
        statusClick(e) {
            console.log(e)
        },
        yfmb(e) {
            this.from.postageId = e
        },
        initYf() {
            getConfig().then((res) => {
                console.log(res)
                let array = []
                res.data.forEach((item) => {
                    let obj = {}
                    obj.value = item.id
                    obj.label = item.name
                    array.push(obj)
                })
                this.freightOptions = array
            })
        },
        deleteImg(index, num) {
            if (num == 1) {
                this.pic.splice(index, 1)
                this.from.pic = this.pic.join(',')
            } else if (num == 2) {
                this.details.splice(index, 1)
                this.from.details = this.details.join(',')
            }
        },
        triggerFileInputs(itemId) {
            this.chooseNum = `fileInput_${itemId}`
            console.log(this.chooseNum)
            console.log(this.$refs[this.chooseNum])
            this.$refs[this.chooseNum].click()
        },
        handleFileUpload(index, event) {
            console.log(event)
            this.selectedFile = event.target.files[0]
            if (!this.selectedFile) {
                return
            }
            const formData = new FormData()
            formData.append('file', this.selectedFile)
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            upload(formData)
                .then((res) => {
                    loading.close()

                    this.$set(this.from.specList, index, {
                        ...this.from.specList[index],
                        img: res.data.src // Base64 编码的图片数据
                    })
                })
                .catch((error) => {
                    console.error('Error uploading image:', error)
                    // 处理上传失败后的逻辑
                })
            // const reader = new FileReader()
            // reader.onload = (e) => {
            //     this.$set(this.items, index, {
            //         ...this.items[index],
            //         img: e.target.result // Base64 编码的图片数据
            //     })
            // }
        },
        triggerFileInput(refName) {
            console.log(refName)
            console.log(this.$refs[refName])
            this.chooseNum = refName
            this.$refs[refName].click()
        },
        handleFileChange(event) {
            console.log(event)
            this.selectedFile = event.target.files[0]
            if (!this.selectedFile) {
                return
            }
            const formData = new FormData()
            formData.append('file', this.selectedFile)
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            upload(formData)
                .then((res) => {
                    loading.close()
                    console.log(this.chooseNum)
                    if (this.chooseNum == 'fileInput1') {
                        this.pic.push(res.data.src)
                        this.from.pic = this.pic.join(',')
                    } else if (this.chooseNum == 'fileInput2') {
                        this.details.push(res.data.src)
                        this.from.details = this.details.join(',')
                    }
                })
                .catch((error) => {
                    console.error('Error uploading image:', error)
                    // 处理上传失败后的逻辑
                })
        },
        chooseType(e) {
            console.log(this.typeName)
            this.typeArray.forEach((res) => {
                if (e == res.value) {
                    this.from.categoryName = res.label
                }
            })
            this.from.categoryId = e
        },
        freightChoose(e) {
            if (e == 0) {
                this.from.postageId = ''
            }
            this.from.freightNum = e
        },
        init() {
            categoryList().then((res) => {
                let array = []
                res.data.forEach((item) => {
                    let obj = {}
                    obj.value = item.id
                    obj.label = item.title
                    array.push(obj)
                })
                this.typeArray = array
                console.log(this.options, 99999999)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.el-select {
    // width: 100%;
    flex: 1;
}

.el-input {
    flex: 1;
}
.el-textarea {
    flex: 1;
}
.content {
    padding: 0.5rem 0;
    overflow-y: auto; /* 允许垂直滚动 */
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('@/assets/fwgtb.png') no-repeat center;
    background-size: 100% 100%;
    z-index: -1;
}
.box {
    width: 15.14rem;
    border-radius: 0.34rem;
    border: 0.04rem solid #ffffff;
    background: #ffffff;
    box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;

    margin: 0 auto 0.3rem;

    .boxStatus {
        font-size: 0.26rem;
        font-family: 'SourceHanSansCN';
    }

    .tip {
        padding: 0.2rem 0.3rem 0;
        box-sizing: border-box;
        font-size: 0.24rem;
        color: red;
    }
    .boxTit {
        background: linear-gradient(90deg, #00903d 0%, #00903d00 100%);
        color: #ffffff;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        border-radius: 0.34rem 0 0 0;
        padding: 0.2rem 0.3rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        .boxR {
            display: flex;
            align-items: center;
            img {
                width: 0.5rem;
                height: 0.5rem;
            }
        }
        .boxBtn {
            color: #253a70;
        }
    }
    .boxList {
        display: flex;
        align-items: center;
        padding: 0.3rem;

        box-sizing: border-box;
        .boxName {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            width: 2.5rem;
            span {
                color: #f92800;
            }
        }
    }
}

.boxImage {
    display: flex;
    margin-top: 0.3rem;
    flex-wrap: wrap;

    .imagePerBoox {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: 0.3rem;
        // width: 100%;

        flex: 1;
    }

    .imagePerBoox::after {
        content: '';
        width: 3rem;
    }

    .imagePer {
        width: 3rem;
        height: 3rem;
        border-radius: 0.1rem;
        border: 0.01rem solid #dedede;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.2rem;
        position: relative !important;
        margin-right: 0.5rem;
        .boxClose {
            width: 0.5rem;
            height: 0.5rem;
            position: absolute;
            top: -0.1rem;
            right: -0.1rem;
            img {
                width: 100%;
                height: 100%;

                display: block;
            }
        }

        .el-icon-plus {
            font-size: 1rem;
            color: #dedede;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 0.1rem;
        }
    }

    .boxName {
        color: #253a70;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        width: 2.5rem;
        margin-right: 0.3rem;
        padding-left: 0.3rem;
        box-sizing: border-box;
        span {
            color: #f92800;
        }
    }
}
.btnBoxBig {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.88rem;
}

.btnBox {
    width: 6.62rem;
    height: 0.98rem;
    border-radius: 1rem;
    background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
    color: #ffffff;
    font-size: 0.32rem;
    font-weight: 500;
    font-family: 'SourceHanSansCN';
    text-align: center;
}
</style>