<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="addressBox">
            <div class="addressTop">{{ title }}发票信息</div>
            <div class="addressInptLi">
                <div class="addressTit">税号</div>
                <div class="addressInpt">
                    <input
                        type="text"
                        v-model="from.taxNumber"
                        placeholder="请输入税号"
                    />
                </div>
            </div>
            <div class="addressInptLi">
                <div class="addressTit">账户名称</div>
                <div class="addressInpt">
                    <input
                        type="text"
                        v-model="from.accountName"
                        placeholder="请输入账户名称"
                    />
                </div>
            </div>
            <div class="addressInptLi">
                <div class="addressTit">账户号码</div>
                <div class="addressInpt">
                    <input
                        type="text"
                        v-model="from.accountNumber"
                        placeholder="请输入账户号码"
                    />
                </div>
            </div>
            <div class="addressInptLi">
                <div class="addressTit">开户银行</div>
                <div class="addressInpt">
                    <input
                        type="text"
                        v-model="from.bank"
                        placeholder="请输入开户银行"
                    />
                </div>
            </div>
            <div class="addressInptLi">
                <div class="addressTit">地址</div>
                <div class="addressInpt">
                    <input
                        type="text"
                        v-model="from.address"
                        placeholder="请输入地址"
                    />
                </div>
            </div>

            <div class="addressInptLi">
                <div class="addressTit">电话</div>
                <div class="addressInpt">
                    <input
                        type="text"
                        v-model="from.phone"
                        placeholder="请输入电话"
                    />
                </div>
            </div>

            <div class="addressInptLi">
                <div class="addressTit">是否默认</div>
                <div class="addressInpt">
                    <el-switch
                        v-model="value"
                        active-color="#0e8f46"
                        inactive-color="#999999"
                        width="60"
                    >
                    </el-switch>
                </div>
            </div>

            <div class="addShdz" @click="addSure">确认{{ title }}</div>
        </div>
    </div>
</template>
  
  <script>
import {
    // getRegionTree,
    addInvoiceInfo,
    updateInvoiceInfo,
    getInvoiceInfoDetail
} from '@/api/user/index.js'

export default {
    data() {
        return {
            title: '新增',
            area: [],
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',

            value: false,
            from: {
                taxNumber: '', //税号
                accountName: '', //账户名称
                accountNumber: '', //账户号码
                bank: '', //开户银行
                phone: '', //电话
                address: '', //地址
                isDefault: 0 //是否默认
            },
            options: [],
            type: 1,
            btnFlag: false,
            id: ''
        }
    },

    created() {
        // this.init()
        this.type = this.$route.query.type
        if (this.type == 1) {
            this.title = '新增'
        } else {
            this.title = '修改'
            this.id = this.$route.query.id
            this.detailGet(this.id)
        }
    },
    methods: {
        detailGet(id) {
            console.log(id, '123123123')
            getInvoiceInfoDetail({ id: id }).then((res) => {
                console.log(res)
                if (res.code == 0) {
                    this.from.taxNumber = res.data.taxNumber
                    this.from.accountName = res.data.accountName
                    this.from.accountNumber = res.data.accountNumber
                    this.from.bank = res.data.bank
                    this.from.phone = res.data.phone
                    this.from.address = res.data.address
                    this.from.isDefault = res.data.isDefault
                    this.value = res.data.isDefault == 1 ? true : false

                    // console.log(this.area,99999999)
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        // 提交
        addSure() {
            if (!this.from.taxNumber) {
                this.$message({
                    message: '请输入税号',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (!this.from.accountName) {
                this.$message({
                    message: '请输入账户名称',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (!this.from.accountNumber) {
                this.$message({
                    message: '请输入账户号码',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }

            if (!this.from.bank) {
                this.$message({
                    message: '请输入开户银行',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }

            if (!this.from.address) {
                this.$message({
                    message: '请输入地址',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }

            if (!this.from.phone) {
                this.$message({
                    message: '请输入电话',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (this.btnFlag) {
                return
            }
            this.btnFlag = true
            this.from.isDefault = this.value ? 1 : 0
            if (this.type == 1) {
                addInvoiceInfo(this.from).then((res) => {
                    this.btnFlag = false
                    if (res.code == 0) {
                        this.$message({
                            message: '提交成功',
                            type: 'success',
                            customClass: 'messageBoxTip'
                        })
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 1500)
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'warning',
                            customClass: 'messageBoxTip'
                        })
                    }
                })
            } else {
                this.from.id = this.id
                updateInvoiceInfo(this.from).then((res) => {
                    this.btnFlag = false
                    if (res.code == 0) {
                        this.$message({
                            message: '提交成功',
                            type: 'success',
                            customClass: 'messageBoxTip'
                        })
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 1500)
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'warning',
                            customClass: 'messageBoxTip'
                        })
                    }
                })
            }
        },

        handleChange(e) {
            console.log(e)
            this.from.provinceId = e[0]
            this.from.cityId = e[1]
            this.from.countyId = e[2]
        },
        buildTree(data, parentId = '0') {
            let tree = []
            let map = {}

            // 初始化map，将name字段更改为label，并准备快速查找节点
            data.forEach((item) => {
                map[item.id] = {
                    id: item.id,
                    pid: item.pid,
                    label: item.name,
                    value: item.id,
                    children: []
                }
            })

            // 构建树，使用label作为显示名称
            data.forEach((item) => {
                if (item.pid === parentId) {
                    tree.push(map[item.id])
                } else {
                    // 查找父节点，并添加子节点，同时确保使用label
                    if (map[item.pid]) {
                        map[item.pid].children.push(map[item.id])
                    }
                }
            })

            // 如果需要修剪超过三级的子节点，可以在这里添加逻辑
            // 但由于问题要求三级结构，我们假设输入数据已经符合或可以通过其他方式裁剪
            function trimEmptyChildren(nodes) {
                return nodes.map((node) => {
                    // 如果children为空数组或只包含空数组，则移除children字段
                    if (
                        node.children &&
                        !node.children.some(
                            (child) =>
                                child.children ||
                                (child.children && child.children.length > 0)
                        )
                    ) {
                        delete node.children
                    } else if (node.children) {
                        // 递归修剪子节点
                        node.children = trimEmptyChildren(node.children)
                    }
                    return node
                })
            }

            return trimEmptyChildren(tree)
        }
        // init() {
        //     getRegionTree().then((res) => {
        //         const treeData = this.buildTree(res.data)
        //         this.options = treeData
        //     })
        // }
    }
}
</script>
  
  <style lang="scss" scoped>
.content {
    padding: 0.5rem 0;
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('@/assets/fwgtb.png') no-repeat center;
    background-size: 100% 100%;
    z-index: -1;
}
.addressBox {
    width: 15.12rem;
    border-radius: 0.2934rem;
    border: 0.04rem solid #ffffff;
    background: #ffffff;
    box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
    margin: 0 auto;
    padding: 0.45rem 0.8rem;
    box-sizing: border-box;
    .addressTop {
        color: #000000;
        font-size: 0.36rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        margin-bottom: 0.5rem;
    }
    .addressInptLi {
        display: flex;
        align-items: center;
        padding: 0.2rem 0;
        border-bottom: 0.02rem solid #e5e5e5;
        flex: 1;

        .addressTit {
            color: #555555;
            font-size: 0.28rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            width: 2rem;
        }
        .addressInpt {
            display: flex;
            align-items: center;
            flex: 1;

            input {
                color: #555555;
                font-size: 0.28rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
                flex: 1;
            }
        }
    }
    .addShdz {
        width: 6.94rem;
        height: 0.82rem;
        border-radius: 0.4rem;
        background: linear-gradient(90deg, #0e8f46 0%, #12924a 100%);
        color: #ffffff;
        font-size: 0.28rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.76rem auto;
    }
}
</style>