<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <!-- <div class="header">
            <div class="headerTop">
                <img src="../../assets/zzck.png" alt="" />
                正在出库
            </div>
            <div class="headerTip">
                您的订单审核已完成，等待商家处理，请保持手机畅通，耐心等候
            </div>
            <div class="headerNext">
                <div class="headerNextOne">
                    <div class="headerNextOneLeft">
                        <div class="headerNextOneLeftd">
                            <div class="headerNextOneLeftdN"></div>
                        </div>

                        <div class="headerNextOneLeftName">仓库处理中</div>
                    </div>
                    <div class="headerNextOneRight">
                        查看详情
                        <div class="el-icon-arrow-right"></div>
                    </div>
                </div>
                <div class="headerNextTwo">
                    <div class="headerNextTwoLeft">
                        <div class="line"></div>
                    </div>
                    <div class="headerNextTwoCont">
                        您的订单当前商家正在处理中
                    </div>
                </div>
                <div class="headerAddress">
                    <div class="headerAddressIcon">
                        <div class="el-icon-location"></div>
                    </div>
                    <div class="headerAddName">
                        张可欣<span>18036923130</span>
                    </div>
                </div>
                <div class="headerAddressMsg">
                    浙江省杭州市西湖区玉皇山路观音洞66号
                </div>
            </div>
        </div> -->
        <div class="header">
            <div class="headerTop">
                {{ dataMsg.statusName }}
            </div>
            <div class="headerNext">
                <div class="headerAddress">
                    <div class="headerAddressIcon">
                        <div class="el-icon-location"></div>
                    </div>
                    <div class="headerAddName">
                        {{ dataMsg.addressEntity.userName
                        }}<span>{{ dataMsg.addressEntity.userPhone }}</span>
                    </div>
                </div>
                <div class="headerAddressMsg">
                    {{ dataMsg.addressEntity.provinceName
                    }}{{ dataMsg.addressEntity.cityName
                    }}{{ dataMsg.addressEntity.countyName
                    }}{{ dataMsg.addressEntity.address }}
                </div>
            </div>
        </div>
        <div
            class="shopBox"
            v-for="(item, index) in dataMsg.sumCartPriceVO.goodsList"
            :key="index"
        >
            <div class="shopTop" @click="qyGo(item.companyId)">
                <img src="../../assets/shopIcon.png" alt="" />
                <div class="shopName">
                    {{ item.company }}
                    <div class="el-icon-arrow-right"></div>
                </div>
            </div>
            <div v-for="(obj, objIndex) in item.cartList" :key="objIndex">
                <div class="shopList" @click="spGo(obj.goodsId)">
                    <div class="shopImage">
                        <img :src="obj.goodsImg" alt="" />
                    </div>
                    <div class="shopCont">
                        <div class="shopContTop">
                            <div class="shopContName">
                                {{ obj.goodsName || '' }}
                            </div>
                            <div class="shopContPrice">
                                ￥{{ obj.goodsPrice || '' }}
                            </div>
                        </div>
                        <div class="shopContTip">
                            规格：{{ obj.specName || '' }}
                        </div>
                        <div class="shopContTip">
                            数量：{{ obj.goodsNum || '' }}
                        </div>

                        <div class="footerBottomLiBox">
                            <div
                                class="footerBottomLi footerBottomLiA"
                                v-if="
                                    dataMsg.status == 5 && obj.comment == null
                                "
                                @click.stop="evaluate(obj)"
                            >
                                评价
                            </div>
                        </div>
                        <div class="footerBottomLiBox">
                            <div
                                class="footerBottomLi footerBottomLiA"
                                v-if="
                                    dataMsg.status == 5 && obj.comment != null
                                "
                                @click.stop="evaluateCk(obj)"
                            >
                                查看评价
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shopOrder">
                <div class="shopOrderLeft">运费</div>
                <div class="shopOrderRight">￥{{ dataMsg.freightPrice }}</div>
            </div>

            <div class="shopOrder">
                <div class="shopOrderLeft">实付款</div>
                <div class="shopOrderRight">
                    合计 ￥{{ dataMsg.totalPrice }}
                </div>
            </div>

            <div class="shopOrder" @click="copy(dataMsg.code)">
                <div class="shopOrderLeft">订单编号</div>
                <div class="shopOrderRight">{{ dataMsg.code }} | 复制</div>
            </div>
            <div class="shopOrderBox" v-if="flagzs">
                <!-- <div class="shopOrder">
                    <div class="shopOrderLeft">交易快照</div>
                    <div class="shopOrderRight">
                        发生交易争议时，可作为判断依据
                        <span class="el-icon-arrow-right"></span>
                    </div>
                </div> -->
                <div class="shopOrder">
                    <div class="shopOrderLeft">支付方式</div>
                    <div class="shopOrderRight">{{ dataMsg.payTypeName }}</div>
                </div>
                <!-- <div class="shopOrder">
                    <div class="shopOrderLeft">发票类型</div>
                    <div class="shopOrderRight">
                        不开发票
                        <span class="el-icon-arrow-right"></span>
                    </div>
                </div> -->
                <div class="shopOrder">
                    <div class="shopOrderLeft">支付时间</div>
                    <div class="shopOrderRight">{{ dataMsg.payTime }}</div>
                </div>
                <div class="shopOrder">
                    <div class="shopOrderLeft">下单时间</div>
                    <div class="shopOrderRight">{{ dataMsg.orderTime }}</div>
                </div>
                <!-- <div class="shopOrder">
                    <div class="shopOrderLeft">配送方式</div>
                    <div class="shopOrderRight">运输</div>
                </div> -->
            </div>
            <div class="shopShow" v-if="flagzs" @click="flagzs = false">
                收起
                <span class="el-icon-arrow-up"></span>
            </div>
            <div class="shopShow" v-if="!flagzs" @click="flagzs = true">
                展开
                <span class="el-icon-arrow-down"></span>
            </div>
        </div>
        <div class="shopBox" v-if="dataMsg.invoiceInfoEntity != null">
            <div class="shopTop">
                <div class="shopName" style="margin-left: 0">发票信息</div>
            </div>
            <div
                class="shopOrder"
                @click="copy(dataMsg.invoiceInfoEntity.taxNumber)"
            >
                <div class="shopOrderLeft">税号</div>
                <div class="shopOrderRight">
                    {{ dataMsg.invoiceInfoEntity.taxNumber }} | 复制
                </div>
            </div>
            <div
                class="shopOrder"
                @click="copy(dataMsg.invoiceInfoEntity.accountName)"
            >
                <div class="shopOrderLeft">账户名称</div>
                <div class="shopOrderRight">
                    {{ dataMsg.invoiceInfoEntity.accountName }} | 复制
                </div>
            </div>
            <div
                class="shopOrder"
                @click="copy(dataMsg.invoiceInfoEntity.accountNumber)"
            >
                <div class="shopOrderLeft">账户号码</div>
                <div class="shopOrderRight">
                    {{ dataMsg.invoiceInfoEntity.accountNumber }} | 复制
                </div>
            </div>
            <div
                class="shopOrder"
                @click="copy(dataMsg.invoiceInfoEntity.bank)"
            >
                <div class="shopOrderLeft">开户银行</div>
                <div class="shopOrderRight">
                    {{ dataMsg.invoiceInfoEntity.bank }} | 复制
                </div>
            </div>
            <div
                class="shopOrder"
                @click="copy(dataMsg.invoiceInfoEntity.address)"
            >
                <div class="shopOrderLeft">地址</div>
                <div class="shopOrderRight">
                    {{ dataMsg.invoiceInfoEntity.address }} | 复制
                </div>
            </div>
            <div
                class="shopOrder"
                @click="copy(dataMsg.invoiceInfoEntity.phone)"
            >
                <div class="shopOrderLeft">电话</div>
                <div class="shopOrderRight">
                    {{ dataMsg.invoiceInfoEntity.phone }} | 复制
                </div>
            </div>
        </div>

        <div class="footerBottom">
            <div class="footerBottomLi" @click="kefuGo">联系客服</div>
            <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                title="是否确定收到货？"
                @confirm="receipt"
                v-if="dataMsg.status == 2"
            >
                <!-- <el-button slot="reference">删除</el-button> -->
                <div class="footerBottomLi footerBottomLiA" slot="reference">
                    确认收货
                </div>
            </el-popconfirm>
            <div
                class="footerBottomLi footerBottomLiA"
                v-if="dataMsg.payVoucher"
                @click="checkInvoice(dataMsg.payVoucher)"
            >
                查看付款凭证
            </div>
            <div
                class="footerBottomLi footerBottomLiA"
                v-if="dataMsg.status == 3"
                @click="billing"
            >
                申请开票
            </div>
            <div
                class="footerBottomLi footerBottomLiA"
                v-if="dataMsg.status == 5 && dataMsg.invoiceUrl"
                @click="checkInvoice(dataMsg.invoiceUrl)"
            >
                查看发票
            </div>
        </div>

        <div class="popupBig" v-if="wuliuFlag">
            <div class="popupBox">
                <div class="popupTopBig">
                    <div></div>
                    <div class="popupTit">查看详情</div>
                    <div class="el-icon-close"></div>
                </div>
                <div class="popupCont">
                    <div class="popupTop">
                        <div class="popupImg">
                            <img src="../../assets/wlxq.png" alt="" />
                        </div>
                        <div class="popupName">已下单</div>
                    </div>
                    <div class="popupText">
                        <div class="popupTextLine">
                            <div class="line"></div>
                        </div>
                        <div class="popupTextCont">
                            <div class="popupTextLi">06-28 16:59</div>
                            <div class="popupTextLi">
                                您的订单当前商家正在处理中
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popupCont">
                    <div class="popupTop">
                        <div class="popupImg">
                            <img src="../../assets/wlxq.png" alt="" />
                        </div>
                        <div class="popupName">06-28 16:59</div>
                    </div>
                    <div class="popupText">
                        <div class="popupTextLine">
                            <div class="line"></div>
                        </div>
                        <div class="popupTextCont">
                            <div class="popupTextLi">
                                您的订单已完成初步审核，待商家进行下一步处理，请保持手机畅通，耐心等候。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popupCont">
                    <div class="popupTop">
                        <div class="popupImg">
                            <img src="../../assets/wlxq.png" alt="" />
                        </div>
                        <div class="popupName">06-28 16:59</div>
                    </div>
                    <div class="popupText">
                        <div class="popupTextLine">
                            <!-- <view class="line">
								</view> -->
                        </div>
                        <div class="popupTextCont">
                            <div class="popupTextLi">
                                订单正在审核中，审核结果会及时通知，请保持手机畅通，耐心等候。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogFormVisible">
            <div class="popupTit">选择发票信息</div>
            <div class="popupMsg" @click="goChooseInvoice">
                <div class="popupMsgLeft" v-if="invoiceId">
                    <div class="popupMsgLi">
                        税号：{{ invoiceMsg.taxNumber }}
                    </div>
                    <div class="popupMsgLi">
                        账户名称：{{ invoiceMsg.accountName }}
                    </div>
                    <div class="popupMsgLi">开户银行:{{ invoiceMsg.bank }}</div>
                </div>
                <div class="popupMsgLeft" v-if="!invoiceId">
                    <div class="popupMsgLi">点击选择发票信息</div>
                </div>
                <div class="el-icon-arrow-right"></div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureKp">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible" width="30%">
            <img width="100%" :src="imageSrc" alt="预览图片" />
        </el-dialog>

        <el-dialog :visible.sync="dialogVisibleKf" width="70%">
            <div style="width: 100%; height: 8rem">
                <iframe
                    :src="urlSrcKf"
                    frameborder="0"
                    width="100%"
                    height="100%"
                ></iframe>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    orderDetail,
    confirmReceipt,
    applyInvoice,
    userInfo
} from '@/api/user/index.js'
export default {
    data() {
        return {
            urlSrcKf: '',
            dialogVisibleKf: false,

            invoiceMsg: {},
            invoiceId: '',
            dialogVisible: false,
            imageSrc: '',
            dialogFormVisible: false,

            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',

            wuliuFlag: false,
            orderId: '',
            dataMsg: {
                addressEntity: {},
                sumCartPriceVO: {
                    freightTotal: '',
                    goodsList: [],
                    goodsTotal: ''
                }
            },
            flagzs: false
        }
    },
    created() {
        if (localStorage.getItem('invoiceChoose')) {
            this.invoiceMsg = JSON.parse(localStorage.getItem('invoiceChoose'))
            this.invoiceId = this.invoiceMsg.id
            localStorage.removeItem('invoiceChoose')
            this.dialogFormVisible = true
        }
        this.orderId = this.$route.query.orderId
        this.init()
    },
    methods: {
        kefuGo() {
            if (localStorage.getItem('token')) {
                userInfo().then((res) => {
                    console.log(res)
                    if (res.code == 0) {
                        let url =
                            'https://kf.liangzhidao.com/index/index/home?theme=7571f9' +
                            '&visiter_id=' +
                            res.data.id +
                            '&visiter_name=' +
                            res.data.username +
                            '&avatar=' +
                            res.data.headUrl +
                            '&business_id=1&groupid=0'
                        // window.location.href = 'https://kf.liangzhidao.com/index/index/home?theme=7571f9' + '&visiter_id='+res.data.id + '&visiter_name=' + res.data.username + '&avatar=' + res.data.headUrl+'&business_id=1&groupid=0'
                        // window.open(url, '_blank')
                        this.urlSrcKf = url

                        localStorage.setItem(
                            'userInfo',
                            JSON.stringify(res.data)
                        )
                    }
                })
            } else {
                let url =
                    'https://kf.liangzhidao.com/index/index/home?theme=7571f9' +
                    '&visiter_id=' +
                    '' +
                    '&visiter_name=' +
                    '' +
                    '&avatar=' +
                    '' +
                    '&business_id=1&groupid=0'

                this.urlSrcKf = url

                // window.open(url, '_blank')
                // window.location.href = 'https://kf.liangzhidao.com/index/index/home?theme=7571f9' + '&visiter_id='+'' + '&visiter_name=' + '' + '&avatar=' + ''+'&business_id=1&groupid=0'
            }
            this.dialogVisibleKf = true
        },
        evaluate(item) {
            // const obj = JSON.stringify(item)
            item.orderId = this.dataMsg.id
            this.$router.push({
                path: '/merchandiseEvaluateInpt',
                query: { obj: JSON.stringify(item) }
            })
        },
        evaluateCk(item) {
            localStorage.setItem('pingjia', JSON.stringify(item))
            this.$router.push({
                path: '/merchandiseEvaluateInptCk'
            })
        },
        // 跳转企业详情
        qyGo(id) {
            if (id) {
                this.$router.push({
                    path: '/companyXq',
                    query: { id: id }
                })
            }
        },
        // 跳转商品详情
        spGo(id) {
            this.$router.push({
                path: '/shopXq',
                query: { id: id }
            })
        },
        // 查看发票
        checkInvoice(url) {
            this.imageSrc = url
            this.dialogVisible = true
        },
        sureKp() {
            if (!this.invoiceId) {
                this.$message({
                    message: '请选择发票信息',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            applyInvoice({
                id: this.orderId,
                invoiceId: this.invoiceId
            }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '申请开票提交成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.dialogFormVisible = false
                    this.init()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
            // dialogFormVisible = false
        },
        // 选择发票信息
        goChooseInvoice() {
            this.$router.push({
                path: '/invoiceAdmin',
                query: { type: 2 }
            })
        },
        // 申请开票
        billing() {
            this.dialogFormVisible = true
        },
        // 确认收货
        receipt() {
            confirmReceipt({ id: this.dataMsg.id }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '确定收货成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.init()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        // 复制
        copy(data) {
            let url = data
            let oInput = document.createElement('input')
            oInput.value = url
            document.body.appendChild(oInput)
            oInput.select() // 选择对象;
            console.log(oInput.value)
            document.execCommand('Copy') // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success'
            })
            oInput.remove()
        },
        init() {
            orderDetail({ id: this.orderId }).then((res) => {
                this.dataMsg = res.data
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.header {
    width: 15.12rem;
    border-radius: 0.32rem;
    border: 0.04rem solid #ffffff;
    background: #ffffff;
    box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
    margin: 2rem auto 0;
    padding: 0.38rem 0.28rem;
    box-sizing: border-box;
    .headerTop {
        color: #12924a;
        font-size: 0.34rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        display: flex;
        align-items: center;
        img {
            width: 0.42rem;
            height: 0.42rem;
            margin-right: 0.12rem;
        }
    }

    .headerTip {
        color: #555555e6;
        font-size: 0.24rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        padding-left: 0.5443rem;
        box-sizing: border-box;
        margin-top: 0.16rem;
    }
    .headerNext {
        margin-top: 0.26rem;
        .headerNextOne {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .headerNextOneLeft {
                display: flex;
                align-items: center;
                .headerNextOneLeftd {
                    width: 0.29rem;
                    height: 0.29rem;
                    background: #f9f9f9;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .headerNextOneLeftdN {
                        width: 0.13rem;
                        height: 0.13rem;
                        background: #cccccc;
                        border-radius: 50%;
                    }
                }

                .headerNextOneLeftName {
                    color: #1b1b1b;
                    font-size: 0.28rem;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                    margin-left: 0.18rem;
                }
            }
            .headerNextOneRight {
                color: #1b1b1b;
                font-size: 0.28rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
            }
        }
        .headerNextTwo {
            display: flex;

            .headerNextTwoLeft {
                width: 0.29rem;
                min-height: 1.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                .line {
                    width: 0.01rem;
                    height: 100%;
                    background: #cccccc;
                }
            }

            .headerNextTwoCont {
                padding: 0.08rem 0.18rem;
                box-sizing: border-box;
                color: #6b6b6bcc;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                line-height: 0.4rem;
            }
        }

        .headerAddress {
            display: flex;
            align-items: center;
            .headerAddressIcon {
                width: 0.29rem;
                height: 0.29rem;
                background: #12924a;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                .el-icon-location {
                    font-size: 0.2rem;
                    color: #ffffff;
                }
            }
            .headerAddName {
                margin-left: 0.18rem;
                color: #1b1b1b;
                font-size: 0.28rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
                span {
                    color: #6b6b6bcc;
                    font-size: 0.28rem;
                    margin-left: 0.1rem;
                }
            }
        }
        .headerAddressMsg {
            color: #6b6b6bcc;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-top: 0.27rem;
            line-height: 0.4rem;
            padding-left: 0.47rem;
        }
    }
}
.shopBox {
    width: 15.12rem;
    border-radius: 0.32rem;
    background: #ffffff;
    padding: 0.28rem;
    box-sizing: border-box;
    margin: 0.26rem auto 0;
    .shopTop {
        display: flex;
        align-items: center;

        img {
            width: 0.33rem;
            height: 0.35rem;
        }
        .shopName {
            color: #1b1b1b;
            font-size: 0.28rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-left: 0.11rem;
        }
    }
    .shopList {
        display: flex;
        // align-items: center;
        margin-top: 0.22rem;
        .shopImage {
            width: 1.8rem;
            height: 1.8rem;
            border-radius: 0.16rem;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.16rem;
            }
        }
        .shopCont {
            margin-left: 0.26rem;

            flex: 1;
            .shopContTop {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .shopContName {
                    color: #1b1b1b;
                    font-size: 0.24rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
                .shopContPrice {
                    color: #1b1b1b;
                    font-size: 0.3rem;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                }
            }
            .shopContTip {
                color: #6b6b6bcc;
                font-size: 0.22rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                margin-top: 0.16rem;
            }
            .footerBottomLiBox {
                display: flex;
                justify-content: flex-end;
            }
            .footerBottomLi {
                min-width: 1.5738rem;
                padding: 0 0.2rem;
                box-sizing: border-box;
                height: 0.64rem;
                border-radius: 0.72rem;
                border: 0.02rem solid #383838;
                color: #555555;
                font-size: 0.28rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0.16rem;
                //  margin-bottom: constant(safe-area-inset-bottom);
                //  margin-bottom: env(safe-area-inset-bottom);
            }
            .footerBottomLiA {
                border: 0.02rem solid #12924a;
                color: #12924a;
            }
        }
    }
    .shopOrder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.26rem;
        .shopOrderLeft {
            color: #6b6b6bcc;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
        .shopOrderRight {
            color: #555555;
            font-size: 0.26rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
        }
    }

    .shopShow {
        color: #8b8b8b;
        font-size: 0.26rem;
        font-weight: 500;
        font-family: 'SourceHanSansCN';
        text-align: center;
        padding: 0.45rem;
        box-sizing: border-box;
    }
}
.footerBottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.2rem 0.3rem;
    box-sizing: border-box;
    // background: #ffffff;
    // box-shadow: 0 -0.01rem 0.12rem 0 #0000001a;
    // position: fixed;
    // bottom: 0;
    // left: 0;
    width: 15.12rem;
    margin: 0.45rem auto;

    .footerBottomLi {
        min-width: 1.5738rem;
        padding: 0 0.2rem;
        box-sizing: border-box;
        height: 0.64rem;
        border-radius: 0.72rem;
        border: 0.02rem solid #383838;
        color: #555555;
        font-size: 0.28rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.16rem;
        //  margin-bottom: constant(safe-area-inset-bottom);
        //  margin-bottom: env(safe-area-inset-bottom);
    }
    .footerBottomLiA {
        border: 0.02rem solid #12924a;
        color: #12924a;
    }
}
.popupBig {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 999999;
    .popupBox {
        width: 7.5rem;
        border-radius: 0.32rem;
        background: #ffffff;
        margin: 4rem auto 0;

        padding: 0.3rem;

        box-sizing: border-box;
        .popupTopBig {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .popupTit {
                color: #1b1b1b;
                font-size: 0.3rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
            }
            .el-icon-close {
                font-size: 0.3rem;

                color: #a6a6a6;
            }
        }
        .popupCont {
            .popupTop {
                display: flex;
                align-items: center;
                .popupImg {
                    width: 0.29rem;
                    height: 0.29rem;
                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
                .popupName {
                    color: #1b1b1b;
                    font-size: 0.28rem;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                    margin-left: 0.18rem;
                }
            }

            .popupText {
                display: flex;
                .popupTextLine {
                    width: 0.29rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .line {
                        width: 0.01rem;
                        min-height: 1.5rem;
                        background: #e5e5e5;
                    }
                }

                .popupTextCont {
                    margin-left: 0.18rem;
                    flex: 1;
                    .popupTextLi {
                        color: #6b6b6bcc;
                        font-size: 0.26rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                        margin-top: 0.22rem;
                        line-height: 0.4rem;
                    }
                }
            }
        }
    }
}

.popupTit {
    font-size: 0.32rem;
    text-align: center;
    font-weight: 700;
    font-family: 'SourceHanSansCN';
    margin-bottom: 0.2rem;
}
.popupMsg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0;
    .popupMsgLeft {
        .popupMsgLi {
            color: #1b1b1b;
            font-size: 0.28rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            margin-top: 0.1rem;
        }
    }

    .el-icon-arrow-right {
        font-size: 0.28rem;
    }
}
</style>