<template>
    <div class="contentbox">
        <div class="popupBox">
            <div class="popupTop">
                <img :src="guigeMsg.img" alt="" />
                <div class="popupCont">
                    <div>
                        <div class="popupName">
                            {{ msgData.title }}
                            <div class="el-icon-close" @click="close"></div>
                        </div>
                        <div class="popupKc">库存：{{ guigeMsg.stock }}</div>
                    </div>

                    <div class="popupPrice">
                        ￥<span>{{ guigeMsg.price }}</span>
                    </div>
                </div>
            </div>

            <div class="popupNum">
                <div class="popupNumName">数量</div>

                <div class="popupNumAdd">
                    <el-input-number
                        v-model="numberSub"
                        @change="handleChange"
                        :min="1"
                        :max="guigeMsg.stock"
                        label="描述文字"
                    ></el-input-number>
                </div>
            </div>

            <div class="popupGg">
                <div class="popupGgTit">规格</div>
                <div class="popupGgBox">
                    <div
                        class="popupGgLi"
                        :class="indexDown == index ? 'popupGgLiA' : ''"
                        v-for="(item, index) in msgData.specList"
                        :key="index"
                        @click="chooseGg(index, item)"
                    >
                        {{ item.name }}
                    </div>
                </div>
            </div>

            <div class="footerRight">
                <div class="footerRightLi" @click="submit(1)">加入购物车</div>
                <div class="footerRightLi footerRightLiA" @click="submit(2)">
                    立即购买
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            numberSub: 1,
            indexDown: 0,
            guigeMsg: {}
        }
    },
    props: {
        msgData: {
            type: Object,
            default() {}
        }
    },
    created() {
        this.init()
    },
    methods: {
        chooseGg(index, item) {
            this.indexDown = index
            this.guigeMsg = item
        },
        init() {
            this.guigeMsg = this.msgData.specList[0]
        },
        // 关闭弹窗
        close() {
            this.$emit('closeBtn', false)
        },
        handleChange(value) {
            console.log(value)
        },

        submit(num) {
            this.$emit(
                'guigeMsgGet',
                JSON.stringify(this.guigeMsg),
                num,
                this.numberSub
            )
        }
    }
}
</script>

<style lang="scss" scoped>
.contentbox {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    align-items: flex-end;
    .popupBox {
        // padding-top: 0.3rem;
        width: 100%;
        border-radius: 0.3rem 0.3rem 0 0;
        background: #ffffff;

        .popupTop {
            display: flex;
            align-items: center;
            padding: 0.3rem;
            box-sizing: border-box;
            img {
                width: 2rem;
                height: 2rem;
                border-radius: 0.2rem;
            }
            .popupCont {
                margin-left: 0.2rem;
                flex: 1;
                height: 2rem;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                .popupName {
                    font-size: 0.3rem;
                    color: #000000;
                    display: flex;
                    justify-content: space-between;
                    .el-icon-close {
                        font-size: 0.4rem;

                        color: #6b6b6b;
                    }
                }
                .popupKc {
                    margin-top: 0.2rem;
                    font-size: 0.26rem;
                    color: #6b6b6b;
                }
                .popupPrice {
                    color: #00903d;
                    font-weight: 500;
                    font-size: 0.24rem;
                    font-family: 'SourceHanSansCN';
                    text {
                        color: #00903d;
                        font-weight: 700;
                        font-size: 0.3rem;
                        font-family: 'SourceHanSansCN';
                    }
                }
            }
        }
        .popupNum {
            padding: 0 0.3rem 0.3rem;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .popupNumName {
                font-size: 0.3rem;
                color: #6b6b6b;
            }
            .popupNumAdd {
                display: flex;
                align-items: center;
            }
        }
        .popupGg {
            padding: 0 0.3rem 0.3rem;
            box-sizing: border-box;
            .popupGgTit {
                font-size: 0.3rem;
                color: #6b6b6b;
            }
            .popupGgBox {
                display: flex;
                align-items: center;
                .popupGgLi {
                    margin-top: 0.2rem;
                    margin-right: 0.3rem;
                    padding: 0.1rem 0.2rem;
                    box-sizing: border-box;
                    background: #6b6b6b;
                    font-size: 0.28rem;
                    color: #ffffff;
                    border-radius: 0.1rem;
                }
                .popupGgLiA {
                    background: #00903d;
                }
            }
        }
    }
}
.footerRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem;
    box-sizing: border-box;
    .footerRightLi {
        width: 48%;
        height: 0.9rem;
        border-radius: 100rpx;
        border: 0.02cap solid #00903d;
        color: #00903d;
        font-size: 0.32rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 0.2rem;
    }
    .footerRightLiA {
        border: none;
        background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
        color: #ffffff;
        font-size: 0.32rem;

        font-weight: 500;
        font-family: 'SourceHanSansCN';
    }
}
</style>