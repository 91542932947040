<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="addressBox">
            <div v-for="(item, index) in dataList" :key="index">
                <div class="addressLi" @click="choose(item)">
                    <div class="addressTop">
                        {{ item.userName }} <span> {{ item.userPhone }}</span>
                    </div>
                    <div class="address">
                        {{ item.provinceName }}{{ item.cityName
                        }}{{ item.countyName }}{{ item.address }}
                    </div>
                    <div class="addressBottom">
                        <div
                            class="addressBottomLeft"
                            @click.stop="moren(item.id)"
                        >
                            <div class="shopCheck">
                                <img
                                    src="../assets/noactive.png"
                                    alt=""
                                    v-if="item.isDefault != 1"
                                />
                                <img
                                    src="../assets/activce.png"
                                    alt=""
                                    v-if="item.isDefault == 1"
                                />
                            </div>
                            设为默认
                        </div>
                        <div class="addressBtn">
                            <div
                                class="addressBtnLi"
                                @click.stop="removeLi(item.id)"
                            >
                                删除
                            </div>
                            <!-- <div class="addressBtnLi">
                            复制
                        </div> -->
                            <div
                                class="addressBtnLi"
                                @click.stop="toUrl('/addressInpt', 2, item.id)"
                            >
                                修改
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="addShdz" @click="toUrl('/addressInpt', 1)">
                新增收货地址
            </div>
        </div>
    </div>
</template>

<script>
import { getUserAddressList, delAddress, isDefault } from '@/api/user/index.js'

export default {
    data() {
        return {
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            dataList: [],
            type: 1
        }
    },
    created() {
        console.log(this.$route.query.type, 'asfas')
        if (this.$route.query.type) {
            this.type = this.$route.query.type
        }
        this.init()
    },
    methods: {
        choose(item) {
            if (this.type == 2) {
                localStorage.setItem('addressChoose', JSON.stringify(item))
                this.$router.go(-1)
            }
        },
        moren(id) {
            isDefault({ id: id }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '设置成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })

                    this.init()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        removeLi(id) {
            delAddress({ id: id }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '删除成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })

                    this.init()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        toUrl(urlSrc, num, id) {
            if (id) {
                this.$router.push({
                    path: urlSrc,
                    query: { type: num, id: id }
                })
            } else {
                this.$router.push({ path: urlSrc, query: { type: num } })
            }
        },

        init() {
            getUserAddressList().then((res) => {
                console.log(res)

                this.dataList = res.data
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding: 0.5rem 0;
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('@/assets/fwgtb.png') no-repeat center;
    background-size: 100% 100%;
    z-index: -1;
}
.addressBox {
    .addressLi {
        width: 15.07rem;
        border-radius: 0.32rem;
        border: 0.02rem solid #00913e;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem #2fa5631a;
        margin: 0.32rem auto 0;
        padding: 0.5rem;
        box-sizing: border-box;
        .addressTop {
            color: #1b1b1b;
            font-size: 0.28rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            span {
                margin-left: 0.26rem;
            }
        }
        .address {
            color: #302e2f;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-top: 0.17rem;
        }
        .addressBottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 0.36rem;
            .addressBottomLeft {
                display: flex;
                align-items: center;
                color: #302e2f;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                .shopCheck {
                    width: 0.37rem;
                    height: 0.37rem;
                    margin-right: 0.08rem;
                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
            }
            .addressBtn {
                display: flex;
                align-items: center;
                .addressBtnLi {
                    width: 1.1202rem;
                    height: 0.54rem;
                    border-radius: 0.6075rem;
                    opacity: 1;
                    border: 0.02rem solid #38383880;
                    color: #555555;
                    font-size: 0.28rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 0.11rem;
                }
            }
        }
    }
    .addShdz {
        width: 6.94rem;
        height: 0.82rem;
        border-radius: 0.4rem;
        background: linear-gradient(90deg, #0e8f46 0%, #12924a 100%);
        color: #ffffff;
        font-size: 0.28rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.76rem auto;
    }
}
</style>